import React from 'react';

import { Quote, QuoteRequest } from '@meterup/connect-api';

import FilterBandwidth from '../FilterBandwidth/FilterBandwidth';
import FilterConnectionTypes from '../FilterConnectionTypes/FilterConnectionTypes';
import FilterContractLengthMonth from '../FilterContractLengthMonth/FilterContractLengthMonth';
import FilterLocation from '../FilterLocation/FilterLocation';
import FilterPrice from '../FilterPrice/FilterPrice';
import FilterProviders from '../FilterProviders/FilterProviders';

// Props will be a bunch of onchangeevent
interface QuotesResultsFiltersProps {
  quoteRequest?: QuoteRequest;
  showFilters: boolean;

  minMonthlyFee: number;
  maxMonthlyFee: number;
  currentMonthlyFee: number;
  onMonthlyPriceChange: (event: any) => void;

  quotesByDownloadMbps: { [provider: string]: Quote[] };
  allDownloadMbps: number[];
  currentDownloadMbps: number[];
  onDownloadMbpsChange: (downloadMbps: number, checked: boolean) => void;

  quotesByContractLengthMonths: { [provider: string]: Quote[] };
  allContractLengthMonths: number[];
  currentContractLengthMonths: number[];
  onContractLengthMonthsChange: (contractLengthMonth: number, checked: boolean) => void;

  quotesByProvider: { [provider: string]: Quote[] };
  allProviders: string[];
  currentProviders: string[];
  onProvidersChange: (providerName: string, checked: boolean) => void;

  allConnectionTypes: string[];
  currentConnectionTypes: string[];
  onCurrentConnectionTypesChange: (connectionType: string, checked: boolean) => void;
  quotesByConnectionType: { [connectionType: string]: Quote[] };
  // resetCurrentConnectionTypes,
}

export default function QuotesResultsFilters({
  quoteRequest,
  showFilters,

  // Monthly fee
  minMonthlyFee,
  maxMonthlyFee,
  currentMonthlyFee,
  onMonthlyPriceChange,

  // Speed
  quotesByDownloadMbps,
  allDownloadMbps,
  currentDownloadMbps,
  onDownloadMbpsChange,

  // Contract length
  quotesByContractLengthMonths,
  allContractLengthMonths,
  currentContractLengthMonths,
  onContractLengthMonthsChange,

  allProviders,
  currentProviders,
  onProvidersChange,
  quotesByProvider,

  allConnectionTypes,
  currentConnectionTypes,
  onCurrentConnectionTypesChange,
  quotesByConnectionType,
}: QuotesResultsFiltersProps) {
  const address = quoteRequest?.location?.address?.address1
    ? quoteRequest?.location?.address?.address1
    : '';
  return (
    <nav>
      <FilterLocation address={address} />

      {showFilters && allDownloadMbps.length > 1 && (
        <div className="mt-8 pt-6 border-t border-gray-50">
          <FilterBandwidth
            quotesByDownloadMbps={quotesByDownloadMbps}
            allDownloadMbps={allDownloadMbps}
            currentDownloadMbps={currentDownloadMbps}
            onDownloadMbpsChange={onDownloadMbpsChange}
          />
        </div>
      )}

      {showFilters && (
        <div className="mt-8 pt-6 border-t border-gray-50">
          <FilterPrice
            minMonthlyFee={minMonthlyFee}
            maxMonthlyFee={maxMonthlyFee}
            currentMonthlyFee={currentMonthlyFee}
            onMonthlyPriceChange={onMonthlyPriceChange}
          />
        </div>
      )}

      {showFilters && allContractLengthMonths.length > 1 && (
        <div className="mt-8 pt-6 border-t border-gray-50">
          <FilterContractLengthMonth
            quotesByContractLengthMonths={quotesByContractLengthMonths}
            allContractLengthMonths={allContractLengthMonths}
            currentContractLengthMonths={currentContractLengthMonths}
            onContractLengthMonthsChange={onContractLengthMonthsChange}
          />
        </div>
      )}

      {showFilters && allConnectionTypes.length > 1 && (
        <div className="mt-8 pt-6 border-t border-gray-50">
          <FilterConnectionTypes
            allConnectionTypes={allConnectionTypes}
            currentConnectionTypes={currentConnectionTypes}
            onCurrentConnectionTypesChange={onCurrentConnectionTypesChange}
            quotesByConnectionType={quotesByConnectionType}
          />
        </div>
      )}

      {showFilters && allProviders.length > 1 && (
        <div className="mt-8 pt-6 border-t border-gray-50">
          <FilterProviders
            allProviders={allProviders}
            currentProviders={currentProviders}
            onProvidersChange={onProvidersChange}
            quotesByProvider={quotesByProvider}
          />
        </div>
      )}
    </nav>
  );
}
