/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';

import { Button } from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../../hooks/useFormStorage';
import { route } from '../../../routes';

// Props will be a bunch of onchangeevent
interface QuotesResultsEmptyProps {
  address: string;
}

function QuotesResultsEmpty({ address }: QuotesResultsEmptyProps) {
  const navigate = useNavigate();
  const [, , setTemporaryFormValues] = useFormStorage(REQUEST_QUOTE_FORM_KEY);
  const onClickClear = useCallback(() => {
    setTemporaryFormValues({});
    navigate(route('welcome'));
  }, []);

  return (
    <section className="bg-gray-50 p-4 rounded-md">
      <strong>Sorry, we weren’t able to find any providers</strong>
      <p className="text-gray-600 mt-1">
        We searched all providers but couldn’t find any that serviced {address}. Please check to
        ensure that the address you entered is correct and that it’s not a residential address. Sign
        up to check when service will be available, or search another location.
      </p>
      <p className="flex w-full justify-end mt-1">
        <Button onClick={onClickClear}>Start a new search</Button>
      </p>
    </section>
  );
}

export default QuotesResultsEmpty;
