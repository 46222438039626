/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable eqeqeq */
import { Address } from '@meterup/connect-api';

export const getAddressObjectFromAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): Address => {
  // Help us extra data from google schema
  function extractFromAddress(
    components: google.maps.GeocoderAddressComponent[],
    type: string,
    short_name: boolean = false,
  ) {
    const value = components
      .filter((component) => component.types.indexOf(type) != -1)
      .map((item) => (short_name ? item.short_name : item.long_name))
      .pop();

    return value || '';
  }

  // Street address 1 data points
  const street_number = extractFromAddress(addressComponents, 'street_number');
  const route = extractFromAddress(addressComponents, 'route');
  const premise = extractFromAddress(addressComponents, 'premise');

  // City: Candidates for city
  const postal_town = extractFromAddress(addressComponents, 'postal_town');
  const sublocality = extractFromAddress(addressComponents, 'sublocality');
  const locality = extractFromAddress(addressComponents, 'locality');
  const administrative_area_level_1 = extractFromAddress(
    addressComponents,
    'administrative_area_level_1',
  );

  // State: Candidates for state
  const state_code = extractFromAddress(addressComponents, 'state');
  const administrative_area_level_1_short_name = extractFromAddress(
    addressComponents,
    'administrative_area_level_1',
    true,
  );

  // Postal code
  const postalCode = extractFromAddress(addressComponents, 'postal_code');

  // address 1
  const address1 = [street_number, route].filter((v) => v !== '').join(' ') || premise; // Sometimes there is no street_number/route returned because the address is a prominent "premise" (like a stadium or a large office tower).
  const address2 = ''; // This is never returned; rely on the input in the Address2 text box for this

  // Select best guess for City & State
  const city = postal_town || locality || sublocality || administrative_area_level_1_short_name;
  const state = state_code || administrative_area_level_1_short_name;

  // @ts-ignore
  return {
    address1,
    address2,
    city,
    state,
    postalCode,
  };
};

export const guessAddressObjectFromInputString = (addressString: string): Partial<Address> => {
  const parts = addressString.split(',');

  if (parts.length > 5) {
    return {
      address2: `${parts[0]} ${parts[1]}`,
      address1: parts[2],
      city: parts[3],
      state: parts[4],
      postalCode: parts[5],
    };
  }

  return { address1: addressString };
};
