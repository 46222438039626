/* eslint-disable eqeqeq */
import React from 'react';

import { FormattedMoney } from '@meterup/atto';
import { Quote, QuoteRequest } from '@meterup/connect-api';
import { FormattedMonthlyAmount } from '@meterup/connect-ui';

interface QuotesContractSidebarProps {
  quoteRequest: QuoteRequest;
  primaryQuote: Quote;
  secondaryQuote?: Quote;
}

function QuotesContractSidebar({
  quoteRequest,
  primaryQuote,
  secondaryQuote,
}: QuotesContractSidebarProps) {
  let totalInstallFeeCents: number = primaryQuote.installFeeCents;
  let totalMonthlyFeeCents: number = primaryQuote.monthlyFeeCents;

  if (secondaryQuote) {
    totalInstallFeeCents += secondaryQuote.installFeeCents;
    totalMonthlyFeeCents += secondaryQuote.monthlyFeeCents;
  }

  const renderQuoteWithDetails = (quote: Quote, order: string) => (
    <div>
      <p className="text-gray-800">
        <span className="font-bold text-gray-800 mr-4">{quote?.provider?.name}</span>
        <span className="font-medium text-xs bg-gray-100 rounded p-1 bg-opacity-30">
          {order} connection
        </span>
      </p>
      <div className="flex justify-between mt-2 mb-2 align-center items-center text-gray-600 text-sm">
        <span className="font-normal">Install fee</span>
        <p className="text-sm font-normal">
          <span className="mr-1">one-time</span>
          <span className="text-gray-800">
            <FormattedMoney amount={quote.installFeeCents / 100} />
          </span>
        </p>
      </div>
      <div className="flex justify-between mt-2 align-center items-center text-gray-600 text-sm">
        <span className="font-normal">
          {quote.contractLengthMonths === 1
            ? 'Month-to-month contract'
            : `${quote.contractLengthMonths}-month contract`}
        </span>
        <p className="text-sm font-normal">
          <span className="mr-2">monthly</span>
          <span className="text-gray-800">
            <FormattedMoney amount={quote.monthlyFeeCents / 100} />
          </span>
        </p>
      </div>
    </div>
  );

  const renderQuoteSimplified = (quote: Quote) => (
    <div>
      <p className="text-gray-600">
        <span className="text-gray-800 mr-4">{quote?.provider?.name}</span>
        <span className="text-sm bg-gray-100 rounded p-1 bg-opacity-30">Primary connection</span>
      </p>
    </div>
  );

  const renderQuotes = () => {
    if (primaryQuote && secondaryQuote) {
      return (
        <>
          <div className="mb-4 pb-4 border-b border-gray-100">
            {renderQuoteWithDetails(primaryQuote, 'Primary')}
          </div>
          <div className="mb-4 pb-4 border-b border-gray-100">
            {renderQuoteWithDetails(secondaryQuote, 'Secondary')}
          </div>
        </>
      );
    }
    return (
      <div className="mb-4 pb-4 border-b border-gray-100">
        {renderQuoteSimplified(primaryQuote)}
      </div>
    );
  };

  return (
    <section>
      {renderQuotes()}
      {quoteRequest.isInterestedInNetwork && (
        <div className="mb-4 pb-4 border-b border-gray-100 text-gray-600">
          <p className="text-gray-800 mr-4">Meter Network</p>
          <p className="font-normal text-sm">
            Get connected to fast, secure, and reliable internet & WiFi. We’ll contact you with a
            quote.
          </p>
        </div>
      )}
      <div className="flex justify-between mt-2 mb-2 align-center items-center text-gray-800">
        <span className="text-sm font-normal">Total install fee</span>
        <p className="text-sm font-normal">
          <span className="mr-2">one-time</span>
          <span className="text-gray-800">
            <FormattedMoney amount={totalInstallFeeCents / 100} />
          </span>
        </p>
      </div>
      <div className="flex justify-between align-center items-center text-gray-800">
        <span className="text-sm font-normal">Total monthly rate</span>
        <span className="text-sm font-normal">
          <span className="text-lg font-bold text-gray-800">
            <FormattedMonthlyAmount amount={totalMonthlyFeeCents / 100} />
          </span>
        </span>
      </div>
    </section>
  );
}

export default QuotesContractSidebar;
