import type { Brand } from 'ts-brand';
import { make } from 'ts-brand';

import { spacings } from '../../stitches.config';

export type SizerProp = keyof typeof spacings;
export const sizer = (value: SizerProp) => spacings[value];

export type InnerSpacingScale =
  | 0
  | 1
  | 2
  | 4
  | 6
  | 8
  | 10
  | 12
  | 14
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 68
  | 72
  | 76
  | 80
  | 84
  | 88
  | 92
  | 96
  | 100
  | 104
  | 108
  | 112
  | 116
  | 120
  | 124
  | 128
  | 132
  | 136
  | 140
  | 144
  | 148
  | 152
  | 156
  | 160
  | 164
  | 168
  | 172
  | 176
  | 180
  | 184
  | 188
  | 192
  | 196
  | 200
  | 204
  | 208
  | 212
  | 216
  | 220
  | 224
  | 228
  | 232
  | 236
  | 240
  | 244
  | 248
  | 252
  | 256
  | 260
  | 264
  | 268
  | 272
  | 276
  | 280
  | 284
  | 288
  | 292
  | 296
  | 300
  | 304
  | 308
  | 312
  | 316
  | 320
  | 324
  | 328
  | 332
  | 336
  | 340
  | 344
  | 348
  | 352
  | 356
  | 360
  | 364
  | 368
  | 372
  | 376
  | 380
  | 384
  | 388
  | 392
  | 396
  | 400
  | 404
  | 408
  | 412
  | 416
  | 420
  | 700;

export type SpacingScale = 0 | Brand<InnerSpacingScale, 'space'>;
export const SpacingValue = [
  0, 1, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80,
  84, 88, 92, 96, 100, 104, 108, 112, 116, 120, 124, 128, 132, 136, 140, 144, 148, 152, 156, 160,
  164, 168, 172, 176, 180, 184, 188, 192, 196, 200, 204, 208, 212, 216, 220, 224, 228, 232, 236,
  240, 244, 248, 252, 256, 260, 264, 268, 272, 276, 280, 284, 288, 292, 296, 300, 304, 308, 312,
  316, 320, 324, 328, 332, 336, 340, 344, 348, 352, 356, 360, 364, 368, 372, 376, 380, 384, 388,
  392, 396, 400, 404, 408, 412, 416, 420, 700,
];

export type SizingScale =
  | 'auto'
  | 'fit-content'
  | 'full'
  | 'max-content'
  | 'min-content'
  | 'screen'
  | SpacingScale;

const markSpace = make<SpacingScale>();
export const space = <V extends InnerSpacingScale>(val: V): SpacingScale & V =>
  markSpace(val) as any;
