import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { sizing } from '../../common/sizing';
import { selectors } from '../../controls/shared/styles';
import {
  colors,
  css,
  darkThemeSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches.config';
import { Text } from '../../text/Text';
import { space } from '../../utilities/shared/sizes';
import { Tooltip } from '../Tooltip/Tooltip';
import { useCanScrollX } from './useCanScrollX';

const ZINDEX_HEAD_LEADING = 19;
const ZINDEX_HEAD_SORTED = 18;
const ZINDEX_HEAD_INITIAL = 17;

const ZINDEX_BODY_STICKY_SELECTED = 16;
const ZINDEX_BODY_STICKY = 15;

const ZINDEX_BODY_SELECTED = 11;
const ZINDEX_BODY_HOVERED = 5;
const ZINDEX_BODY_INITIAL = 1;
const ZINDEX_BODY_DISABLED = 0;

const TableNestedContext = createContext<boolean | undefined>(undefined);
export const TableNestedProvider = TableNestedContext.Provider;

export const useTableNested = (
  controlledValue?: TableNestedProp,
  defaultValue: TableNestedProp = false,
) => {
  const tableNested = useContext(TableNestedContext);
  return controlledValue ?? tableNested ?? defaultValue;
};

type TableRowSelectedProp = boolean;
const TableRowSelectedContext = createContext<TableRowSelectedProp | undefined>(undefined);
export const TableRowSelectedProvider = TableRowSelectedContext.Provider;
export const useTableRowSelected = (
  controlledValue?: TableRowSelectedProp,
  defaultValue: TableRowSelectedProp = false,
) => {
  const tableRowSelected = useContext(TableRowSelectedContext);
  return controlledValue ?? tableRowSelected ?? defaultValue;
};

export function TableCellEmpty({ internal }: { internal?: boolean }) {
  return (
    <Text family="monospace" internal={internal} style={{ color: 'currentColor' }}>
      -
    </Text>
  );
}

const tableHeadRowHeight = '$28';
const tableBodyRowHeight = '$36';

const TableCellCollapsable = styled(Icon, {
  width: '$12',
  height: '$12',
});

const TableCellContents = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '$6 $12',
  width: '100%',
  minHeight: tableBodyRowHeight,
  color: '$$textColor',
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  fontSize: '$14',
  lineHeight: '$16',
  fontVariant: 'tabular-nums',
  verticalAlign: 'middle',
  wordBreak: 'keep-all',
  whiteSpace: 'nowrap',
  cursor: 'default',

  [selectors.focus]: {
    outline: 'none',
  },

  variants: {
    alignment: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    hasNested: {
      true: {
        padding: 0,

        [selectors.focus]: {
          '&::after': {
            display: 'none',
          },
        },
      },
      false: {},
    },
    head: {
      true: {
        minHeight: 'auto',
      },
      false: {},
    },
    isLeading: {
      true: {
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {},
    },
    isNavigable: {
      true: {
        cursor: 'pointer',

        [selectors.focus]: {
          '&::before': {
            content: '',
            position: 'absolute',
            inset: '$4',
            display: 'block',
            boxShadow: shadows.focusRingLight,
            borderRadius: '$8',

            [darkThemeSelector]: {
              boxShadow: shadows.focusRingDark,
            },
          },
        },
      },
      false: {},
    },
    isNested: {
      true: {},
      false: {},
    },
    internal: {
      true: {
        color: '$$internalBodyLight',

        [darkThemeSelector]: {
          color: '$$internalBodyDark',
        },
      },
      false: {},
    },
    stuck: {
      top: {
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      right: {
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      bottom: {
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      left: {
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
    },
  },
  compoundVariants: [
    {
      isLeading: true,
      isNavigable: true,
      css: {
        color: '$$leadingTextColor',
        fontWeight: fontWeights.bold,

        [selectors.hover]: {
          color: colors.linkHoverLight,

          [darkThemeSelector]: {
            color: colors.linkHoverDark,
          },
        },
      },
    },
  ],
});

const TableCellContainer = styled('td', {
  position: 'relative',
  zIndex: ZINDEX_BODY_INITIAL,
  padding: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  variants: {
    collapsable: {
      true: {
        width: '$36',
      },
      false: {},
    },
    condense: {
      true: {
        '@maxSm': {
          display: 'none',
        },
      },
      false: {},
    },
    hasNested: {
      true: {
        padding: '0 0 $16 $36',
      },
      false: {},
    },
    isNested: {
      true: {},
      false: {},
    },
    isLeading: {
      true: {
        position: 'sticky',
        left: 0,
      },
      false: {},
    },
    isSelected: {
      true: {
        zIndex: ZINDEX_BODY_SELECTED,
      },
      false: {},
    },
    stuck: {
      top: {
        position: 'sticky',
        top: 0,
      },
      right: {
        position: 'sticky',
        right: 0,
      },
      bottom: {
        position: 'sticky',
        bottom: 0,
      },
      left: {
        position: 'sticky',
        left: 0,
      },
    },
  },
  compoundVariants: [
    {
      isSelected: true,
      isLeading: true,
      css: {
        zIndex: ZINDEX_BODY_STICKY_SELECTED,
      },
    },
    {
      isNested: false,
      isLeading: true,
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: false,
      stuck: 'top',
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: false,
      stuck: 'right',
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: false,
      stuck: 'bottom',
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: false,
      stuck: 'left',
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: true,
      isLeading: true,
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: true,
      stuck: 'top',
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: true,
      stuck: 'right',
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: true,
      stuck: 'bottom',
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
    {
      isNested: true,
      stuck: 'left',
      css: {
        zIndex: ZINDEX_BODY_STICKY,
      },
    },
  ],
});

interface TableCellProps {
  /**
   * The alignment of the cell content.
   */
  alignment?: 'start' | 'center' | 'end';
  /**
   * Whether the cell should be collapsable.
   */
  collapsable?: boolean;
  /**
   * Whether the cell is collapsed.
   */
  collapsed?: boolean;
  /**
   * The number of columns the cell should span.
   */
  colSpan?: number;
  /**
   * Whether the cell should be hidden to tablet and mobile users.
   */
  condense?: boolean;
  /**
   * Whether the cell has a nested `Table`.
   */
  hasNested?: boolean;
  /**
   * Whether the cell is the leading cell in the row.
   */
  isLeading?: boolean;
  /**
   * Whether the cell is part of a nested `Table`.
   */
  isNested?: boolean;
  /**
   * Whether the cell is navigable and should show it can be clicked/tapped.
   */
  isNavigable?: boolean;
  /**
   * Whether the cell is stuck to the top, right, bottom, or left of the `Table`.
   */
  stuck?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * Whether the cell is visible only to Meter employees.
   */
  internal?: boolean;
}

function TableCellPassthrough<Tag extends React.ElementType>(
  {
    as,
    alignment,
    children,
    collapsed,
    collapsable,
    colSpan,
    condense,
    hasNested,
    isLeading,
    isNavigable,
    isNested,
    isSelected,
    stuck,
    to,
    ...props
  }: PolymorphicComponentProps<Tag, TableCellProps>,
  ref: PolymorphicRef<Tag>,
) {
  const tableNested = useTableNested(isNested);
  const tableRowSelected = useTableRowSelected(isSelected);
  return (
    <TableCellContainer
      collapsable={collapsable}
      colSpan={colSpan}
      condense={condense}
      hasNested={hasNested}
      isNested={tableNested}
      isLeading={isLeading}
      isSelected={tableRowSelected}
      stuck={stuck}
    >
      <TableCellContents
        as={collapsable ? 'button' : as}
        to={to}
        alignment={alignment}
        hasNested={hasNested}
        isLeading={isLeading}
        isNavigable={!!to || isNavigable || collapsable}
        isNested={tableNested}
        stuck={stuck}
        tabIndex={collapsable || (isLeading && to) ? 0 : undefined}
        data-has-nested={hasNested && 'true'}
        {...props}
        ref={ref}
      >
        {collapsable && (
          <TableCellCollapsable icon={collapsed ? 'chevron-right' : 'chevron-down'} />
        )}
        {children}
      </TableCellContents>
    </TableCellContainer>
  );
}

export const TableCell = React.forwardRef(TableCellPassthrough) as Polymorphic.ForwardRefComponent<
  React.ElementType,
  TableCellProps
>;

const stateCellWidth = 8;
const bufferCellWidth = sizing.primary - stateCellWidth;

export const TableCellBufferContainer = styled('td', {
  width: `$${bufferCellWidth}`,
  minWidth: `$${bufferCellWidth}`,
  maxWidth: `$${bufferCellWidth}`,

  variants: {
    head: {
      true: {
        position: 'sticky',
        top: 0,
        zIndex: ZINDEX_HEAD_INITIAL,
        maxHeight: tableHeadRowHeight,
        minHeight: tableHeadRowHeight,
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {},
    },
    isNested: {
      true: {},
      false: {},
    },
    side: {
      leading: {},
      trailing: {},
    },
  },
  compoundVariants: [
    {
      head: true,
      isNested: true,
      side: 'leading',
      css: {
        strokeTop: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          strokeTop: colors.strokeNeutralDark,
        },
      },
    },
  ],
});

type TableCellBufferProps = {
  /**
   * Whether the cell state is at the top of the table.
   */
  head?: boolean;
  /**
   * Whether the cell is part of a nested `Table`.
   */
  isNested?: boolean;
  /**
   * The side of row the cell appears.
   */
  side?: 'leading' | 'trailing';
};

export function TableCellBuffer({ head, isNested, side, ...remaining }: TableCellBufferProps) {
  const tableNested = useTableNested(isNested);
  return <TableCellBufferContainer head={head} isNested={tableNested} side={side} {...remaining} />;
}

const TableCellStateContents = styled('div', {
  width: '100%',
  minHeight: tableBodyRowHeight,

  variants: {
    head: {
      true: {
        minHeight: tableHeadRowHeight,
        maxHeight: tableHeadRowHeight,
      },
      false: {},
    },
    isNested: {
      true: {},
      false: {},
    },
    side: {
      leading: {
        borderRadius: '$10 0 0 $10',
      },
      trailing: {
        borderRadius: '0 $10 $10 0',
      },
    },
  },
  compoundVariants: [
    {
      head: true,
      css: {
        borderRadius: 0,
      },
    },
    {
      head: true,
      isNested: true,
      side: 'leading',
      css: {
        strokeTop: colors.strokeNeutralLight,

        [darkThemeSelector]: {
          strokeTop: colors.strokeNeutralDark,
        },
      },
    },
  ],
});

const TableCellStateContainer = styled('td', {
  padding: 0,
  width: `$${stateCellWidth}`,
  minWidth: `$${stateCellWidth}`,
  maxWidth: `$${stateCellWidth}`,

  variants: {
    head: {
      true: {
        position: 'sticky',
        top: 0,
        zIndex: ZINDEX_HEAD_INITIAL,
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {},
    },
  },
});

type TableCellStateProps = {
  /**
   * Whether the cell state is at the top of the table.
   */
  head?: boolean;
  /**
   * Whether the cell is part of a nested `Table`.
   */
  isNested?: boolean;
  /**
   * The side of row the cell appears.
   */
  side?: 'leading' | 'trailing';
};

export function TableCellState<Tag extends React.ElementType>({
  head,
  isNested,
  side,
  ...remaining
}: PolymorphicComponentProps<Tag, TableCellStateProps>) {
  const tableNested = useTableNested(isNested);
  return (
    <TableCellStateContainer head={head} data-side={side} {...remaining}>
      <TableCellStateContents head={head} isNested={tableNested} side={side} />
    </TableCellStateContainer>
  );
}

const TableRowStyles = css({
  $$iconColor: colors.iconNeutralLight,
  $$textColor: colors.bodyNeutralLight,
  $$leadingTextColor: colors.linkInitialLight,

  $$internalBodyLight: colors.internalBodyLight,
  $$internalBodyDark: colors.internalBodyDark,

  position: 'relative',
  width: '100%',

  [darkThemeSelector]: {
    $$iconColor: colors.iconNeutralDark,
    $$textColor: colors.bodyNeutralDark,
    $$leadingTextColor: colors.linkInitialDark,
  },

  [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
    strokeTopBottom: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      strokeTopBottom: colors.strokeNeutralDark,
    },
  },
});

const TableRowContainer = styled('tr', TableRowStyles, {
  position: 'relative',
  zIndex: ZINDEX_BODY_INITIAL,

  [selectors.hover]: {
    zIndex: ZINDEX_BODY_HOVERED,

    [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
      background: colors.bgNeutralLight,
      strokeTopBottom: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        background: colors.bgNeutralDark,
        strokeTopBottom: colors.strokeNeutralDark,
      },
    },

    [`& > ${TableCellStateContainer} > ${TableCellStateContents}`]: {
      background: colors.bgNeutralLight,

      [darkThemeSelector]: {
        background: colors.bgNeutralDark,
      },
    },

    [`& > ${TableCellStateContainer}[data-side="leading"] > ${TableCellStateContents}`]: {
      strokeNoRight: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        strokeNoRight: colors.strokeNeutralDark,
      },
    },

    [`& > ${TableCellStateContainer}[data-side="trailing"] > ${TableCellStateContents}`]: {
      strokeNoLeft: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        strokeNoLeft: colors.strokeNeutralDark,
      },
    },
  },
  variants: {
    isDisabled: {
      true: {
        zIndex: ZINDEX_BODY_DISABLED,
        $$iconColor: colors.bodyNeutralLight,
        $$textColor: colors.bodyNeutralLight,
        $$leadingTextColor: colors.bodyNeutralLight,

        opacity: 0.5,

        [darkThemeSelector]: {
          $$iconColor: colors.bodyNeutralDark,
          $$textColor: colors.bodyNeutralDark,
          $$leadingTextColor: colors.bodyNeutralDark,
        },

        [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
          background: colors.bgApplicationLight,
          strokeTopBottom: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            background: colors.bgApplicationDark,
            strokeTopBottom: colors.strokeNeutralDark,
          },
        },

        [`& > ${TableCellStateContainer} > ${TableCellStateContents}`]: {
          background: colors.bgApplicationLight,

          [darkThemeSelector]: {
            background: colors.bgApplicationDark,
          },
        },

        [`& > ${TableCellStateContainer}[data-side="leading"] > ${TableCellStateContents}`]: {
          strokeNoRight: colors.bgApplicationLight,

          [darkThemeSelector]: {
            strokeNoRight: colors.bgApplicationDark,
          },
        },

        [`& > ${TableCellStateContainer}[data-side="trailing"] > ${TableCellStateContents}`]: {
          strokeNoLeft: colors.bgApplicationLight,

          [darkThemeSelector]: {
            strokeNoLeft: colors.bgApplicationDark,
          },
        },

        [selectors.hover]: {
          zIndex: ZINDEX_BODY_DISABLED,

          [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
            zIndex: ZINDEX_BODY_DISABLED,
            $$leadingTextColor: colors.bodyNeutralLight,
            background: colors.bgNeutralLight,
            strokeTopBottom: colors.strokeNeutralLight,

            [darkThemeSelector]: {
              $$leadingTextColor: colors.bodyNeutralDark,
              background: colors.bgNeutralDark,
              strokeTopBottom: colors.strokeNeutralDark,
            },
          },

          [`& > ${TableCellStateContainer} > ${TableCellStateContents}`]: {
            zIndex: ZINDEX_BODY_DISABLED,
            background: colors.bgNeutralLight,

            [darkThemeSelector]: {
              background: colors.bgNeutralDark,
            },
          },

          [`& > ${TableCellStateContainer}[data-side="leading"] > ${TableCellStateContents}`]: {
            zIndex: ZINDEX_BODY_DISABLED,
            strokeNoRight: colors.strokeNeutralLight,

            [darkThemeSelector]: {
              strokeNoRight: colors.strokeNeutralDark,
            },
          },

          [`& > ${TableCellStateContainer}[data-side="trailing"] > ${TableCellStateContents}`]: {
            zIndex: ZINDEX_BODY_DISABLED,
            strokeNoLeft: colors.strokeNeutralLight,

            [darkThemeSelector]: {
              strokeNoLeft: colors.strokeNeutralDark,
            },
          },
        },
      },
    },
    isSelected: {
      true: {
        zIndex: ZINDEX_BODY_SELECTED,
        $$iconColor: colors.bodyBrandLight,
        $$textColor: colors.headingBrandLight,
        $$leadingTextColor: colors.headingBrandLight,

        [darkThemeSelector]: {
          $$iconColor: colors.bodyBrandDark,
          $$textColor: colors.headingBrandDark,
          $$leadingTextColor: colors.headingBrandDark,
        },

        [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
          background: colors.bgBrandLight,
          strokeTopBottom: colors.strokeBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
            strokeTopBottom: colors.strokeBrandDark,
          },
        },

        [`& > ${TableCellStateContainer} > ${TableCellStateContents}`]: {
          background: colors.bgBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
          },
        },

        [`& > ${TableCellStateContainer}[data-side="leading"] > ${TableCellStateContents}`]: {
          strokeNoRight: colors.strokeBrandLight,

          [darkThemeSelector]: {
            strokeNoRight: colors.strokeBrandDark,
          },
        },

        [`& > ${TableCellStateContainer}[data-side="trailing"] > ${TableCellStateContents}`]: {
          strokeNoLeft: colors.strokeBrandLight,

          [darkThemeSelector]: {
            strokeNoLeft: colors.strokeBrandDark,
          },
        },

        [selectors.hover]: {
          zIndex: ZINDEX_BODY_SELECTED,

          [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
            zIndex: ZINDEX_BODY_SELECTED,
            $$leadingTextColor: colors.headingBrandLight,
            background: colors.bgBrandLight,
            strokeTopBottom: colors.strokeBrandLight,

            [darkThemeSelector]: {
              $$leadingTextColor: colors.headingBrandDark,
              background: colors.bgBrandDark,
              strokeTopBottom: colors.strokeBrandDark,
            },
          },

          [`& > ${TableCellStateContainer} > ${TableCellStateContents}`]: {
            zIndex: ZINDEX_BODY_SELECTED,
            background: colors.bgBrandLight,

            [darkThemeSelector]: {
              background: colors.bgBrandDark,
            },
          },

          [`& > ${TableCellStateContainer}[data-side="leading"] > ${TableCellStateContents}`]: {
            zIndex: ZINDEX_BODY_SELECTED,
            strokeNoRight: colors.strokeBrandLight,

            [darkThemeSelector]: {
              strokeNoRight: colors.strokeBrandDark,
            },
          },

          [`& > ${TableCellStateContainer}[data-side="trailing"] > ${TableCellStateContents}`]: {
            zIndex: ZINDEX_BODY_SELECTED,
            strokeNoLeft: colors.strokeBrandLight,

            [darkThemeSelector]: {
              strokeNoLeft: colors.strokeBrandDark,
            },
          },
        },
      },
      false: {},
    },
  },
});

interface TableRowProps {
  /**
   * The content of the row.
   */
  children?: React.ReactNode | undefined;
  /**
   * Whether the row is navigable and should show it can be clicked/tapped.
   */
  isNavigable?: boolean;
  /**
   * Whether the row is part of a nested `Table`.
   */
  isNested?: boolean;
  /**
   * Whether the row is selected.
   */
  isSelected?: boolean;
}

function TableRowPassthrough<Tag extends React.ElementType>(
  { isNested, isSelected, ...props }: PolymorphicComponentProps<Tag, TableRowProps>,
  ref: PolymorphicRef<Tag>,
) {
  return (
    <TableRowContainer
      ref={ref}
      {...props}
      isSelected={isSelected}
      data-is-selected={isSelected && 'true'}
    >
      <TableRowSelectedProvider value={isSelected}>{props.children}</TableRowSelectedProvider>
    </TableRowContainer>
  );
}

export const TableRow = React.forwardRef(TableRowPassthrough) as Polymorphic.ForwardRefComponent<
  React.ElementType,
  TableRowProps
>;

export const TableBody = styled('tbody');

const TableHeadCellSort = styled('div', {
  position: 'relative',
  width: 0,
  height: '$10',

  variants: {
    isVisible: {
      true: {
        // TRICKY: Toggle visibility rather than display to always reserve
        // space in the layout for the icon.
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },
  defaultVariants: {
    isVisible: false,
  },
});

const TableHeadCellIcon = styled(Icon, {
  width: '$14',
  height: '$14',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },
});

const TableHeadCellSortIcon = styled(Icon, {
  display: 'flex',
  marginLeft: '$4',
});

const TableHeadCellContents = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxHeight: tableHeadRowHeight,
  minHeight: tableHeadRowHeight,
  padding: '$6 $12',
  backgroundColor: colors.bgApplicationLight,
  strokeTopBottom: colors.strokeNeutralLight,
  cursor: 'pointer',
  fontWeight: fontWeights.bold,
  fontFamily: fonts.sans,
  fontSize: '$12',
  lineHeight: '$16',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    strokeTopBottom: colors.strokeNeutralDark,
  },

  variants: {
    alignment: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    canSort: {
      true: {
        outline: 'none',
        cursor: 'pointer',

        [selectors.focus]: {
          '&::before': {
            content: '',
            position: 'absolute',
            inset: '$4',
            display: 'block',
            boxShadow: shadows.focusRingLight,
            borderRadius: '$8',

            [darkThemeSelector]: {
              boxShadow: shadows.focusRingDark,
            },
          },
        },
      },
      false: {
        cursor: 'default',
      },
    },
    internal: {
      true: {
        color: colors.internalBodyLight,

        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },
});

const TableHeadCellContainer = styled('th', {
  position: 'sticky',
  top: 0,
  zIndex: ZINDEX_HEAD_INITIAL,
  padding: 0,

  variants: {
    collapsable: {
      true: {
        width: '$36',
      },
      false: {},
    },
    isLeading: {
      true: {
        left: 0,
        zIndex: ZINDEX_HEAD_LEADING,
      },
      false: {},
    },
    isSorted: {
      true: {
        zIndex: ZINDEX_HEAD_SORTED,
      },
      false: {},
    },
    stuck: {
      top: {},
      right: {
        right: 0,
      },
      bottom: {
        bottom: 0,
      },
      left: {
        left: 0,
      },
    },
  },
});

type TableHeadCellProps = PropsWithChildren<
  HTMLAttributes<HTMLTableCellElement> & {
    /**
     * The alignment of the cell content.
     */
    alignment?: 'start' | 'center' | 'end';
    /**
     * Whether the cell is collapsable.
     */
    collapsable?: boolean;
    /**
     * Whether the cell is collapsed.
     */
    collapsed?: boolean;
    /**
     * Whether to hide the sort icon.
     */
    hideSortIcon?: boolean;
    /**
     * The icon to display in the cell, label is hidden.
     */
    icon?: IconName;
    /**
     * Whether the cell is visible only to Meter employees.
     */
    internal?: boolean;
    /**
     * Whether the cell is part of a nested `Table`.
     */
    isNested?: boolean;
    /**
     * Whether the cell is leading.
     */
    isLeading?: boolean;
    /**
     * The sort direction of the cell.
     */
    sortDirection?: 'asc' | 'desc' | boolean;
    /**
     * Whether the cell is stuck to the top, right, bottom, or left of the `Table`.
     */
    stuck?: 'top' | 'right' | 'bottom' | 'left';
    /**
     * The tooltip contents to display when the cell's label is hovered.
     */
    tooltip?: React.ReactNode;
  }
>;

function TableHeadCellInner(
  {
    alignment,
    children,
    collapsable,
    collapsed,
    hideSortIcon,
    icon,
    internal,
    isNested,
    isLeading,
    sortDirection,
    stuck,
    tooltip,
    ...props
  }: TableHeadCellProps,
  ref: React.ForwardedRef<HTMLTableCellElement>,
) {
  const tableHeadCellLabel = icon ? <TableHeadCellIcon icon={icon} /> : children;
  return (
    <TableHeadCellContainer
      isLeading={isLeading}
      isSorted={!!sortDirection}
      stuck={stuck}
      {...props}
      ref={ref}
    >
      <TableHeadCellContents
        as={collapsable ? 'button' : 'div'}
        canSort={!hideSortIcon || !!sortDirection}
        tabIndex={collapsable || !hideSortIcon ? 0 : undefined}
        alignment={alignment}
        internal={internal}
      >
        {collapsable && (
          <TableCellCollapsable icon={collapsed ? 'chevron-right' : 'chevron-down'} />
        )}
        {tooltip ? (
          <Tooltip asChild={false} contents={tooltip}>
            {tableHeadCellLabel}
          </Tooltip>
        ) : (
          tableHeadCellLabel
        )}
        {!collapsable && (!hideSortIcon || !!sortDirection) && (
          <TableHeadCellSort isVisible={!!sortDirection}>
            <TableHeadCellSortIcon
              icon={sortDirection === 'asc' ? 'chevron-up' : 'chevron-down'}
              size={space(10)}
            />
          </TableHeadCellSort>
        )}
      </TableHeadCellContents>
    </TableHeadCellContainer>
  );
}

export const TableHeadCell = React.forwardRef(
  TableHeadCellInner,
) as Polymorphic.ForwardRefComponent<React.ElementType, TableCellProps>;

const TableHeadRowContainer = styled('tr', {
  position: 'relative',
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

type TableHeadRowProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

function TableHeadRowPassthrough(
  props: TableHeadRowProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const innerRef = useRef<HTMLDivElement>(null);
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([e]) => setIsPinned(e.intersectionRatio < 1), {
      threshold: [1],
    });

    if (innerRef.current) {
      observer.observe(innerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <TableHeadRowContainer {...props} ref={mergeRefs([ref, innerRef])} data-is-pinned={isPinned} />
  );
}

export const TableHeadRow = React.forwardRef<HTMLDivElement, TableHeadCellProps>(
  TableHeadRowPassthrough,
);

export const TableHead = styled('thead');

const TableContainer = styled('table', {
  position: 'relative',
  width: '100%',
  background: colors.bgApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },

  variants: {
    isNested: {
      true: {},
      false: {},
    },
  },
});

type TableNestedProp = boolean;

interface TableProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  /**
   * Whether the table is nested within a parent `Table`.
   */
  isNested?: TableNestedProp;
}

export const Table = React.forwardRef<HTMLDivElement, TableProps>(
  ({ children, isNested, ...props }, ref) => {
    const [scrollRef, canScrollX] = useCanScrollX();
    return (
      <TableContainer
        ref={mergeRefs([ref, scrollRef])}
        isNested={isNested}
        data-can-scroll-x={canScrollX}
        {...props}
      >
        <TableNestedProvider value={isNested}>{children}</TableNestedProvider>
      </TableContainer>
    );
  },
);
