import { useEffect, useMemo, useState } from 'react';

import { Quote } from '@meterup/connect-api';
import { groupBy, sortBy } from 'underscore';

// Utils
import { onlyUnique } from '../utils/Array.utils';

// A hooks to keep quotes logic / filtering
export default function useMonthlyFeeFilter(quotes: Quote[]) {
  // Sort by price, group by downloadKbps
  const quotesByContractLengthMonths = useMemo(() => {
    const sortedQuotes = sortBy(quotes, (quote) => quote.monthlyFeeCents);
    return groupBy(sortedQuotes, (quote) => quote && quote.contractLengthMonths);
  }, [quotes]);

  // Contract Length - All
  const allContractLengthMonths = useMemo(
    () =>
      quotes
        .map((quote: Quote) => quote.contractLengthMonths)
        .filter(onlyUnique)
        .sort((a, b) => (a < b ? -1 : 1)),
    [quotes],
  );

  // Contract Length - Currently selected (default: all)
  const [currentContractLengthMonths, setCurrentContractLengthMonths] =
    useState(allContractLengthMonths);

  // Contract Length - setstate
  useEffect(() => {
    setCurrentContractLengthMonths(allContractLengthMonths);
  }, [allContractLengthMonths]);

  // Contract Length - User event change
  const onContractLengthMonthsChange = (contractLengthMonth: number, checked: boolean) => {
    let newContractLengthMonths = currentContractLengthMonths;
    if (checked) {
      newContractLengthMonths.push(contractLengthMonth);
    } else {
      newContractLengthMonths = newContractLengthMonths.filter(
        (item) => item !== contractLengthMonth,
      );
    }
    setCurrentContractLengthMonths(newContractLengthMonths.filter(onlyUnique));
  };

  const resetContractLengthMonthsFilter = () => {
    setCurrentContractLengthMonths(allContractLengthMonths);
  };

  return {
    quotesByContractLengthMonths,
    allContractLengthMonths,
    currentContractLengthMonths,
    onContractLengthMonthsChange,
    resetContractLengthMonthsFilter,
  };
}
