/* eslint-disable react/state-in-constructor */
import React from 'react';

type WithChildren = {
  children: React.ReactNode;
};

export default class ErrorBoundary extends React.Component<WithChildren> {
  readonly state: { error?: Error };

  constructor(props: any) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    if (this.state.error) {
      return (
        <div className="mt-8 ml-4">
          <h3 className="mb-4 text-2xl font-semibold">Whoops!</h3>
          <p>Something went wrong.</p>
          <pre className="w-11/12 p-2 mt-4 bg-gray-50 border border-gray-600 rounded">
            <code>{String(this.state.error.stack)}</code>
          </pre>
        </div>
      );
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}
