// Libraries
import React, { useEffect, useState } from 'react';

// Components
import { Button, HStack, Icon, space } from '@meterup/atto';
import cx from 'classnames';

import styles from './QuotesListHeader.module.css';

// TODO: get Interface from @api
interface QuotesListHeaderProps {
  onClick: () => void;
  open: boolean;
  title: React.ReactNode;
}

function QuotesListHeader({ onClick, open, title }: QuotesListHeaderProps) {
  const [init, setInit] = useState(true);

  useEffect(() => {
    setInit(false);
  }, []);
  return (
    <div className={cx('pl-2 text-left cursor-pointer', styles.content)} onClick={onClick}>
      <div className={cx('inline-block', styles.badgeWrapper)}>
        <Button variant="secondary">
          <HStack width="full" align="center" spacing={space(1)}>
            {init || open ? (
              <Icon size={3} color={{ light: 'gray200' }} icon="chevron-down" />
            ) : (
              <Icon size={3} color={{ light: 'gray200' }} icon="chevron-up" />
            )}
            <span>{title}</span>
          </HStack>
        </Button>
      </div>
    </div>
  );
}

export default QuotesListHeader;
