import type { ComponentProps } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import { Icon } from '../../assets/Icon/Icon';
import { FocusRing } from '../../common/focus_rings';
import { visuallyHiddenCSS } from '../../common/visually_hidden';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { space } from '../../utilities/shared/sizes';

const LegendIcon = styled(Icon, {
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const LegendSpeed = styled('div', {
  minWidth: '$12',
  minHeight: '$12',
  borderRadius: '$4',
  variants: {
    disabled: {
      true: {
        backgroundColor: colors.hardwareBgDisabledLight,
        strokeAll: colors.hardwareStrokeDisabledLight,

        [darkThemeSelector]: {
          backgroundColor: colors.hardwareBgDisabledDark,
          strokeAll: colors.hardwareStrokeDisabledDark,
        },
      },
      false: {},
    },
    error: {
      true: {
        backgroundColor: colors.hardwareBgNegativeLight,
        strokeAll: colors.hardwareStrokeNegativeLight,

        [darkThemeSelector]: {
          backgroundColor: colors.hardwareBgNegativeDark,
          strokeAll: colors.hardwareStrokeNegativeDark,
        },
      },
      false: {},
    },
    variant: {
      alternative: {
        backgroundColor: colors.hardwareBgAlternativeLight,
        strokeAll: colors.hardwareStrokeAlternativeLight,

        [darkThemeSelector]: {
          backgroundColor: colors.hardwareBgAlternativeDark,
          strokeAll: colors.hardwareStrokeAlternativeDark,
        },
      },
      attention: {
        backgroundColor: colors.hardwareBgAttentionLight,
        strokeAll: colors.hardwareStrokeAttentionLight,

        [darkThemeSelector]: {
          backgroundColor: colors.hardwareBgAttentionDark,
          strokeAll: colors.hardwareStrokeAttentionDark,
        },
      },
      neutral: {
        backgroundColor: colors.hardwareBgNeutralLight,
        strokeAll: colors.hardwareStrokeNeutralLight,

        [darkThemeSelector]: {
          backgroundColor: colors.hardwareBgNeutralDark,
          strokeAll: colors.hardwareStrokeNeutralDark,
        },
      },
      positive: {
        backgroundColor: colors.hardwareBgPositiveLight,
        strokeAll: colors.hardwareStrokePositiveLight,

        [darkThemeSelector]: {
          backgroundColor: colors.hardwareBgPositiveDark,
          strokeAll: colors.hardwareStrokePositiveDark,
        },
      },
    },
  },
});

const LegendLabel = styled(Small, {
  minWidth: 0,
  color: colors.bodyNeutralLight,
  truncate: true,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  '@notDesktop': visuallyHiddenCSS,
});

const LegendContainer = styled('div', FocusRing, {
  position: 'relative',
  hStack: '$6',
  minWidth: 0,
  minHeight: '$20',
  padding: '$2 $6',
  borderRadius: '$6',

  '&[role="button"]': {
    [selectors.hover]: {
      [`${LegendLabel}`]: {
        color: colors.headingNeutralLight,

        [darkThemeSelector]: {
          color: colors.headingNeutralDark,
        },
      },
    },

    [selectors.focus]: {
      '&::after': {
        opacity: 0,
      },
    },
  },

  variants: {
    active: {
      true: {
        backgroundColor: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },

        [`${LegendLabel}`]: {
          color: colors.headingBrandLight,

          [darkThemeSelector]: {
            colors: colors.headingBrandDark,
          },
        },
        '&::after': {
          opacity: 0,
        },
      },
      false: {},
    },
  },
});

export type LegendProps = {
  active?: boolean;
  disabled?: boolean;
  error?: boolean;
  icon?: IconName;
  label?: React.ReactNode;
  onClick?: (event: any) => void;
  variant?: 'alternative' | 'attention' | 'neutral' | 'positive';
};

export function Legend({
  active,
  label,
  disabled,
  error,
  icon,
  onClick,
  variant,
  ...remaining
}: LegendProps) {
  const portLegendRender = (
    <LegendContainer
      tabIndex={onClick && 0}
      role={onClick && 'button'}
      active={active}
      {...remaining}
    >
      {icon ? (
        <LegendIcon icon={icon} size={space(12)} />
      ) : (
        <LegendSpeed disabled={disabled} error={error} variant={variant} />
      )}
      <LegendLabel weight="bold">{label}</LegendLabel>
    </LegendContainer>
  );

  return (
    <Tooltip hideOnDesktop contents={label} side="top" sideOffset={2}>
      {portLegendRender}
    </Tooltip>
  );
}

const LegendsDivider = styled('div', {
  display: 'flex',
  width: '$2',
  height: '$12',
  backgroundColor: colors.strokeNeutralLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
});

const LegendsLegend = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',

  '&:last-child': {
    [`${LegendsDivider}`]: {
      ...visuallyHiddenCSS,
    },
  },
});

const LegendsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
});

export type LegendsProps = {
  legends: LegendProps[];
} & ComponentProps<typeof LegendsContainer>;

export function Legends({ legends, ...remaining }: LegendsProps) {
  return (
    <LegendsContainer {...remaining}>
      {legends.map((legend) => (
        <LegendsLegend key={`legend-${legend.label}`}>
          <Legend {...legend} />
          <LegendsDivider />
        </LegendsLegend>
      ))}
    </LegendsContainer>
  );
}
