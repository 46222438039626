/* eslint-disable no-empty */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useEffect, useMemo, useState } from 'react';

import { Quote } from '@meterup/connect-api';
import { groupBy, sortBy } from 'underscore';

export default function useConnectionTypeFilter(quotes: Quote[]) {
  const quotesByConnectionType = useMemo(() => {
    const sortedQuotes = sortBy(quotes, (quote) => quote.monthlyFeeCents);
    return groupBy(sortedQuotes, (quote) => quote?.connectionType?.name || 'unknown');
  }, [quotes]);
  const allConnectionTypes = useMemo(() => Object.keys(quotesByConnectionType).sort(), [quotes]);
  const [currentConnectionTypes, setCurrentConnectionTypes] = useState(allConnectionTypes);

  useEffect(() => {
    setCurrentConnectionTypes(allConnectionTypes);
  }, [allConnectionTypes]);

  const onCurrentConnectionTypesChange = (connectionType: string, checked: boolean) => {
    const connectionTypesSet = new Set(currentConnectionTypes);
    if (connectionTypesSet.has(connectionType) && !checked) {
      connectionTypesSet.delete(connectionType);
    } else {
      connectionTypesSet.add(connectionType);
    }

    setCurrentConnectionTypes([...connectionTypesSet]);
  };

  const resetCurrentConnectionTypes = () => {
    setCurrentConnectionTypes(allConnectionTypes);
  };

  return {
    allConnectionTypes,
    currentConnectionTypes,
    onCurrentConnectionTypesChange,
    quotesByConnectionType,
    resetCurrentConnectionTypes,
  };
}
