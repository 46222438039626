/* eslint-disable eqeqeq */
/* eslint-disable import/no-cycle */
import React from 'react';

import cx from 'classnames';

import Header from './Header';

interface BaseLayoutProps {
  children: React.ReactNode;
  align?: 'center' | 'top';
  layoutMargin?: boolean;
}

// TODO RENAME THIS to MainLayout
function BaseLayout({ children, align = 'center', layoutMargin = true }: BaseLayoutProps) {
  let alignClass;
  if (align == 'center') {
    alignClass = 'items-center';
  } else {
    alignClass = 'items-top';
  }

  const contentClass = cx('w-full 2xl:container mx-auto', {
    'px-6': layoutMargin,
  });

  return (
    <main className="w-full flex flex-col ">
      <Header />
      <div className={cx('flex-grow flex h-full mt-48', alignClass)}>
        <div className={contentClass}>{children}</div>
      </div>
    </main>
  );
}

export default BaseLayout;
