/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import {
  Accordion as AccordionMetric,
  AccordionContent,
  AccordionItem as AccordionItemMetric,
  AccordionTrigger,
  Body,
  Caption,
  theme,
} from '@meterup/atto';

const { styled } = theme;

const Accordion = styled(AccordionMetric, {
  boxShadow: 'unset',

  '& > :not(:first-child)': {
    boxShadow: '$none',
  },
});

const AccordionItemMetric2 = styled(AccordionItemMetric, {
  boxShadow: 'unset',
  padding: '$8 0 $8 0',
});

const AccordionContent2 = styled(AccordionContent, {
  '& > div': {
    display: 'flex',
  },
});

// Props will be a bunch of onchangeevent

function QuotesResultsSidebar() {
  const [selectedItem, setSelectedItem] = useState('0');

  const renderConnectionTypeSection = () => (
    <div>
      <p className="text-gray-600">
        <strong className="font-medium text-gray-700">Shared fiber</strong> is shared between you
        and everyone else on the same street—apartments, houses, and other businesses—so your speeds
        and latency will change based on how much other traffic there is at the same time.
      </p>
      <p className="text-gray-600 mt-2">
        <strong className="font-medium text-gray-700">Dedicated fiber</strong> only runs your
        business’ traffic, and therefore, you are not sharing traffic with anyone else on your
        street. It is also on more robust infrastructure. Generally, service-level agreements (SLAs)
        like minimum speeds, maximum downtime, and maximum latencies will be part of the contract
        agreement. If those agreed-upon parameters are not met, you will receive account credits as
        compensation.
      </p>
      <p className="text-gray-600 mt-2">
        <strong className="font-medium text-gray-700">Coax cable</strong> offers a cheaper solution,
        its performance starts to degrade after about 300 feet. It also cannot provide symmetrical
        speeds (i.e., upload speeds that are as fast as download speeds). If you need strong upload
        speeds (for things like video conferencing and data set uploads) and want a more reliable
        connection, you should consider fiber.
      </p>
    </div>
  );

  const accordionItems = [
    {
      value: '0',
      title: 'How does Meter get paid?',
      content:
        'We make a small commission off of each contract from the ISP at no additional cost to you.',
    },
    {
      value: '1',
      title: 'How is your service free?',
      content:
        "We work with ISPs to find every quote for your space and truly find you the best quotes, since we work with them so often. Once you decide which provider you'll move forward with, you'll sign a contract and pay them directly.",
    },
    // {
    //   value:"2",
    //   title: 'How much bandwidth do I need?',
    //   content: renderBandwdithSection(),
    // },
    {
      value: '3',
      title: 'Which connection type is best for me?',
      content: renderConnectionTypeSection(),
    },
  ];
  return (
    <section>
      <Caption>FAQs</Caption>
      <Accordion
        className="mt-2"
        type="single"
        value={selectedItem}
        onValueChange={setSelectedItem}
        collapsible
      >
        {accordionItems.map((item, i) => (
          <AccordionItemMetric2
            value={`${item.value}`}
            className={i > 0 ? 'm-shadow-surfaceTop' : ''}
            key={`faq-item-${i}`}
          >
            <AccordionTrigger className="text-left">
              <Body weight="bold">{item.title}</Body>
            </AccordionTrigger>
            <AccordionContent2>
              <div className="m-flex m-bg-gray-50 dark:m-bg-gray-900 m-rounded-full m-w-0.5">
                &nbsp;
              </div>
              {item.content}
            </AccordionContent2>
          </AccordionItemMetric2>
        ))}
      </Accordion>
    </section>
  );
}

export default QuotesResultsSidebar;
