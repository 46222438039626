import { useCallback, useMemo } from 'react';

import { axios, AxiosAPIError, QuotesRequest } from '@meterup/connect-api';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// A hooks to keep quotes logic / filtering
export default function useQuotes(quoteRequestSid: string, selectedQuoteSids: string[]) {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ['quoteRequest', quoteRequestSid, 'quotes'], [quoteRequestSid]);
  const query = useQuery<unknown, AxiosAPIError, QuotesRequest>({
    queryKey,
    queryFn: () => axios.get(`v1/connect/quote-requests/${quoteRequestSid}/quotes`),
  });
  const loadingQuotes = query.isLoading;
  const quotes = useMemo(() => query.data?.quotes ?? [], [query]);
  const reloadQuotes = useCallback(
    () => queryClient.invalidateQueries(['quoteRequest', quoteRequestSid]),
    [queryClient, quoteRequestSid],
  );

  // Enabled quotes
  const enabledQuotes = useMemo(
    // eslint-disable-next-line eqeqeq
    () => quotes.filter((quote) => quote.status != 'disabled'),
    [quotes],
  );

  // Manual quotes (ie: needs meter admin to request the quote)
  const proposedQuotes = useMemo(
    () =>
      enabledQuotes
        // eslint-disable-next-line eqeqeq
        .filter((quote) => quote.status == 'proposed' || quote.status == 'requested')
        .sort((a, b) => (a.provider!.name < b.provider!.name ? -1 : 1)),
    [enabledQuotes],
  );

  const recommendedQuotes = useMemo(
    () => enabledQuotes.filter((quote) => quote.recommended),
    [enabledQuotes],
  );

  // Generated quotes (ie: automatically generated quotes from easyquote)
  const generatedQuotes = useMemo(
    // eslint-disable-next-line eqeqeq
    () => enabledQuotes.filter((quote) => quote.status == 'enabled'),
    [enabledQuotes],
  );

  // User selected quotes
  const selectedQuotes = useMemo(
    () =>
      selectedQuoteSids
        .map((sid) => generatedQuotes.find(({ sid: quoteSid }) => quoteSid === sid))
        .filter((q) => !!q),
    [generatedQuotes, selectedQuoteSids],
  );

  const unselectedQuotes = useMemo(
    () => generatedQuotes.filter(({ sid }) => !selectedQuoteSids.includes(sid)),
    [generatedQuotes, selectedQuoteSids],
  );

  // Primary and secondary
  const primaryQuote = useMemo(() => selectedQuotes[0], [selectedQuotes]);

  const secondaryQuote = useMemo(() => selectedQuotes[1], [selectedQuotes]);

  return {
    loadingQuotes,
    quotes,
    reloadQuotes,
    enabledQuotes,
    proposedQuotes,
    generatedQuotes,
    selectedQuotes,
    unselectedQuotes,
    primaryQuote,
    secondaryQuote,
    recommendedQuotes,
  };
}
