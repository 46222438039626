import { get as lodashGet, has as lodashHas, isFunction as lodashIsFunction } from 'lodash-es';

export function get<T, K extends keyof T>(object: T | undefined, key: K): T[K] | undefined {
  return lodashGet(object, key);
}

export function isFunction<T extends (...args: any[]) => any>(value: any): value is T {
  return lodashIsFunction(value);
}

export function has<R extends string, O = unknown>(obj: O, key: R): obj is O & Record<R, unknown> {
  return lodashHas(obj, key);
}
