import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import type { HasChildren } from '../types/components';
import { breakpoints } from '../common/breakpoints';

interface ReadonlySize {
  readonly width: number;
  readonly height: number;
  readonly breakpoint: 'mobile' | 'tablet' | 'notDesktop' | 'desktop';
}

const ViewportContext = createContext<ReadonlySize>({ height: 0, width: 0, breakpoint: 'desktop' });

const useViewportSizeMonitor = (): ReadonlySize => {
  const calculateBreakpointForWidth = (width: number) => {
    if (width <= breakpoints.sm) {
      return 'mobile';
    }
    if (width > breakpoints.sm && width < breakpoints.lg) {
      return 'tablet';
    }
    if (width <= breakpoints.lg) {
      return 'notDesktop';
    }
    return 'desktop';
  };

  const [size, setSize] = useState<ReadonlySize>({
    width: window.innerWidth,
    height: window.innerHeight,
    breakpoint: calculateBreakpointForWidth(window.innerWidth),
  });

  const handleResize = useCallback(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
      breakpoint: calculateBreakpointForWidth(window.innerWidth),
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return size;
};

/**
 * Measures the inner width and height of the window element and makes the dimensions available to
 * the {@link useViewport} hook through context.
 */
export function ViewportProvider({ children }: HasChildren) {
  const size = useViewportSizeMonitor();
  return <ViewportContext.Provider value={size}>{children}</ViewportContext.Provider>;
}

/**
 * @returns The inner width and height of the window element, representing the dimensions of the
 * user's viewport for use in responsive layouts.
 */
export const useViewport = (): ReadonlySize => useContext(ViewportContext);
