import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';

const Container = styled('svg', {
  display: 'flex',
  variants: {
    color: {
      brand: {
        fill: colors.brand600,
      },
      white: {
        fill: colors.white,
      },
      auto: {
        fill: colors.brand600,
        [darkThemeSelector]: {
          fill: colors.white,
        },
      },
    },
  },
});

export interface LogoProps {
  /**
   * Overwrite the automatically applied dark/light mode colors.
   */
  color?: 'auto' | 'brand' | 'white';
  /**
   * Height is controlled with the rem scale (1 = 4px).
   */
  height?: 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  /**
   * Change which type of logo appears between the full wordtype or just the first letter.
   */
  variant?: 'full' | 'initial';
}

export function Logo({ color = 'auto', height = 5, variant = 'full', ...remaining }: LogoProps) {
  return variant === 'initial' ? (
    <Container
      viewBox="0 0 16 12"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      style={{ height: height * 4 }}
      {...remaining}
    >
      <path d="M0 12H1.95759V11.7891C1.95759 11.3533 1.95655 10.9175 1.95551 10.4816C1.95293 9.39739 1.95035 8.31314 1.96374 7.22907C1.9713 6.61549 2.01838 5.99889 2.09976 5.39062C2.2092 4.57247 2.47684 3.80033 2.94454 3.10919C3.40487 2.42899 3.98396 1.91303 4.84195 1.81752C5.82646 1.70798 6.6507 2.09313 6.89129 3.16868C6.97909 3.56097 7.00594 3.97358 7.00757 4.37711C7.01494 6.16811 7.01422 7.95916 7.0135 9.7502C7.01323 10.4229 7.01296 11.0955 7.01312 11.7682C7.01312 11.8203 7.01659 11.8724 7.02004 11.924C7.02163 11.9478 7.02321 11.9715 7.02444 11.9951H8.98996V11.8014C8.98995 11.3554 8.98891 10.9094 8.98788 10.4634C8.98528 9.34412 8.98269 8.22484 8.99628 7.10574C9.00353 6.50952 9.05 5.9092 9.13794 5.31958C9.28321 4.34517 9.63124 3.44532 10.2877 2.68683C10.8244 2.0668 11.4969 1.74861 12.3249 1.80529C13.0071 1.852 13.5155 2.16672 13.7942 2.80867C13.9898 3.25896 14.0282 3.73622 14.0286 4.21776C14.0302 6.09699 14.0299 7.9762 14.0297 9.8554C14.0297 10.5021 14.0296 11.1487 14.0296 11.7954V11.9978H15.9905C15.9915 11.9792 15.9927 11.9613 15.9938 11.9441C15.9961 11.9085 15.9983 11.8752 15.9983 11.842C15.9984 11.2075 15.9988 10.573 15.9991 9.93856C16.0004 7.85505 16.0016 5.77153 15.9923 3.68808C15.9903 3.26806 15.942 2.84027 15.851 2.43021C15.6525 1.53647 15.1866 0.808494 14.3461 0.384811C13.4038 -0.0902433 12.413 -0.0943881 11.4221 0.202971C10.3082 0.537228 9.5221 1.28569 8.93171 2.26023C8.90778 2.29975 8.88371 2.33916 8.85547 2.38541C8.84187 2.40767 8.8273 2.43152 8.81132 2.45773C8.80222 2.42624 8.79451 2.3991 8.78761 2.37482C8.77492 2.33019 8.76497 2.29519 8.7542 2.26047C8.46598 1.3319 7.91506 0.627877 6.99365 0.258421C6.2795 -0.0279304 5.53613 -0.0565045 4.79055 0.100839C3.56017 0.360451 2.68555 1.09797 2.07043 2.1689C2.05113 2.20254 2.03193 2.23626 2.01274 2.26997C2.00355 2.28611 1.99437 2.30224 1.98518 2.31836L1.95208 2.30521V0.224988H0V12Z" />
    </Container>
  ) : (
    <Container
      viewBox="0 0 51 16"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      style={{ height: height * 4 }}
      {...remaining}
    >
      <g>
        <path d="M30.0546 0.0716724C30.0527 0.0477816 30.0509 0.0238908 30.0495 0H30.0161C30.0019 0.0115478 29.988 0.0236354 29.9741 0.0357236C29.9443 0.0616918 29.9145 0.0876623 29.8811 0.108287C29.7657 0.179717 29.6504 0.251396 29.5351 0.323076C29.1316 0.573972 28.7278 0.825005 28.3177 1.06539C28.1628 1.15613 28.1164 1.2555 28.118 1.42583C28.1246 2.1587 28.1237 2.89163 28.1229 3.62454V3.62514C28.1226 3.87377 28.1223 4.12239 28.1223 4.37102V4.57073H26.1341V6.25847H28.1223V6.46729C28.1223 6.98616 28.1215 7.50503 28.1207 8.02391V8.02446C28.1186 9.42036 28.1165 10.8163 28.1298 12.212C28.1344 12.6987 28.1841 13.1949 28.2954 13.6686C28.5407 14.7121 29.1872 15.4466 30.2415 15.8105C30.9076 16.0404 31.5931 16.0351 32.2815 15.9317C32.6666 15.8739 33.0364 15.7684 33.3774 15.5608L32.9175 13.9389C32.528 14.1808 32.1146 14.2877 31.6759 14.3041C30.9353 14.3317 30.3914 13.976 30.1879 13.2853C30.1023 12.9948 30.0698 12.6808 30.0682 12.3772C30.0603 10.842 30.0615 9.30684 30.0626 7.77164C30.0629 7.32717 30.0632 6.8827 30.0633 6.43824C30.0634 6.40024 30.0659 6.36227 30.0689 6.31912C30.0704 6.29709 30.072 6.27371 30.0733 6.24829H33.1003V4.5585H30.063L30.063 3.5492C30.0631 2.44127 30.0631 1.33336 30.0625 0.225428C30.0625 0.174176 30.0585 0.122924 30.0546 0.0716724Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.2185 10.5652H34.8658C34.8888 10.6956 34.9094 10.8242 34.9297 10.9511C34.974 11.2281 35.0171 11.4976 35.0816 11.7618C35.2448 12.43 35.5535 13.0288 36.0427 13.5225C36.488 13.972 37.0387 14.1992 37.6634 14.2691C38.1882 14.3278 38.7037 14.2851 39.2117 14.1487C39.9545 13.9492 40.4558 13.4456 40.8561 12.8237C40.9514 12.6757 41.0367 12.5214 41.1246 12.3627C41.1655 12.2888 41.2069 12.2139 41.2501 12.1382L42.9058 12.854C42.8875 12.8903 42.8697 12.9262 42.8521 12.9617C42.8145 13.0376 42.7776 13.1119 42.7388 13.1849C42.3265 13.9585 41.819 14.6533 41.0959 15.1721C40.528 15.5796 39.886 15.8002 39.2038 15.9071C38.4591 16.0238 37.7108 16.0441 36.9682 15.8891C35.7329 15.6314 34.7428 14.9958 34.0382 13.9445C33.4095 13.0065 33.0819 11.963 32.9879 10.8426C32.877 9.52166 33.0623 8.25145 33.6468 7.05164C34.2755 5.76129 35.2481 4.8591 36.6604 4.50252C38.1363 4.12991 39.5597 4.27773 40.8828 5.06805C41.8213 5.62866 42.4101 6.47796 42.8066 7.4743C43.1031 8.21916 43.2152 8.99729 43.2183 9.79419C43.219 9.96292 43.2189 10.1316 43.2187 10.3086L43.2185 10.5652ZM41.2041 8.84734C40.9757 7.51411 40.0413 5.88625 37.845 6.03274C37.0636 6.08484 36.393 6.37347 35.8761 6.97289C35.4107 7.51255 35.1424 8.14354 35.0118 8.84734H41.2041Z"
        />
        <path d="M50.9175 5.06442C50.8517 5.31791 50.7858 5.57191 50.7212 5.82566C50.6911 5.94403 50.6629 6.06287 50.6338 6.18583L50.6336 6.18665C50.6198 6.24503 50.6058 6.30433 50.5912 6.36496C50.575 6.3602 50.5601 6.35617 50.5461 6.35238C50.5186 6.34497 50.4947 6.33851 50.4719 6.32945C49.128 5.79739 48.0369 6.05885 47.1092 7.12968C46.5367 7.79063 46.2419 8.56833 46.0459 9.38831C45.8816 10.0757 45.8049 10.7733 45.8033 11.4776C45.8011 12.4926 45.8015 13.5076 45.8019 14.5226C45.802 14.9355 45.8022 15.3484 45.8021 15.7612V15.9695H43.8037V4.52745H45.798V6.43009C45.8462 6.36427 45.8905 6.30094 45.933 6.24005C46.0199 6.11572 46.0997 6.00137 46.1917 5.89701C47.0468 4.92635 48.1004 4.31785 49.4708 4.32803C50.0047 4.33199 50.5252 4.42556 51 4.68012V4.74604L50.9175 5.06442Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.6901 10.5658H18.371C18.4112 11.352 18.5711 12.0898 18.9533 12.7683C19.1926 13.193 19.4875 13.5709 19.9037 13.842C20.4779 14.2159 21.1156 14.3245 21.7863 14.2906C22.5504 14.2521 23.2525 14.0584 23.8058 13.4872C24.1452 13.1369 24.415 12.7408 24.6276 12.304C24.6443 12.2697 24.6613 12.2354 24.6801 12.1976L24.712 12.133L26.3878 12.8575C26.3476 12.9317 26.3087 13.0055 26.2701 13.0787C26.187 13.2363 26.1052 13.3915 26.0154 13.5414C25.5634 14.2959 25.006 14.9512 24.2266 15.3919C23.6702 15.7065 23.0642 15.87 22.4351 15.9437C21.78 16.0203 21.1225 16.0313 20.4718 15.8971C19.1311 15.6205 18.0902 14.9123 17.3778 13.7391C16.8454 12.8625 16.5538 11.9085 16.4635 10.8907C16.345 9.55365 16.5256 8.2667 17.1189 7.05083C17.7486 5.76056 18.7217 4.85844 20.1347 4.50232C21.6173 4.12866 23.0463 4.27878 24.3729 5.07713C25.3047 5.63792 25.8905 6.4843 26.2837 7.47533C26.5749 8.20924 26.6945 8.97549 26.6904 9.76272C26.6894 9.93918 26.6897 10.1157 26.6899 10.3003C26.69 10.3867 26.6901 10.475 26.6901 10.5658ZM24.6894 8.84987C24.341 7.1324 23.2502 5.88576 21.2581 6.03636C20.4901 6.09441 19.833 6.39851 19.3301 6.99362C18.8771 7.52971 18.6119 8.15184 18.4833 8.84987H24.6894Z"
        />
        <path d="M0 16H1.92014V15.7945C1.92014 15.3699 1.91912 14.9452 1.91811 14.5206C1.91557 13.4641 1.91304 12.4077 1.92618 11.3514C1.93359 10.7536 1.97977 10.1528 2.05959 9.5601C2.16694 8.76294 2.42946 8.01059 2.88822 7.33717C3.33974 6.67441 3.90776 6.17168 4.74933 6.07863C5.71501 5.97189 6.52348 6.34717 6.75947 7.39514C6.8456 7.77737 6.87192 8.1794 6.87353 8.57258C6.88076 10.3177 6.88005 12.0628 6.87934 13.8079C6.87908 14.4633 6.87881 15.1187 6.87897 15.7741C6.87897 15.8249 6.88238 15.8756 6.88576 15.926C6.88732 15.9491 6.88887 15.9722 6.89008 15.9952H8.81799V15.8065C8.81798 15.3719 8.81697 14.9374 8.81596 14.5028C8.81341 13.4122 8.81086 12.3216 8.8242 11.2312C8.83131 10.6503 8.87689 10.0654 8.96315 9.49088C9.10564 8.54146 9.44701 7.66468 10.0909 6.92565C10.6174 6.32152 11.277 6.01149 12.0892 6.06671C12.7583 6.11223 13.257 6.41888 13.5304 7.04436C13.7222 7.4831 13.7599 7.94813 13.7603 8.41732C13.7618 10.2484 13.7616 12.0794 13.7614 13.9104C13.7613 14.5405 13.7612 15.1705 13.7612 15.8006V15.9979H15.6846C15.6856 15.9797 15.6867 15.9623 15.6879 15.9455C15.6902 15.9108 15.6923 15.8784 15.6923 15.8461C15.6924 15.2279 15.6927 14.6096 15.6931 13.9914C15.6943 11.9613 15.6956 9.93125 15.6863 7.90122C15.6845 7.49197 15.6371 7.07515 15.5478 6.67561C15.3531 5.80478 14.8961 5.09548 14.0717 4.68266C13.1474 4.21978 12.1755 4.21575 11.2036 4.50548C10.111 4.83117 9.33996 5.56043 8.76087 6.50998C8.73739 6.54849 8.71378 6.58689 8.68608 6.63195C8.67274 6.65365 8.65845 6.67688 8.64277 6.70242C8.63385 6.67173 8.62628 6.6453 8.61951 6.62164C8.60707 6.57815 8.59731 6.54405 8.58674 6.51021C8.30404 5.60546 7.76365 4.91949 6.85988 4.55951C6.15939 4.2805 5.43023 4.25266 4.69891 4.40597C3.49207 4.65892 2.63418 5.37753 2.03083 6.421C2.01189 6.45378 1.99306 6.48663 1.97424 6.51948C1.96523 6.5352 1.95622 6.55092 1.9472 6.56662L1.91474 6.55381V4.52693H0V16Z" />
      </g>
    </Container>
  );
}
