import { darkThemeSelector } from '@meterup/common';

import { styled } from '../styled';

export const AlertWrapper = styled('div', {
  boxShadow: '$fenceBottomLight',
  [darkThemeSelector]: {
    boxShadow: '$fenceBottomDark',
  },
  '& > div': {
    borderRadius: 0,
  },
});
