/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useCallback, useMemo } from 'react';

import { Button } from '@meterup/atto';
import type { AxiosAPIError, QuoteRequest } from '@meterup/connect-api';
import { axios, put } from '@meterup/connect-api';
import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { useParams } from 'react-router-dom';

// Components
import SidebarLayout from '../../components/Layout/SidebarLayout';
import CheckoutHeaderNavigation from '../../components/QuotesContract/CheckoutHeaderNavigation/CheckoutHeaderNavigation';
import QuotesContractContactInfoForm from '../../components/QuotesContract/QuotesContractContactInfoForm/QuotesContractContactInfoForm';
import QuotesContractNetwork from '../../components/QuotesContract/QuotesContractNetwork/QuotesContractNetwork';
import QuotesContractSidebar from '../../components/QuotesContract/QuotesContractSidebar/QuotesContractSidebar';
// hooks
import useQuotes from '../../hooks/useQuotes';

function QuoteRequestsContact() {
  // Router
  const { sid } = useParams<{ sid: string }>();
  // const [loading, quoteRequest, reloadQuoteRequest] = useApiResource<QuoteRequest>(
  //   `quote-requests/${sid}`,
  //   false,
  //   true,
  // );
  const {
    data: quoteRequest,
    isLoading: loading,
    isError: failed,
    refetch: reloadQuoteRequest,
  } = useQuery<unknown, AxiosAPIError, QuoteRequest>(
    ['quoteRequest', sid],
    () => axios.get(`v1/connect/quote-requests/${sid}`),
    {
      enabled: !!sid,
    },
  );

  const selectedQuoteSids = useMemo(
    () => (quoteRequest ? quoteRequest.selectedQuotes : []),
    [quoteRequest],
  );

  // Quotes
  // @ts-ignore
  const { loadingQuotes, primaryQuote, secondaryQuote } = useQuotes(sid, selectedQuoteSids);

  const renderSidebar = () => {
    const buttonWrapperClass = cx(
      'md:rounded-lg',
      'bg-white md:bg-gray-100 md:bg-opacity-30',
      'w-full fixed z-200 left-0 bottom-0 md:relative',
      'border-t border-gray-100 md:border-none',
      'p-4 pt-5 md:p-6 md:pt-6 pr-24 md:pr-6 md:mt-8',
    );

    if (!primaryQuote) {
      return null;
    }
    if (!quoteRequest) {
      return null;
    }
    return (
      <div className="pb-8 md:pb-0">
        <QuotesContractSidebar
          primaryQuote={primaryQuote}
          secondaryQuote={secondaryQuote}
          quoteRequest={quoteRequest}
        />
        <div className={buttonWrapperClass}>
          <Button size="large" width="100%" type="submit" form="contactForm" variant="primary">
            Submit order request
          </Button>
          <p className="text-center text-xs md:text-sm mt-2 md:mt-4 text-gray-400">
            We’ll contact you before anything is committed.
          </p>
        </div>
      </div>
    );
  };

  const onRequestNetwork = useCallback(async () => {
    if (!quoteRequest) {
      return;
    }
    if (!reloadQuoteRequest) {
      return;
    }
    const response = await put(`quote-requests/${quoteRequest.sid}`, {
      is_interested_in_network: !quoteRequest.isInterestedInNetwork,
      is_interested_in_network_changed: true,
    });

    reloadQuoteRequest();
  }, [quoteRequest]);

  if (loading) {
    return null;
  }

  return (
    <SidebarLayout sidebarChildren={renderSidebar()} hideSidebarOnMobile={false} align="top">
      <div className="mx-auto max-w-2xl max-w-screen-xs w-full">
        {/* @ts-ignore */}
        <CheckoutHeaderNavigation quoteSid={sid} />
        <div className="mt-8">
          {quoteRequest && <QuotesContractContactInfoForm quoteRequest={quoteRequest} />}
        </div>

        <div className="mt-8">
          {quoteRequest && (
            <QuotesContractNetwork
              quoteRequest={quoteRequest}
              onRequestNetwork={onRequestNetwork}
            />
          )}
        </div>
      </div>
    </SidebarLayout>
  );
}

export default QuoteRequestsContact;
