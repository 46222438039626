import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Box } from '../../utilities/Box/Box';
import { space } from '../../utilities/shared/sizes';

export type RuleProps = {
  height?: 1 | 2;
};

const Inner = styled(Box, {
  background: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    background: colors.strokeNeutralDark,
  },
});

export function Rule({ height = 1 }: RuleProps) {
  return (
    <Box padding={height === 1 ? { top: space(1), bottom: space(2) } : { y: space(1) }}>
      <Inner width="full" height={space(height)} />
    </Box>
  );
}
