import { useParams } from 'react-router-dom';

export default function useCompanyNameFromParams() {
  const { companySID: companySIDInner, companyName } = useParams<{
    companySID: string;
    companyName: string;
  }>();

  return companySIDInner || companyName;
}
