import React from 'react';

import type { breakpoints } from '../../common/breakpoints';
import type { theme } from '../../stitches.config';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Small } from '../../text/Small';

type AddPrefixToObject<T, P extends string> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K];
};

type RelationProps = {
  [key in keyof typeof theme.config.media | 'initial']?: 'stacked' | 'standalone';
};

export type DeprecatedStatProps = {
  /**
   * The label of the stat.
   */
  label: string;
  /**
   * The content of the stat.
   */
  children: React.ReactNode;
  /**
   * The relation of the stat to other stats in a list.
   * @default standalone
   */
  relation?: 'stacked' | 'standalone' | AddPrefixToObject<RelationProps, '@'>;
  /**
   * The color variant of the stat. Only affects the label.
   * @default neutral
   */
  variant?: 'negative' | 'neutral';
};

const StatContainer = styled('div', {
  vStack: '$4',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  paddingY: '$16',
  strokeAll: colors.strokeNeutralLight,
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    strokeAll: colors.strokeNeutralDark,
    color: colors.bodyNeutralDark,
  },
  variants: {
    relation: {
      stacked: {},
      standalone: {
        borderRadius: '$8',
      },
    },
  },
});

const Label = styled('div', Small, {
  minWidth: 'fit-content',

  variants: {
    variant: {
      negative: {
        color: colors.bodyNegativeLight,
        [darkThemeSelector]: {
          color: colors.bodyNegativeDark,
        },
      },
      neutral: {
        color: colors.bodyNeutralLight,
        [darkThemeSelector]: {
          color: colors.bodyNeutralDark,
        },
      },
    },
  },
});

export function DeprecatedStat({
  children,
  label,
  relation = 'standalone',
  variant = 'neutral',
}: DeprecatedStatProps) {
  return (
    <StatContainer relation={relation}>
      {children}
      <Label variant={variant}>{label}</Label>
    </StatContainer>
  );
}

type Breakpoints = keyof typeof breakpoints;

export type DeprecatedStatListProps = {
  /**
   * The direction of the stat list.
   * @default column
   */
  direction?: 'row' | 'column';
  /**
   * An array of stats to display. Accepts an array of `StatProps`.
   * @default []
   */
  stats: Omit<DeprecatedStatProps, 'relation'>[];
  /**
   * Stats are always stacked vertically below this breakpoint.
   * Only necessary if the list is horizontal.
   * @default sm
   */
  stackedBreakpoint?: Breakpoints;
};

const StatListContainer = styled('div', {
  width: '100%',
  variants: {
    direction: {
      row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',

        '> div': {
          width: '100%',
          borderRadius: 0,
        },

        '> div:first-child': {
          borderTopLeftRadius: '$8',
          borderBottomLeftRadius: '$8',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },

        '> div:last-child': {
          borderTopRightRadius: '$8',
          borderBottomRightRadius: '$8',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',

        '> div:first-child': {
          borderTopLeftRadius: '$8',
          borderTopRightRadius: '$8',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },

        '> div:last-child': {
          borderBottomLeftRadius: '$8',
          borderBottomRightRadius: '$8',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },

        '> div:only-child': {
          borderRadius: '$8',
        },
      },
    },
  },
});

export function DeprecatedStatList({
  stats,
  direction = 'column',
  stackedBreakpoint = 'sm',
}: DeprecatedStatListProps) {
  return (
    <StatListContainer
      direction={{
        '@initial': 'column',
        [`@${stackedBreakpoint}`]: direction,
      }}
    >
      {stats.map((stat) => (
        <DeprecatedStat key={stat.label} relation="stacked" {...stat} />
      ))}
    </StatListContainer>
  );
}
