/* eslint-disable eqeqeq */
import React from 'react';

import { Body, Caption, CheckboxInputWithLabel, FormattedMoney } from '@meterup/atto';
import { Quote } from '@meterup/connect-api';

// Props will be a bunch of onchangeevent
interface FilterContractLengthMonthProps {
  quotesByContractLengthMonths: { [provider: string]: Quote[] };
  allContractLengthMonths: number[];
  currentContractLengthMonths: number[];
  onContractLengthMonthsChange: (contractLengthMonth: number, checked: boolean) => void;
}

function FilterContractLengthMonth({
  quotesByContractLengthMonths,
  allContractLengthMonths,
  currentContractLengthMonths,
  onContractLengthMonthsChange,
}: FilterContractLengthMonthProps) {
  return (
    <section>
      <Caption>Contract Length</Caption>
      <div className="mt-4">
        {allContractLengthMonths.map((contractLengthMonth) => (
          <div className="mt-2" key={contractLengthMonth}>
            <CheckboxInputWithLabel
              checked={currentContractLengthMonths.includes(contractLengthMonth)}
              onChange={(checked: boolean) =>
                onContractLengthMonthsChange(contractLengthMonth, checked)
              }
              name={`contractLengthMonth-${contractLengthMonth}`}
            >
              <div className="w-full flex justify-between">
                <Body weight="bold">
                  {contractLengthMonth == 1 ? 'Month-to-month' : `${contractLengthMonth} months`}
                </Body>
                <Body>
                  <FormattedMoney
                    amount={
                      quotesByContractLengthMonths[contractLengthMonth][0].monthlyFeeCents / 100
                    }
                  />
                </Body>
              </div>
            </CheckboxInputWithLabel>
          </div>
        ))}
      </div>
    </section>
  );
}

export default FilterContractLengthMonth;
