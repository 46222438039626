import { createContext, useContext } from 'react';

export type ShellPropSize = 'x-small' | 'medium';

type ShellSize = ShellPropSize;
const ShellSizeContext = createContext<ShellSize | undefined>(undefined);
export const ShellSizeProvider = ShellSizeContext.Provider;
export const useShellSize = (controlledValue?: ShellSize, defaultValue: ShellSize = 'x-small') => {
  const shellSize = useContext(ShellSizeContext);
  return controlledValue ?? shellSize ?? defaultValue;
};

export type ShellPropLayoutMode = 'compact' | 'detailed';

type ShellLayoutMode = ShellPropLayoutMode;
const ShellLayoutModeContext = createContext<ShellLayoutMode | undefined>(undefined);
export const ShellLayoutModeProvider = ShellLayoutModeContext.Provider;
export const useShellLayoutMode = (
  controlledValue?: ShellLayoutMode,
  defaultValue: ShellLayoutMode = 'compact',
) => {
  const shellLayoutMode = useContext(ShellLayoutModeContext);
  return controlledValue ?? shellLayoutMode ?? defaultValue;
};
