import { useEffect, useMemo, useState } from 'react';

import { Quote } from '@meterup/connect-api';
import { groupBy, sortBy } from 'underscore';

// Utils
import { onlyUnique } from '../utils/Array.utils';

// A hooks to keep quotes logic / filtering
export default function useProvidersFilter(quotes: Quote[]) {
  // Sort by price, group by provider
  const quotesByProvider = useMemo(() => {
    const sortedQuotes = sortBy(quotes, (quote) => quote.monthlyFeeCents);
    return groupBy(sortedQuotes, (quote) => (quote && quote.provider ? quote.provider.name : ''));
  }, [quotes]);

  // Contract Length - All
  const allProviders = useMemo(() => Object.keys(quotesByProvider), [quotesByProvider]);

  // Contract Length - Currently selected (default: all)
  const [currentProviders, setCurrentProviders] = useState(allProviders);

  // Contract Length - setstate
  useEffect(() => {
    setCurrentProviders(allProviders);
  }, [allProviders]);

  // Contract Length - User event change
  const onProvidersChange = (providerName: string, checked: boolean) => {
    let newCurrentProviders = currentProviders;
    if (checked) {
      newCurrentProviders.push(providerName);
    } else {
      newCurrentProviders = newCurrentProviders.filter((item) => item !== providerName);
    }
    setCurrentProviders(newCurrentProviders.filter(onlyUnique));
  };

  const resetProvidersFilter = () => {
    setCurrentProviders(allProviders);
  };

  return {
    quotesByProvider,
    allProviders,
    currentProviders,
    onProvidersChange,
    resetProvidersFilter,
  };
}
