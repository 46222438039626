import React from 'react';

import { Textarea, TextareaProps } from '@meterup/atto';
import { FieldRenderProps } from 'react-final-form';

// Mixed interface between React Final Form and our pure TextareaForm
interface TextareaFieldProps extends FieldRenderProps<string>, TextareaProps {}

// TODO: Can we move this to the fornt-end component library?
function TextareaField({
  // React Final Form
  input,
  meta,
  placeholder,
  // Frontend components
  size,
  disabled,
}: TextareaFieldProps) {
  // TODO: The error mark up should not be here
  const displayError = meta.error && meta.touched;
  return (
    <div>
      <Textarea
        name={input.name}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
        disabled={disabled}
        placeholder={placeholder}
        size={size}
        invalid={displayError}
      />
      {displayError && <span className="mt-2 ml-2 text-xs text-red-600">{meta.error}</span>}
    </div>
  );
}

// RRF = React Final Form
export default TextareaField;
