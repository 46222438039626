import { FavIconSVG } from 'atto-svgs';
import React from 'react';

import { styled } from '../../stitches.config';
import { space, SpacingValue } from '../../utilities/shared/sizes';

export const FavIconSizes = SpacingValue;

export type FavIconSize = number;

export const FavIcons = {
  favicon: FavIconSVG.FavIcon,
};

export type FavIconName = keyof typeof FavIcons;
export const FavIconNames = Object.keys(FavIcons) as FavIconName[];

export interface FavIconProps {
  /**
   * Set a size of the component.
   */
  size?: FavIconSize;
}

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
});

export function FavIcon({ size = space(16), ...remaining }: FavIconProps) {
  return <BaseSVG as={FavIcons.favicon} width={size} height={size} {...remaining} />;
}
