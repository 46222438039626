import type { SVGAttributes } from 'react';
import { HardwareIconSVG } from 'atto-svgs';
import React from 'react';

import { styled } from '../../stitches.config';
import { space, SpacingValue } from '../../utilities/shared/sizes';

export const HardwareIconSizes = SpacingValue;

export const HardwareIcons = {
  AccessPointDetailed: HardwareIconSVG.AccessPointDetailed,
  AccessPointSimple: HardwareIconSVG.AccessPointSimple,
  PDUDetailed: HardwareIconSVG.PDUDetailed,
  PDUSimple: HardwareIconSVG.PDUSimple,
  SecurityApplianceDetailed: HardwareIconSVG.SecurityApplianceDetailed,
  SecurityApplianceSimple: HardwareIconSVG.SecurityApplianceSimple,
  SwitchDetailed: HardwareIconSVG.SwitchDetailed,
  SwitchSimple: HardwareIconSVG.SwitchSimple,
};

export type HardwareIconName = keyof typeof HardwareIcons;
export const HardwareIconNames = Object.keys(HardwareIcons) as HardwareIconName[];

export type HardwareIconPropHardware =
  | 'access-point'
  | 'pdu'
  | 'power-distribution-unit'
  | 'security-appliance'
  | 'switch';
export type HardwareIconPropSize = number;
export type HardwareIconPropVariant = 'detailed' | 'simple';

export interface HardwareIconProps {
  /**
   * Set which piece of hardware to display.
   */
  hardware: HardwareIconPropHardware;
  /**
   * Set a size of the component.
   */
  size?: HardwareIconPropSize;
  /**
   * Set which variant, either `detailed` or `simple`.
   */
  variant?: HardwareIconPropVariant;
}

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
});

const getHardwareIconName = (hardware: HardwareIconPropHardware) => {
  switch (hardware) {
    case 'security-appliance':
      return 'SecurityAppliance';
    case 'switch':
      return 'Switch';
    case 'pdu':
    case 'power-distribution-unit':
      return 'PDU';
    default:
      return 'AccessPoint';
  }
};

const getHardwareIconVariant = (variant: HardwareIconPropVariant) => {
  switch (variant) {
    case 'simple':
      return 'Simple';
    default:
      return 'Detailed';
  }
};

export function HardwareIcon({
  hardware = 'access-point',
  size = space(16),
  variant = 'detailed',
  ...remaining
}: HardwareIconProps) {
  const hardwareVariant = getHardwareIconName(hardware) + getHardwareIconVariant(variant);
  const NamedHardwareIcon = HardwareIcons[hardwareVariant as HardwareIconName] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;

  if (!NamedHardwareIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${hardware}`);
    return null;
  }

  return <BaseSVG as={NamedHardwareIcon} width={size} height={size} {...remaining} />;
}
