/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';

import { Button, Icon } from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

import { route } from '../../../routes';

interface RequestSecondaryQuoteProps {
  quoteRequestSid: string;
}

function RequestSecondaryQuote({ quoteRequestSid }: RequestSecondaryQuoteProps) {
  // Router
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(route('results', { sid: quoteRequestSid }));
  }, [quoteRequestSid]);

  return (
    <section
      className="w-full rounded-md border border-gray-100 bg-white overflow-hidden relative p-6 flex cursor-pointer hover:bg-brand-50"
      onClick={onClick}
    >
      <span className="text-gray-600">
        <Icon icon="speed-high" />
      </span>
      <div className="pl-4">
        <p className="text-gray-800">Need a secondary connection?</p>
        <p className="text-sm text-gray-700 mb-2">
          Stay connected even if your primary connection goes down.
        </p>

        <Button variant="secondary">Add a secondary ISP</Button>
      </div>
    </section>
  );
}

export default RequestSecondaryQuote;
