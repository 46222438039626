/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Address, Location, GeoPoint, Point } from './common';
import { ConnectionStatus, Quote, QuoteRequest, ConnectionType } from './connectProtos';
import { AuthMethod } from './wimanProtos';
import { GuestPasswordStrategy, PortMapping } from './mbootProtos';

export const protobufPackage = 'api';

export enum CompanyMembershipRole {
  unknown = 'unknown',
  guest = 'guest',
  member = 'member',
  admin = 'admin',
}

export enum SIMAPN {
  APN_EMPTY = 'APN_EMPTY',
  APN_TWILIO_WIRELESS = 'APN_TWILIO_WIRELESS',
  /**
   * APN_SUPER - This references the Twilio Super SIM
   * https://www.twilio.com/docs/iot/supersim
   */
  APN_SUPER = 'APN_SUPER',
}

export enum SMSMessageEnum {
  SMS_UNKNOWN = 'SMS_UNKNOWN',
  SMS_DOWNLOAD_TUNNEL_ANDROID = 'SMS_DOWNLOAD_TUNNEL_ANDROID',
  SMS_DOWNLOAD_TUNNEL_IOS = 'SMS_DOWNLOAD_TUNNEL_IOS',
}

export enum LifecycleStatus {
  LIFECYCLE_STATUS_UNKNOWN = 'LIFECYCLE_STATUS_UNKNOWN',
  LIFECYCLE_STATUS_INSTALLED_PRIMARY = 'LIFECYCLE_STATUS_INSTALLED_PRIMARY',
  LIFECYCLE_STATUS_INSTALLED_BACKUP = 'LIFECYCLE_STATUS_INSTALLED_BACKUP',
  LIFECYCLE_STATUS_INACTIVE = 'LIFECYCLE_STATUS_INACTIVE',
  LIFECYCLE_STATUS_PROVISIONED = 'LIFECYCLE_STATUS_PROVISIONED',
  LIFECYCLE_STATUS_UNINSTALLED = 'LIFECYCLE_STATUS_UNINSTALLED',
  LIFECYCLE_STATUS_DEMO = 'LIFECYCLE_STATUS_DEMO',
}

export enum ISPProduct {
  IR_UNKNOWN = 'IR_UNKNOWN',
  IR_DEDICATED_FIBER = 'IR_DEDICATED_FIBER',
  IR_SHARED_FIBER = 'IR_SHARED_FIBER',
  IR_POINT_TO_POINT_WIRELESS = 'IR_POINT_TO_POINT_WIRELESS',
  IR_RESIDENTIAL_FIBER = 'IR_RESIDENTIAL_FIBER',
  IR_RESIDENTIAL_CABLE = 'IR_RESIDENTIAL_CABLE',
  IR_BUSINESS_CABLE = 'IR_BUSINESS_CABLE',
}

export enum ISPStatus {
  IS_UNKNOWN = 'IS_UNKNOWN',
  IS_PRIMARY = 'IS_PRIMARY',
  IS_BACKUP = 'IS_BACKUP',
}

export enum AccessPointStatus {
  access_point_status_unknown = 'access_point_status_unknown',
  draft = 'draft',
  offline = 'offline',
  online = 'online',
}

export enum InventoryStatus {
  inventory_status_unknown = 'inventory_status_unknown',
  in_stock = 'in_stock',
  ready_for_install = 'ready_for_install',
}

export enum RadioBand {
  RB_UNKNOWN = 'RB_UNKNOWN',
  RB_2G = 'RB_2G',
  RB_5G = 'RB_5G',
}

/**
 * DeploymentStatus represents the status of a Deployment.
 * https://docs.aws.amazon.com/sdk-for-go/api/service/greengrass/#GetDeploymentStatusOutput
 */
export enum DeploymentStatus {
  DS_UNKNOWN = 'DS_UNKNOWN',
  DS_IN_PROGRESS = 'DS_IN_PROGRESS',
  DS_BUILDING = 'DS_BUILDING',
  DS_SUCCESS = 'DS_SUCCESS',
  DS_FAILURE = 'DS_FAILURE',
}

export enum RecordType {
  RT_UNKNOWN = 'RT_UNKNOWN',
  RT_DNSMASQ_LEASE = 'RT_DNSMASQ_LEASE',
  RT_DHCP_LOG = 'RT_DHCP_LOG',
  RT_CORE_DUMP = 'RT_CORE_DUMP',
}

/** TODO APPENG-362: Deprecate entire enum */
export enum UserRole {
  UR_MEMBER = 'UR_MEMBER',
  UR_GUEST = 'UR_GUEST',
  UR_ADMIN = 'UR_ADMIN',
}

export enum ControllerEventType {
  CE_UNKNOWN = 'CE_UNKNOWN',
  CE_CREATED = 'CE_CREATED',
  CE_ONLINE = 'CE_ONLINE',
  CE_OFFLINE = 'CE_OFFLINE',
  CE_DEGRADED = 'CE_DEGRADED',
}

export enum AccessPointEventType {
  AE_UNKNOWN = 'AE_UNKNOWN',
  AE_CREATED = 'AE_CREATED',
  AE_OFFLINE = 'AE_OFFLINE',
  AE_ONLINE = 'AE_ONLINE',
  AE_DEGRADED = 'AE_DEGRADED',
}

export enum SSHUser {
  SU_UNKNOWN = 'SU_UNKNOWN',
  SU_SUPPORT = 'SU_SUPPORT',
  SU_ADMIN = 'SU_ADMIN',
}

export enum BuildingContactType {
  unknown_building_contact_type = 'unknown_building_contact_type',
  property_manager = 'property_manager',
  riser_manager = 'riser_manager',
  self = 'self',
  other = 'other',
}

export enum OnboardingStatus {
  unknown_onboarding_status = 'unknown_onboarding_status',
  information_pending = 'information_pending',
  information_complete = 'information_complete',
  network_ready = 'network_ready',
}

export enum ContentFilterAction {
  CONTENTFILTER_ACTION_UNKNOWN = 'CONTENTFILTER_ACTION_UNKNOWN',
  CONTENTFILTER_ACTION_ALLOW = 'CONTENTFILTER_ACTION_ALLOW',
  CONTENTFILTER_ACTION_BLOCK = 'CONTENTFILTER_ACTION_BLOCK',
}

/**
 * All 400 or 500 error messages will have this format. "title" should always be
 * set; "id" is usually set; other fields may be set, but not always.
 */
export interface Error {
  title: string;
  id: string;
  detail: string;
  instance: string;
  type: string;
  status: number;
}

/**
 * Returned from any "DELETE" request or some POST requests where we were lazy
 * and didn't return a body.
 */
export interface Empty {}

/** The compan(ies) that the user belongs to. */
export interface CompanyMembership {
  company_slug: string;
  /** TODO APPENG-362: Deprecate this */
  role: CompanyMembership_Role;
  sid: string;
  user_role: CompanyMembershipRole;
}

/** TODO APPENG-362: Deprecate this */
export enum CompanyMembership_Role {
  unknown = 'unknown',
  guest = 'guest',
  member = 'member',
  admin = 'admin',
}

export interface IdentityResponse {
  /** for human users, this is an email address */
  username: string;
  permissions: string[];
  expires_at?: string;
  created_at?: string;
  company_memberships: CompanyMembership[];
  id: number;
  first_name?: string;
  last_name?: string;
}

export interface SIM {
  sim_iccid: string;
  modem_iccid: string;
  sim_apn: SIMAPN;
}

export interface SMSMessage {
  /** Receiving phone number, who should receive this message (format: https://www.twilio.com/docs/glossary/what-e164) */
  receiver: string;
  /** Corresponding ISO country code for phone number */
  country_code: string;
  /** Specific SMS body enum, mapping to templated message on the backend */
  body: SMSMessageEnum;
}

export interface ControllerCreateRequest {
  /** Name is eight bytes of a UUID. */
  name: string;
  dry_run: boolean;
  /**
   * This is how we generate the wifi SSID and each *company* / customer must be
   * unique. Multiple controllers may share a company slug.
   */
  company_slug: string;
  address: string;
  permitted_domains: string[];
  company_admins: string[];
  square_feet: number;
  /**
   * We *could* pull this from the user agent, but it's easier to be explicit.
   * If mboot version is empty (""), then we assume we are provisioning a COS controller.
   * Once we fully rollout COS and remove legacy controllers from production, this field
   * will be deprecated and removed.
   */
  mboot_version: string;
  network_interfaces: NetworkInterfaceCreateRequest[];
  /**
   * Friendly name for the company, if the slug is "tishman-speyer" this would
   * be "Tishman Speyer". If unspecified we try to generate it from the company
   * slug.
   */
  company_name: string;
  sim?: SIM;
  vpn_server?: VPNServer;
  /**
   * Controller's IPv6 address on the jumphost subnet. Used to send requests to
   * this controller.
   */
  tunnel_addr: string;
  /**
   * 32 byte Curve25519 public key used to add this controller to the
   * jumphost's WireGuard interface.
   */
  tunnel_public_key: Uint8Array;
  structured_address?: Address;
}

export interface DeploymentCreateRequest {
  /**
   * Async will wait for any in-progress deployments to complete before
   * attempting to create a new deployment.
   */
  async: boolean;
}

/**
 * GET /v1/controllers/:name, POST /v1/controllers
 * TODO: Consolidate usage with ControllerDeviceResponse
 */
export interface ControllerResponse {
  sid: string;
  created_at?: string;
  name: string;
  hostname: string;
  address: string;
  company_slug: string;
  square_feet: number;
  ngrok_addr: string;
  mboot_version: string;
  updated_at?: string;
  status: string;
  /** Links to related resources */
  links: { [key: string]: string };
  sim?: SIM;
  tunnel_addr: string;
  tunnel_public_key: Uint8Array;
  /** free-form JSON intended for storing information strictly for user display purposes in NOC */
  noc_metadata?: { [key: string]: any };
  location?: Location;
  lifecycle_status: LifecycleStatus;
}

export interface ControllerResponse_LinksEntry {
  key: string;
  value: string;
}

export interface NetworkInterfaceCreateRequest {
  /** Controller is passed as part of the URL. */
  interface: string;
  description: string;
}

export interface NetworkInterfaceUpdateRequest {
  description: string;
  /**
   * Set to true to set ISP field to null, it's an error to set both this and
   * the field below it.
   */
  set_isp_empty: boolean;
  /**
   * SID of the ISP. An error to set this to a non-empty value and also set
   * set_isp_empty to true.
   */
  internet_service_plan: string;
}

export interface NetworkInterfaceResponse {
  sid: string;
  created_at?: string;
  updated_at?: string;
  controller: string;
  interface: string;
  description: string;
  /** May be empty if there is no ISP plugged in */
  internet_service_plan: string;
}

export interface NetworkInterfaceListResponse {
  interfaces: NetworkInterfaceResponse[];
}

export interface BandwidthListResponse {
  controller: string;
  streams: BandwidthDataStream[];
}

export interface BandwidthDataStream {
  interface: string;
  down: boolean;
  period: number;
  starting_at?: string;
  value: number[];
}

/** POST /v1/controllers/:name/internet-service-plans */
export interface ISPCreateRequest {
  provider: string;
  product: ISPProduct;
  status: ISPStatus;
  account_number: string;
  notes: string;
  download_kbps: number;
  upload_kbps: number;
  monthly_cost_cents: number;
  /**
   * IP address and mask; IP address does not need to be the lowest in the
   * range. Empty for Dynamic IP
   */
  static_ip_range: string;
  gateway_addr: string;
  /**
   * The controller IP should not be the same as the gateway addr and should not
   * be within the IP range from first to last usable IP.
   */
  controller_ip: string;
  first_usable_ip: string;
  last_usable_ip: string;
  network_interface?: string;
}

/** POST /v1/controllers/:name/internet-service-plans/:sid (POST for legacy tech debt) */
export interface ISPUpdateRequest {
  provider: string;
  product: ISPProduct;
  status: ISPStatus;
  account_number: string;
  notes: string;
  download_kbps: number;
  upload_kbps: number;
  monthly_cost_cents: number;
  /**
   * If true, all of the static IP fields will be cleared. An error to set this
   * to true and set any of fields 10-14 to a non-empty value.
   */
  set_dynamic_ip: boolean;
  static_ip_range: string;
  gateway_addr: string;
  controller_ip: string;
  first_usable_ip: string;
  last_usable_ip: string;
  network_interface?: string;
}

export interface Company {
  sid: string;
  created_at?: string;
  default_slug: string;
  name: string;
  billing_enabled: boolean;
}

export interface CompanyLocation {
  sid: string;
  company?: Company;
  created_at?: string;
  name?: string;
  location?: Location;
}

/** GET /v1/companies/:slug/locations */
export interface CompanyLocationsResponse {
  company_locations: CompanyLocationResponse[];
}

/** PUT /v1/companies/:company-slug/locations/:company-location-sid */
export interface CompanyLocationResponse {
  sid: string;
  company?: Company;
  name: string;
  location?: Location;
  billing_enabled: boolean;
}

export interface ConnectionStatusChange {
  internet_service_plan?: InternetServicePlan;
  created_at?: string;
  connection_status?: ConnectionStatus;
  notes: string;
  related_ts?: string;
  sid: string;
}

/** GET /v1/controllers/:name/internet-service-plans */
export interface InternetServicePlan {
  sid: string;
  created_at?: string;
  updated_at?: string;
  controller: string;
  provider: string;
  product: ISPProduct;
  status: ISPStatus;
  account_number: string;
  notes: string;
  download_kbps: number;
  upload_kbps: number;
  monthly_cost_cents: number;
  /**
   * IP address and mask; IP address does not need to be the lowest in the
   * range. Empty for Dynamic IP
   */
  static_ip_range: string;
  gateway_addr: string;
  controller_ip: string;
  first_usable_ip: string;
  last_usable_ip: string;
  has_static_ip: boolean;
  provider_name: string;
  network_interface?: string;
  quote?: Quote;
  company_location?: CompanyLocation;
  additional_products: { [key: string]: string };
  billing_provider_name: string;
  primary_contact_email: string;
  renewal_date?: string;
  contact_phone_number: string;
  contract_url: string;
  quote_request?: QuoteRequest;
}

export interface InternetServicePlan_AdditionalProductsEntry {
  key: string;
  value: string;
}

export interface CompanyContractResponse {
  latest_status?: ConnectionStatusChange;
  internet_service_plan?: InternetServicePlan;
  provider?: Provider;
  connection_type?: ConnectionType;
}

export interface CompanyContractsResponse {
  company_location?: CompanyLocationResponse;
  company_contracts: CompanyContractResponse[];
}

/** GET /v1/connect/contracts */
export interface AllContractsResponse {
  contracts: CompanyContractsResponse[];
  company?: CompanyResponse;
}

/** POST /v1/connect/admin/companies/:company-slug/internet-service-plans/:internet-service-plan-sid/statuses */
export interface CompanyISPStatusChangeRequest {
  status?: ConnectionStatus;
}

export interface CompanyISPStatusChangeResponse {
  status_change?: ConnectionStatusChange;
}

export interface ISPListResponse {
  plans: InternetServicePlan[];
}

/** Payloads marshalled in a ControllerResponse */
export interface ControllerGrepPayload {
  result: string;
}

export interface ControllerGrepRequest {
  query: string;
}

export interface ClientLogsRequest {
  macaddr: string;
}

export interface PasswordParams {
  /**
   * this is a types.NetworkType, we don't use the proto enum because other
   * stuff is already hardcoded to "private" / "guest"
   */
  network: string;
  password: string;
  restart: number;
}

/**
 * POST /v1/controllers/:name
 *
 * Updates a controller. To update a field, set it to a nonzero value, otherwise
 * the update will not be applied.
 *
 * (This only works for fields that have mandatory nonzero values, an approach
 * that might not scale. But it works for now, so let's roll with it).
 */
export interface ControllerUpdateRequest {
  square_feet: number;
  address: string;
  mboot_version: string;
  status: string;
  tunnel_addr: string;
  tunnel_public_key: Uint8Array;
  company_sid: string;
  /** free-form JSON intended for storing information strictly for user display purposes in NOC */
  noc_metadata?: { [key: string]: any };
  structured_address?: Address;
  lifecycle_status: LifecycleStatus;
}

/** GET /v1/companies/:slug/controllers */
export interface CompanyControllersResponse {
  company_slug: string;
  controllers: ControllerResponse[];
}

/** GET /v1/controllers */
export interface ControllersResponse {
  controllers: ControllerResponse[];
}

export interface CompanyResponse {
  slug: string;
  sid: string;
  created_at?: string;
  name: string;
  billing_enabled: boolean;
}

/** POST /v1/companies */
export interface CompanyCreateRequest {
  slug: string;
  name: string;
}

export interface CompanyUpdateRequest {
  name: string;
}

export interface CompanyUpdateBillingRequest {
  billing_enabled: boolean;
}

export interface CompaniesListResponse {
  companies: CompanyResponse[];
}

/**
 * DEPRECATED: POST /v1/controllers/:name/access-points requires a CSV upload.
 *
 * @deprecated
 */
export interface AccessPointCreateRequest {}

/** PUT /v1/controllers/:name/access-points/:name */
export interface AccessPointPutRequest {
  /** Controller name and AP name are provided in the URL. */
  dry_run: boolean;
  /** Version as "1.4.8". Error to pass both this and major, minor, patch */
  version: string;
  version_major: number;
  version_minor: number;
  version_patch: number;
  location: string;
  /**
   * skip_greengrass_registration tells the backend not to try to register the AP in Greengrass
   * Sometimes we want to make simple updates like the `location` without triggering the heavyweight controller-Greengrass-deployment job.
   */
  skip_greengrass_registration: boolean;
  inventory_status: InventoryStatus;
  status: AccessPointStatus;
  /**
   * model_name is not actually editable on the backend, but it helps us uniquely identify an AP
   * because in our inventory tracker, multiple APs of different model names can have the same name.
   */
  model_name: string;
  /** serial_number is the unique identifier of any piece of hardware */
  serial_number: string;
}

/**
 * DEPRECATED: POST /v1/controllers/:name/access-points/:name
 *
 * @deprecated
 */
export interface AccessPointUpdateRequest {}

/** TODO: Consolidate usage with AccessPointDeviceResponse */
export interface AccessPointResponse {
  /** DEPRECATED: This is actually the name. Use the name property going forward. */
  id: string;
  /** NB, not all fields may be set. */
  thing_arn: string;
  certificate_arn: string;
  sync_shadow: boolean;
  sid: string;
  version: string;
  location: string;
  created_at?: string;
  updated_at?: string;
  controller: string;
  name: string;
  /**
   * If connected_clients is set to -1, the value was not retrieved as part of
   * the request.
   */
  connected_clients: number;
  status: string;
  inventory_status: InventoryStatus;
}

export interface AccessPointRadioCreateRequest {
  index: number;
  channel: number;
  channel_width: number;
  power: number;
  disabled: boolean;
}

export interface AccessPointRadioUpdateRequest {
  channel: number;
  channel_width: number;
  power: number;
  disabled: boolean;
  /**
   * True if the disabled field's value represents a genuine change from its
   * previous state (vs. unset)
   */
  disabled_changed: boolean;
}

/** GET /v1/companies/:slug/devices */
export interface NetworkDevicesResponse {
  controllers: ControllerDeviceResponse[];
}

/** GET /v1/controllers/:name/devices */
export interface ControllerDeviceResponse {
  name: string;
  serial_number: string;
  mac_address: string;
  ip_address: string;
  connected_status: string;
  lifecycle_status: string;
  switches: SwitchResponse[];
  access_points: AccessPointDeviceResponse[];
  connected_clients?: number;
  /** free-form JSON intended for storing information strictly for user display purposes, typically in NOC */
  internal_display_metadata?: { [key: string]: any };
}

export interface AccessPointDeviceResponse {
  name: string;
  serial_number: string;
  mac_address: string;
  ip_address: string;
  connected_status: string;
  location: string;
  radios: AccessPointRadioConfig[];
  connected_clients?: number;
}

export interface AccessPointRadioConfig {
  band: RadioBand;
  channel: number;
  channel_width_MHz: number;
  tx_power_dBm: number;
  enabled: boolean;
}

/** TODO: Consolidate usage with AccessPointRadioConfig */
export interface AccessPointRadioResponse {
  controller: string;
  name: string;
  index: number;
  band: RadioBand;
  sid: string;
  channel: number;
  channel_width: number;
  power: number;
  disabled: boolean;
  created_at?: string;
  updated_at?: string;
}

/** TODO: Consolidate usage with AccessPointDeviceResponse */
export interface AccessPointRadiosResponse {
  access_point?: AccessPointResponse;
  radios: AccessPointRadioResponse[];
  /** IP address of the access point, may not be set in all cases. */
  ip_address: string;
}

export interface SwitchResponse {
  serial_number: string;
  location: string;
  controller: string;
  model: string;
  inventory_status: string;
  mac: string;
}

/**
 * GET /v1/access-points
 * GET /v1/controllers/:name/access-points
 */
export interface AccessPointsResponse {
  access_points: AccessPointResponse[];
}

/** DELETE /v1/controllers/:name request body */
export interface ControllerDeleteRequest {
  name: string;
  dry_run: boolean;
}

export interface HomepageResponse {
  status: number;
  type: string;
  server_version: string;
  versions: Version[];
}

export interface Version {
  name: string;
  path: string;
}

/**
 * AccessPointsDownRequest is made to notify the API that access points are
 * down.
 *
 * DEPRECATED: Use AccessPointsStatusRequest instead.
 */
export interface AccessPointsDownRequest {
  access_point_names: string[];
  num_expected_access_points: number;
}

/**
 * AccessPointsStatusRequest is made to notify the API about access point
 * statuses.
 *
 * A client may make this request at any time to indicate its status; it may not
 * indicate that a change has occurred.
 */
export interface AccessPointsStatusRequest {
  /** ["meter-ap-12345", "meter-ap-4567"] */
  observed_at?: string;
  down_access_points: string[];
  up_access_points: string[];
}

/** GET /v1/controllers/:name/deployments/:id response */
export interface DeploymentResponse {
  id: string;
  path: string;
  status: DeploymentStatus;
  type: string;
  error_message: string;
  error_details: string;
  updated_at?: string;
}

export interface AnalyticsTopVendorsCreateRequest {
  analytics_id: string;
  created_at?: string;
}

export interface AnalyticsTopVendorsResponse {
  upload_url: string;
}

/**
 * RecordsCreateRequest is a request to upload a record to the server. The
 * controller name should be passed as part of the URL.
 */
export interface RecordsCreateRequest {
  type: RecordType;
  created_at?: string;
}

export interface RemoteFile {
  sid: string;
  type: RecordType;
  filename: string;
  created_at?: string;
  controller: string;
}

export interface RemoteFilesResponse {
  files: RemoteFile[];
}

/**
 * RecordsCreateResponse contains a URL that the client can upload the record
 * to.
 */
export interface RecordsCreateResponse {
  upload_url: string;
}

export interface ControllerNetworkAccessResponse {
  resp: ControllerNetworkAccessInstanceResponse[];
  company_slug: string;
}

export interface ControllerNetworkAccessInstanceResponse {
  permitted: boolean;
  email: string;
  is_admin: boolean;
}

/**
 * EncryptedFile contains the encrypted contents of a file. The reader can use
 * the attached public key to decrypt the message.
 */
export interface EncryptedFile {
  contents: Uint8Array;
  path: string;
  public_key: string;
  controller_id: string;
}

export interface CertificatesResponse {
  full_chain?: EncryptedFile;
  private_key?: EncryptedFile;
}

export interface UserResponse {
  sid: string;
  created_at?: string;
  email: string;
  company_slug: string;
  /** TODO APPENG-362: Deprecate this */
  role: UserRole;
  active_user_token_limit?: number;
  first_name?: string;
  last_name?: string;
  profile_picture_url?: string;
  company_role: CompanyMembershipRole;
  stytch_user_id?: string;
}

export interface UsersResponse {
  company_slug: string;
  users: UserResponse[];
}

/** POST /v1/companies/:slug/users */
export interface UserCreateRequest {
  email: string;
  /** TODO APPENG-362: Deprecate this */
  role: UserRole;
  first_name?: string;
  last_name?: string;
  profile_picture_url?: string;
  company_role: CompanyMembershipRole;
  stytch_user_id?: string;
}

/** PUT /v1/companies/:slug/users/:sid */
export interface UserUpdateRequest {
  /** TODO APPENG-362: Deprecate this */
  role: UserRole;
  /**
   * The maximum number of company_user_tokens this company_user can have.
   * If set to null, implies unlimited.
   */
  active_user_token_limit?: number;
  active_user_token_limit_changed: boolean;
  first_name?: string;
  last_name?: string;
  profile_picture_url?: string;
  company_role: CompanyMembershipRole;
}

/** DELETE /v1/companies/:slug/users/:sid */
export interface UserDeleteRequest {
  sid: string;
}

/** POST /v1/companies/:slug/users/invite */
export interface InviteUsersRequest {
  users: UserCreateRequest[];
}

export interface InviteUserResponse {
  email: string;
  is_error: boolean;
  is_success: boolean;
}

export interface InviteUsersResponse {
  results: InviteUserResponse[];
}

/**
 * Struct holding data that gets sent to the API to complete a request/response
 * loop.
 */
export interface MQTTControllerResponse {
  version: number;
  status: string;
  error_id: string;
  error_text: string;
  data_id: string;
  /**
   * This might be better as a "bytes", but protobuf serializes that to base64.
   * since this is JSON data, all of the characters are printable, plus using
   * a string representation makes this more readable.
   */
  data: string;
}

export interface ControllerMessageUpdateRequest {
  controller_name: string;
  message_sid: string;
  payload?: MQTTControllerResponse;
}

/**
 * ControllerMessageResponse is returned from any API endpoint that writes
 * a message to controller_messages and then sends an MQTT message to the
 * controller.
 */
export interface ControllerMessageResponse {
  controller_name: string;
  created_at?: string;
  message_sid: string;
  request: string;
  response?: MQTTControllerResponse;
  finished_at?: string;
  /** Links to related resources */
  links: { [key: string]: string };
}

export interface ControllerMessageResponse_LinksEntry {
  key: string;
  value: string;
}

/**
 * GET /v1/noc/:name/events
 * Maps to a controller_event in the database.
 * Not the same thing as `ControllerEventListResponse`; that maps to a controller_status_event in the database.
 */
export interface AdhocControllerEventListResponse {
  events: AdhocControllerEvent[];
}

export interface AdhocControllerEvent {
  controller: string;
  event_type: string;
  created_at?: string;
  message: string;
  subtype?: string;
}

export interface ControllerEvent {
  sid: string;
  controller: string;
  created_at?: string;
  updated_at?: string;
  event_type: ControllerEventType;
  event_group: string;
}

/**
 * GET /v1/controllers/:name/events
 * Maps to a controller_status_event in the database.
 */
export interface ControllerEventListResponse {
  controller: string;
  events: ControllerEvent[];
}

export interface AccessPointEvent {
  sid: string;
  controller: string;
  /** Access point name */
  name: string;
  event_type: AccessPointEventType;
  event_group: string;
  created_at?: string;
  updated_at?: string;
}

export interface AccessPointEventListResponse {
  events: AccessPointEvent[];
}

/**
 * Protobuf to embed with objects in the Ngrok API. Better to do this than put
 * an arbitrary list of crap in there and then have to figure out later what we
 * put in.
 */
export interface NgrokMetadata {
  version: number;
  controller_name: string;
}

export interface Timestamp {
  time?: string;
}

export interface NetworkInfoUpdateRequest {
  private_ssid: string;
  private_2g_ssid: string;
  private_auth_method: AuthMethod;
  /** only applicable when private_auth_method is psk2 */
  private_password: string;
  guest_ssid: string;
  guest_password: string;
  guest_strategy: GuestPasswordStrategy;
}

/**
 * DHCPLease represents a single line of a list of dnsmasq leases. The fields
 * are here in the same order they appear in the lease file.
 */
export interface DHCPLease {
  expiry?: string;
  mac_address: string;
  ip_address: string;
  /** "Computer name, if known. This is always unqualified (no domain part)" */
  name: string;
  /**
   * Client-ID, if known. The client-ID is used as the computer's unique-ID
   * in preference to the MAC address, if it's available. Some DHCP clients
   * provide it, and some don't. The ones that do normally derive it from the
   * MAC address unless explicitly configured, but it could be something like a
   * serial number, which would protect a computer from losing its identity if
   * the network interface were replaced.
   */
  client_id: string;
}

export interface NetworkClientsResponse {
  last_modified?: string;
  leases: DHCPLease[];
}

export interface RadiusUser {
  username: string;
  devices_remaining: number;
  enabled: boolean;
  available_at?: string;
  macs: string[];
  /** may be empty if omitted / not returned */
  password: string;
}

export interface RadiusUsersCreateRequest {
  username: string;
  password: string;
  devices_remaining: number;
  enabled: boolean;
}

export interface RadiusUsersCreateResponse {
  user: string;
}

export interface RadiusUsersEnableRequest {
  username: string;
}

export interface RadiusUsersEnableResponse {
  user?: RadiusUser;
}

export interface RadiusUsersDisableRequest {
  username: string;
}

export interface RadiusUsersDisableResponse {
  user?: RadiusUser;
}

export interface RadiusUsersUpdateRequest {
  /** maybe password later, if we need it, must be careful though */
  devices_remaining: number;
}

export interface RadiusUsersUpdateResponse {
  user?: RadiusUser;
}

export interface RadiusUsersListRequest {
  usernames: string[];
  show_password: boolean;
}

export interface RadiusUsersListResponse {
  users: RadiusUser[];
}

export interface RadiusDevice {
  mac: string;
  username: string;
  enabled: boolean;
}

export interface RadiusDevicesListRequest {
  usernames: string[];
  macs: string[];
}

export interface RadiusDevicesListResponse {
  devices: RadiusDevice[];
}

export interface RadiusDevicesBindRequest {
  mac: string;
  username: string;
}

export interface RadiusDevicesBindResponse {
  device?: RadiusDevice;
}

export interface RadiusDevicesUnbindRequest {
  macs: string[];
}

export interface RadiusDevicesUnbindResponse {
  unbound: number;
}

export interface VpnPeer {
  public_key: string;
  preshared_key: string;
  endpoint: string;
  persistent_keepalive_interval: number;
  last_handshake_time?: string;
  rx_bytes: number;
  tx_bytes: number;
  allowedips: string[];
  protocol_version: number;
}

export interface VpnInterface {
  tunnel_addrs: string[];
  public_key: string;
  /** actually only 16-bits */
  listen_port: number;
  fwmark: number;
  peers: VpnPeer[];
  link_local: string;
}

export interface VpnInterfaceListRequest {
  show_peers: boolean;
}

export interface VpnInterfaceListResponse {
  interface?: VpnInterface;
}

export interface VpnInterfaceUpdateRequest {
  listen_port: number;
  tunnel_addrs: string[];
}

export interface VpnInterfaceUpdateResponse {
  interface?: VpnInterface;
}

export interface VpnInterfaceRekeyRequest {}

export interface VpnInterfaceRekeyResponse {
  interface?: VpnInterface;
}

export interface VpnPeersListRequest {}

export interface VpnPeersListResponse {
  peers: VpnPeer[];
}

export interface VpnPeersCreateRequest {
  public_key: string;
  preshared_key: string;
  endpoint: string;
  persistent_keepalive_interval: number;
  allowedips: string[];
}

export interface VpnPeersCreateResponse {
  peer?: VpnPeer;
}

export interface VpnPeersUpdateRequest {
  public_key: string;
  preshared_key: string;
  endpoint: string;
  persistent_keepalive_interval: number;
  allowedips: string[];
}

export interface VpnPeersUpdateResponse {
  peer?: VpnPeer;
}

export interface VpnPeersDestroyRequest {
  public_keys: string[];
}

export interface VpnPeersDestroyResponse {}

export interface VPNClient {
  sid: string;
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
  /** company user sid of the end user of this VPN client */
  user_sid: string;
  name: string;
  ip_address: string;
  created_at?: string;
  /** email of the user that created this VPN client (may be different than the intended end user) */
  created_by: string;
}

/**
 * VPNServer stores data needed to create a WireGuard config file used
 * to connect to the VPN.
 */
export interface VPNServer {
  /** endpoint of the server, e.g. meter.meterddns.com */
  endpoint: string;
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
  /** listen port of the VPNServer */
  port?: number;
}

export interface VPNClientsListRequest {}

export interface VPNClientsListResponse {
  clients: VPNClient[];
}

export interface VPNClientCreateRequest {
  /** a Curve25519 public key - the end user should generate via wireguard and provide */
  public_key: Uint8Array;
  /** end user device name, e.g. paulmbp */
  name: string;
  /** end user's company email, associated with the VPN server's controller, e.g. angie@meter.com */
  email: string;
}

/**
 * VPNClientImportRequest specifies individual VPN client data to be imported from a legacy controller.
 * To be deprecated after the legacy to COS migration is complete (track via BE-1535)
 */
export interface VPNClientImportRequest {
  /** a Curve25519 public key - the end user should generate via wireguard and provide */
  public_key: Uint8Array;
  /** end user device name, e.g. paulmbp */
  name: string;
  /** end user's company email, associated with the VPN server's controller, e.g. angie@meter.com */
  email: string;
  /** client IP address to be imported, assigned during legacy VPN setup */
  ip_address: string;
}

/**
 * VPNClientsImportRequest handles a mass import of existing VPN client data from a legacy controller VPN to its
 * corresponding COS controller VPN during an upgrade event.
 * To be deprecated after the legacy to COS migration is complete (track via BE-1535)
 */
export interface VPNClientsImportRequest {
  clients: VPNClientImportRequest[];
}

export interface VPNClientResponse {
  client?: VPNClient;
  server?: VPNServer;
}

export interface VPNClientsDeleteRequest {
  requests: VPNClientDeleteRequest[];
}

export interface VPNClientDeleteRequest {
  sid: string;
}

export interface VPNServerUpdateRequest {
  server?: VPNServer;
}

export interface VPNServerUpsertRequest {
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
}

export interface InternalVPNClient {
  sid: string;
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
  user_sid: string;
  name: string;
  ip_address: string;
  created_by: string;
  created_at?: string;
}

/**
 * VPNServer stores data needed to create a WireGuard config file used
 * to connect to the VPN.
 */
export interface InternalVPNServer {
  /** unique ID for this VPN Server, only used on read requests */
  sid: string;
  /** friendly name for this VPN server */
  name: string;
  /** The CIDR of the WireGuard network, least significant bit should be a 1. */
  network: string;
  /** endpoint of the server, a FQDN or IP address plus a port */
  endpoint: string;
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
  /** listen port of the VPNServer */
  port: number;
}

export interface InternalVPNClientsListResponse {
  clients: InternalVPNClient[];
}

export interface InternalVPNClientCreateRequest {
  /** a Curve25519 public key - the end user should generate via wireguard and provide */
  public_key: Uint8Array;
  /** user's device name, e.g. paulmbp */
  name: string;
  /**
   * when creating, if null a unique IP address will be generated if the associated
   * VPN server is IPv4. If the VPN server is IPv6, an address must be provided.
   */
  ip_address?: string;
}

export interface InternalVPNClientCreateResponse {
  client?: InternalVPNClient;
  server?: InternalVPNServer;
}

export interface InternalVPNServerCreateRequest {
  server?: InternalVPNServer;
}

export interface InternalVPNServerListResponse {
  servers: InternalVPNServer[];
}

export interface JobParams {
  attempt: number;
  data: Uint8Array;
}

export interface QueuedJob {
  id: string;
  name: string;
  attempts: number;
  run_after?: string;
  expires_at?: string;
  created_at?: string;
  updated_at?: string;
  status: string;
  data?: { [key: string]: any };
}

/**
 * QueuedJobData stores the data that we need to send back to the scheduler to
 * record the status of a job at a later point.
 */
export interface QueuedJobData {
  id: string;
  name: string;
  attempts: number;
}

export interface ArchivedJob {
  id: string;
  name: string;
  attempts: number;
  created_at?: string;
  expires_at?: string;
  status: string;
  data?: { [key: string]: any };
}

/**
 * A Job can be retrieved from either the queued_jobs or archived_jobs table
 * depending on its status.
 */
export interface Job {
  id: string;
  name: string;
  attempts: number;
  created_at?: string;
  expires_at?: string;
  status: string;
  data?: { [key: string]: any };
}

/**
 * APDevice is a device connected to an AP.
 * Deprecated; use ControllerClient instead.
 */
export interface APDevice {
  apname: string;
  signal: number;
  last_seen?: string;
  lease?: Lease;
  tx_rate: number;
  rx_rate: number;
  noise: number;
}

/**
 * Client is anything connected to the controller. This is used in both the
 * request and response.
 */
export interface ControllerClient {
  /** May be empty. */
  apname: string;
  signal: number;
  last_seen?: string;
  lease?: Lease;
  tx_rate: number;
  rx_rate: number;
  noise: number;
  channel: number;
  connected: boolean;
  /** To-be-deprecated in favor of `ssids` */
  ssid: string;
  /** Not set when used in the request; set on the response. */
  sid: string;
  /** freeform strings to characterize the client like 'guest', 'wired', 'wireless' */
  tags: string[];
  /** The Meter Auth token used to connect the client to a network */
  user_token_sid?: string;
  /** The Meter Auth company user who connected this client */
  user_sid?: string;
  /** A freeform text string describing where the AP is situated in a space */
  access_point_location: string;
  ssids: string[];
  /** A user defined alias used to identify a client */
  alias: string;
  vlan: string;
}

/**
 * GET /v1/controllers/:name/stats
 * GET /v1/noc/controller-stats
 */
export interface ControllerStat {
  controller: string;
  download_bytes: number;
  upload_bytes: number;
  created_at?: string;
  bandwidth_updated_at?: string;
  last_rebooted_at?: string;
  connected_guests: number;
  /**
   * Connected Meter devices (AP's, switches.) As of June 2020 this may not
   * include switches in the count.
   */
  connected_devices: number;
  /** Connected clients (does not include Meter devices with DHCP leases). */
  connected_clients: number;
  latest_controller_event_at?: string;
  latest_controller_event_type?: string;
  latest_controller_event_subtype?: string;
}

/** Lease is a dnsmasq Lease entry. */
export interface Lease {
  expiry?: string;
  mac: string;
  ip: string;
  name: string;
  client_id: string;
}

/** Deprecated, use ConnectedClientsRequest. */
export interface ConnectedDevicesRequest {
  created_at?: string;
  devices: APDevice[];
}

export interface ConnectedClientsRequest {
  created_at?: string;
  clients: ControllerClient[];
}

/** Deprecated, use ConnectedClientsResponse. */
export interface ConnectedDevicesResponse {
  controller: string;
  devices: APDevice[];
}

export interface ConnectedClientsResponse {
  controller: string;
  clients: ControllerClient[];
}

export interface PortMappingResponse {
  mapping?: PortMapping;
  created_at?: string;
  updated_at?: string;
}

/**
 * Create credentials to access the API.
 * POST /v1/sessions
 */
export interface SessionCreateRequest {
  /** Often, but not always, an email address provided by an SSO provider */
  username: string;
  permission: string[];
  /** as of November 2020 this is advisory and ignored by the server */
  expires_at?: string;
  /** This is the name of the API key to be generated. If a name is not provided, one will be generated server-side */
  name: string;
  /** A person's first name */
  user_first_name: string;
  /** A person's last name */
  user_last_name: string;
  /** A link to a person's profile picture as provided by SSO provider */
  user_profile_picture_url: string;
}

export interface SessionCreateResponse {
  username: string;
  permission: string[];
  /** as of November 2020 this is advisory */
  expires_at?: string;
  /** 64 bytes sign.PrivateKey */
  key: Uint8Array;
  name: string;
}

export interface ControllerUpdateSoftwareRequest {
  name: string;
  /**
   * We generate the message sid before sending to the job queue, so that
   * a caller can track the request all the way through the job queue and the
   * controller message database.
   */
  message_sid: string;
}

export interface ControllerDownReminderRequest {
  name: string;
  event_group: string;
  duration_seconds: number;
  company_slug: string;
}

/** NOC API Protobufs */
export interface NOCControllerStatsResponse {
  /** map of controller name => stat resources */
  stats: { [key: string]: ControllerStat };
}

export interface NOCControllerStatsResponse_StatsEntry {
  key: string;
  value?: ControllerStat;
}

export interface NOCControllerDevicesResponse {
  /** Map of access point names => AP and radio info */
  access_points: { [key: string]: AccessPointRadiosResponse };
  switches: SwitchResponse[];
}

export interface NOCControllerDevicesResponse_AccessPointsEntry {
  key: string;
  value?: AccessPointRadiosResponse;
}

/** GET /v1/noc/companies/:slug/permitted-domains */
export interface PermittedEmailDomainsResponse {
  company_slug: string;
  domains: PermittedEmailDomainResponse[];
}

export interface PermittedEmailDomainResponse {
  sid: string;
  created_at?: string;
  domain: string;
}

/** POST /v1/noc/companies/:slug/permitted-domains */
export interface PermittedEmailDomainCreateRequest {
  domain: string;
}

export interface PermittedEmailDomainCreateResponse {
  company_slug: string;
  domain?: PermittedEmailDomainResponse;
}

/**
 * POST /v1/dashboard/companies/:slug/user-tokens
 * POST /v1/noc/companies/:slug/user-tokens
 */
export interface UserTokenCreateRequest {
  user_sid: string;
  /** human-readable name */
  alias: string;
  /** The maximum number of clients that can be authenticated with this token */
  max_clients_limit?: number;
  available_at?: string;
  expires_at?: string;
}

/**
 * PUT /v1/dashboard/companies/:slug/user-tokens/:sid
 * PUT /v1/noc/companies/:slug/user-tokens/:sid
 */
export interface UserTokenUpdateRequest {
  alias: string;
  /** The maximum number of clients that can be authenticated with this token */
  max_clients_limit?: number;
  available_at?: string;
  expires_at?: string;
}

/**
 * POST /v1/dashboard/companies/:slug/user-tokens
 * POST /v1/noc/companies/:slug/user-tokens
 * PUT /v1/dashboard/companies/:slug/user-tokens/:sid
 * PUT /v1/noc/companies/:slug/user-tokens/:sid
 */
export interface UserTokenResponse {
  sid: string;
  user_token: string;
  user_sid: string;
  available_at?: string;
  /** human-readable name */
  alias: string;
  max_clients_limit: number;
  expires_at?: string;
}

/**
 * GET /v1/dashboard/company-users/:company-user-sid/tokens
 * GET /v1/noc/company-users/:company-user-sid/tokens
 */
export interface UsersTokensResponse {
  company_slug: string;
  user_sid: string;
  tokens: UserTokenResponse[];
}

/** GET /v1/dashboard/companies/<controller_id>/controllers */
export interface ControllerListDashboardResponse {
  company_slug: string;
  /** human-readable name */
  company_name: string;
  /** controller_id, display name */
  controllers: { [key: string]: string };
}

export interface ControllerListDashboardResponse_ControllersEntry {
  key: string;
  value: string;
}

/** GET /v1/dashboard/controller/<controller_id>/status */
export interface ControllerStatusDashboardResponse {
  name: string;
  company_slug: string;
  created_at?: string;
  updated_at?: string;
  status: string;
  /** FQDN */
  hostname: string;
  address: string;
  square_feet: number;
  /** AP List */
  access_points: AccessPointStats[];
  /**
   * SSID List
   * ssid, status
   */
  radio_status: { [key: string]: string };
  /**
   * Controller Statistics
   * name, value
   */
  stats: { [key: string]: string };
  connected_guests: number;
  connected_clients: number;
  connected_devices: number;
  upload_bytes_per_second: number;
  download_bytes_per_second: number;
  bandwidth_updated_at?: string;
  last_rebooted_at?: string;
}

export interface ControllerStatusDashboardResponse_RadioStatusEntry {
  key: string;
  value: string;
}

export interface ControllerStatusDashboardResponse_StatsEntry {
  key: string;
  value: string;
}

export interface AccessPointStats {
  name: string;
  clients: number;
  physical_location: string;
}

/** GET /v1/dashboard/controller/<controller_id>/clients */
export interface ClientListDashboardResponse {
  company_slug: string;
  controller_id: string;
  /** TODO APPENG-468 Delete this */
  clients: UserClient[];
  controller_clients: ControllerClient[];
}

/** GET /v1/dashboard/controllers/:name/internet-service-plans */
export interface DashboardISP {
  sid: string;
  created_at?: string;
  controller: string;
  provider: string;
  provider_name: string;
  product: ISPProduct;
  status: ISPStatus;
  download_kbps: number;
  upload_kbps: number;
  monthly_cost_cents: number;
  /**
   * IP address and mask; IP address does not need to be the lowest in the
   * range. Empty for Dynamic IP
   */
  has_static_ip: boolean;
  static_ip_range: string;
  gateway_addr: string;
  controller_ip: string;
  first_usable_ip: string;
  last_usable_ip: string;
}

export interface ISPListDashboardResponse {
  plans: DashboardISP[];
}

/** TODO APPENG-468 Deprecate this after Dashboard uses ControllerClient */
export interface UserClient {
  mac_address: string;
  ip_address: string;
  expiry?: string;
  name: string;
  network: string;
  tx_rate: number;
  rx_rate: number;
  noise: number;
  signal: number;
  channel: number;
  /** Deprecated in favor of `ssids` */
  ssid: string;
  sid: string;
  last_seen?: string;
  apname: string;
  ap_location: string;
  /** freeform strings to characterize the client like 'guest', 'wired', 'wireless' */
  tags: string[];
  /** Temporary field to bridge between this and ControllerClient */
  lease?: Lease;
  /** The Meter Auth token used to connect the client to a network */
  user_token_sid?: string;
  /** The Meter Auth company user who connected this client */
  user_sid?: string;
  ssids: string[];
  alias: string;
  vlan: string;
}

/** GET /v1/dashboard/controller/<controller_id>/devices */
export interface DeviceListDashboardResponse {
  company_slug: string;
  controller_id: string;
  /** To be deprecated (APPENG-302) */
  devices: MeterDevice[];
  access_points_radios: AccessPointRadiosResponse[];
}

/** To be deprecated (APPENG-302) */
export interface MeterDevice {
  name: string;
  mac_address: string;
  ip_address: string;
  status: string;
  physical_location: string;
  clients: number;
  /** ssid, status */
  radio_status: { [key: string]: string };
}

export interface MeterDevice_RadioStatusEntry {
  key: string;
  value: string;
}

export interface ControllerSettingDashboardRequest {
  company_slug: string;
  controller_id: string;
  settings: { [key: string]: string };
}

export interface ControllerSettingDashboardRequest_SettingsEntry {
  key: string;
  value: string;
}

export interface ControllerSettingDashboardResponse {
  company_slug: string;
  controller_id: string;
  settings: { [key: string]: string };
}

export interface ControllerSettingDashboardResponse_SettingsEntry {
  key: string;
  value: string;
}

/** GET /v1/dashboard/password-suggestions */
export interface PasswordSuggestionResponse {
  suggested_password: string;
}

export interface CompanyRolePortalResponse {
  email: string;
  company_roles: UserResponse[];
}

/** POST /v1/locations */
export interface LocationCreateRequest {
  point?: GeoPoint;
  address?: Address;
  company_slug: string;
  create_onboarding: boolean;
}

/** PUT /v1/locations/:sublocationSID */
export interface LocationUpdateRequest {
  point?: GeoPoint;
  address?: Address;
  company_slug: string;
}

/** GET /v1/locations/:sublocationSID */
export interface LocationResponse {
  location?: Location;
}

/** GET /v1/locations */
export interface LocationListResponse {
  locations: LocationResponse[];
}

export interface Level {
  level: number;
  valid: boolean;
}

/**
 * If level is not present, set level_valid should be false.
 * Otherwise, the 0 value for level will be interpreted as a valid level value.
 */
export interface Layout {
  sid: string;
  floor_plan: string;
  level?: Level;
  notes: string;
  status: string;
  created_at?: string;
  updated_at?: string;
  friendly_name: string;
  sublocation_sid: string;
}

export interface AccessPointConfig {
  sid: string;
  config: Uint8Array;
  notes: string;
  friendly_name: string;
}

/**
 * POST /v1/configurations/access-point
 * PUT /v1/configurations/access-point/:sid
 */
export interface AccessPointConfigRequest {
  config: Uint8Array;
  notes: string;
  friendly_name: string;
}

/** LIST /v1/configurations/access-point */
export interface AccessPointConfigListResponse {
  access_point_configs: AccessPointConfig[];
}

export interface AccessPointLayout {
  sid: string;
  layout_sid: string;
  point?: Point;
  config_sid: string;
  access_point_sid: string;
  notes: string;
  friendly_name: string;
  created_at?: string;
  updated_at?: string;
}

export interface AccessPointLayoutRequest {
  sid: string;
  point?: Point;
  config_sid: string;
  access_point_sid: string;
  notes: string;
  friendly_name: string;
}

/**
 * POST /v1/locations/:sublocationSID/layouts
 * POST /v1/locations/:sublocationSID/layouts/:sid
 */
export interface LayoutResponse {
  layout?: Layout;
  presigned_floor_plan: string;
  access_point_layouts: AccessPointLayout[];
}

/** GET /v1/locations/:sublocationSID/layouts */
export interface LayoutListResponse {
  layouts: LayoutResponse[];
}

export interface LayoutRequest {
  level?: Level;
  notes: string;
  status: string;
  access_point_layouts: AccessPointLayoutRequest[];
  friendly_name: string;
}

export interface Provider {
  sid: string;
  name: string;
  /** E.g. "/providers/sonic.png" */
  path: string;
  /** portal_url is the URL for the provider's customer support portal */
  portal_url: string;
}

/** POST /v1/providers body */
export interface ProviderCreateRequest {
  name: string;
  path: string;
}

export interface ProviderUpdateRequest {
  name: string;
  path: string;
}

/** GET /v1/providers */
export interface ProvidersResponse {
  providers: Provider[];
}

export interface CompanySlugList {
  default_slug: string;
  company_slugs: string[];
}

export interface CreateCompanySlugRequest {
  new_company_slug: string;
  set_default: boolean;
}

export interface CreateCompanySlugResponse {
  default_slug: string;
  company_slug: string;
}

/** GET /v1/controllers/:name/cellular/activate */
export interface CellularActivateRequest {
  sim_iccid: string;
}

/**
 * GET /v1/controllers/:name/cellular/
 * POST,PUT /v1/controllers/:name/cellular/[de|re]activate
 */
export interface CellularResponse {
  /** right now, all fields are retrieved from Twilio */
  twilio_sid: string;
  status: string;
  created_at?: string;
  updated_at?: string;
  twilio_account_sid: string;
  unique_name: string;
  iccid: string;
  fleet_name: string;
  fleet_url: string;
  url: string;
}

/** POST /v1/users/ssh-keys */
export interface UserSSHKeysCreateRequest {
  /** SSH key is in the OpenSSH authorized key format. */
  ssh_key: Uint8Array;
}

export interface SSHKey {
  sid: string;
  /** value is in the OpenSSH authorized key format. */
  value: string;
  created_at?: string;
}

/** GET /v1/users/ssh-keys */
export interface UserSSHKeysListResponse {
  ssh_keys: SSHKey[];
}

/** GET /v1/identity/controller-access */
export interface UserControllerSSHAccessListResponse {
  user_controller_ssh_accesses: UserControllerSSHAccessListResponse_UserControllerSSHAccess[];
}

export interface UserControllerSSHAccessListResponse_UserControllerSSHAccess {
  controller_name: string;
  ssh_user: SSHUser;
  expires_at?: string;
}

/** POST /v1/controllers/:name/user-access */
export interface ControllerUserAccessCreateRequest {
  expires_at?: string;
  ssh_user: SSHUser;
}

/** GET /v1/controllers/:name/authorized-keys/:user */
export interface ControllerAuthorizedKeysResponse {
  /** Each authorized key should be in the OpenSSH authorized key format. */
  authorized_keys: string[];
}

/** POST /v1/jumphost-accesses */
export interface UserJumphostAccessKeyCreateRequest {
  /** The name of the device this key is created for */
  name: string;
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
}

/** POST /v1/jumphost-accesses */
export interface UserJumphostAccessKeyCreateResponse {
  sid: string;
  /** The IPv6 address assigned to requested key */
  ip_address: string;
}

/** GET /v1/jumphost-accesses */
export interface UserJumphostAccessKeyListResponse {
  jumphost_access_keys: UserJumphostAccessKey[];
}

export interface UserJumphostAccessKey {
  sid: string;
  username: string;
  name: string;
  /** The IPv6 address assigned to requested key */
  ip_address: string;
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
  created_at?: string;
}

/**
 * POST /v1/onboardings
 * PUT /v1/onboardings
 */
export interface UpsertOnboardingRequest {
  sublocation_sid: string;
  company_slug: string;
  provider_sid: string;
  network_ssid: string;
  network_password: string;
  guest_network_ssid: string;
  guest_network_password: string;
  guest_network_rotate_every_hours?: number;
  building_contact_type: BuildingContactType;
  building_contact_email: string;
  building_contact_phone: string;
  billing_contact_email: string;
  status: OnboardingStatus;
}

/** DELETE /v1/onboardings */
export interface DeleteOnboardingRequest {
  sublocation_sid: string;
  company_slug: string;
}

/**
 * POST /v1/onboardings (response)
 * PUT /v1/onboardings (response)
 * GET /v1/onboardings
 */
export interface Onboarding {
  sid: string;
  sublocation_sid: string;
  company_slug: string;
  provider_sid: string;
  network_ssid: string;
  network_password: string;
  guest_network_ssid: string;
  guest_network_password: string;
  guest_network_rotate_every_hours: number;
  building_contact_type: BuildingContactType;
  building_contact_email: string;
  building_contact_phone: string;
  billing_contact_email: string;
  status: OnboardingStatus;
}

/**
 * GET /v1/devices/<device-sid> (response)
 * Base representation for all meter devices
 */
export interface Device {
  /**
   * Unique, device-aware identifier
   * Currently, not all devices have serial number populated, so it is not required.
   */
  serial_number?: string;
  /** Device UUID */
  id: string;
  /** Board type */
  board: string;
  /** Current build name */
  build: string;
  boot_count: number;
  created_at?: string;
  updated_at?: string;
  /** Friendly reason that the device rebooted most recently (ex: "upgrade") */
  reboot_reason: string;
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
  /** IPv6 address */
  ip_address: string;
}

/** POST /v1/devices/<device-sid> */
export interface DeviceUpdateRequest {
  /** Unique, device-aware identifier */
  serial_number?: string;
  /** Current build name */
  build: string;
  boot_count: number;
  reboot_reason: string;
}

export interface DeviceRegisterRequest {
  serial_number: string;
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
  /** EUI-48 mac address used to create an IPv6 address */
  mac_address: string;
  /** 32 byte HMAC-SHA256 */
  signature: Uint8Array;
}

export interface DeviceRegisterResponse {
  vpn_server?: DeviceRegisterResponse_VPNServer;
  /** IPv6 address generated from sent MAC address */
  ip_address: string;
  allowed_ips: string[];
}

export interface DeviceRegisterResponse_VPNServer {
  /** 32 byte Curve25519 public key */
  public_key: Uint8Array;
  /** endpoint of the server, a FQDN or IP address */
  endpoint: string;
  /** listen port */
  port: number;
}

export interface DeviceListResponse {
  devices: Device[];
}

/** GET /v1/device-images/cos */
export interface COSVersions {
  versions: string[];
}

export interface COSImageFileMetadata {
  url: string;
  hash: string;
  created_at?: string;
  board: string;
}

/** GET /v1/device-images/cos/<version> */
export interface COSImageFileMetadatas {
  version: string;
  files: { [key: string]: COSImageFileMetadata };
}

export interface COSImageFileMetadatas_FilesEntry {
  key: string;
  value?: COSImageFileMetadata;
}

/** POST /v1/device-images/cos/<version>/<filename> */
export interface COSImageFileUploadRequest {
  hash: string;
  board: string;
}

/** POST /v1/device-images/cos/<version>/<filename> (response) */
export interface COSImageFileUploadResponse {
  url: string;
}

/** GET /v1/device-images/<board> */
export interface DeviceImageBuilds {
  builds: string[];
}

export interface DeviceImageFileMetadata {
  sha256hash: string;
  url: string;
}

/** GET /v1/device-images/<board>/<build> */
export interface DeviceImageBuild {
  build: string;
  board: string;
  created_at?: string;
  /** maps file name to file metadata */
  files: { [key: string]: DeviceImageFileMetadata };
}

export interface DeviceImageBuild_FilesEntry {
  key: string;
  value?: DeviceImageFileMetadata;
}

export interface DeviceImageFileRequest {
  name: string;
  sha256hash: string;
}

/** POST /v1/device-images/<board>/<build> */
export interface DeviceImageBuildUploadRequest {
  files: DeviceImageFileRequest[];
}

/** POST /v1/device-images/<board>/<build> (response) */
export interface DeviceImageBuildUploadResponse {
  /** maps file name to S3 presigned url to upload the file */
  urls: { [key: string]: string };
}

export interface DeviceImageBuildUploadResponse_UrlsEntry {
  key: string;
  value: string;
}

/**
 * POST /v1/controllers/:name/incidents
 * PUT /v1/controllers/:name/incidents/:sid
 */
export interface UpsertControllerIncidentRequest {
  start_time?: string;
  end_time?: string;
  notes?: string;
  category?: string;
}

/**
 * Response for:
 * GET /v1/controllers/:name/incidents
 */
export interface ControllerIncidents {
  incidents: ControllerIncident[];
}

/**
 * Response for:
 * POST /v1/controllers/:name/incidents
 * PUT /v1/controllers/:name/incidents/:sid
 * GET /v1/controllers/:name/incidents/:sid
 */
export interface ControllerIncident {
  sid: string;
  controller: string;
  start_time?: string;
  end_time?: string;
  notes?: string;
  category?: string;
  created_by_email?: string;
  created_at?: string;
  updated_at?: string;
}

/** GET /v1/rpc/connections */
export interface RPCConnectionsResponse {
  connections: RPCConnection[];
}

export interface RPCConnection {
  controller: string;
  broker_addr: string;
}

/**
 * Response for:
 * GET /v1/noc/controllers/:name/config-history
 */
export interface ControllerConfigHistory {
  history: ControllerConfigHistoryRow[];
}

export interface ControllerConfigHistoryRow {
  controller: string;
  updated_at?: string;
  updated_by?: string;
  update_comment?: string;
  key: string;
  value?: { [key: string]: any };
}

/**
 * Response for:
 * POST /v1/companies/:slug/content-filters
 */
export interface EnableContentFilterForCompanyResponse {
  enabled: ContentFilterLocation[];
  errors: EnableContentFilterForCompanyResponse_Error[];
}

export interface EnableContentFilterForCompanyResponse_Error {
  controller_name: string;
  reason: string;
}

/**
 * Response for:
 * GET /v1/companies/:slug/content-filters
 */
export interface CompanyContentFiltersResponse {
  /**
   * If a location (controller) is already enabled, then it shows up in `enabled`.
   * Else, if an underlying controller is eligible to be enabled but not yet enabled, it shows up in `eligible_controllers`.
   */
  enabled: ContentFilterLocation[];
  eligible_controllers: string[];
}

/**
 * Response for:
 * GET /v1/companies/:slug/content-filters
 * POST /v1/companies/:slug/content-filters
 */
export interface ContentFilterLocation {
  cloudflare_account_id: string;
  controller_name: string;
  cloudflare_location_id: string;
  created_at?: string;
  ip_addresses: string[];
}

/**
 * Request for:
 * POST /v1/companies/:slug/content-filters/rules
 * PUT /v1/companies/:slug/content-filters/rules/:id
 */
export interface UpsertContentFilterRuleRequest {
  description: string;
  action: ContentFilterAction;
  domain?: string;
  category_id?: number;
  precedence?: number;
}

/**
 * Response for:
 * GET /v1/companies/:slug/content-filters/rules
 */
export interface ContentFilterRules {
  rules: ContentFilterRule[];
}

/**
 * Response for:
 * GET /v1/companies/:slug/content-filters/rules/:id
 */
export interface ContentFilterRule {
  id: string;
  description: string;
  action: ContentFilterAction;
  domain?: string;
  created_at?: string;
  category?: ContentFilterRuleCategory;
  precedence: number;
}

/**
 * Response for:
 * GET /v1/companies/:slug/content-filters/rule-categories
 */
export interface ContentFilterRuleCategories {
  categories: ContentFilterRuleCategory[];
}

export interface ContentFilterRuleCategory {
  name: string;
  id: number;
  description: string;
  subcategories: ContentFilterRuleCategory[];
}

/** POST /v1/users/authentications/otp */
export interface CreateUserOTPAuthenticationRequest {
  email: string;
  phone_number: string;
  callback_url: string;
}

export interface CreateUserOTPAuthenticationResponse {
  email: string;
  phone_number: string;
  callback_url: string;
}

/** POST /v1/users/authentications/otp/validate */
export interface CreateUserOTPAuthenticationValidationRequest {
  email: string;
  phone_number?: string;
  code: string;
  via?: string;
  path?: string;
}

/** POST /v1/mac_address_aliases */
export interface UpsertMACAddressAliasRequest {
  mac: string;
  alias: string;
}

/** POST /v1/user_feedback */
export interface CreateUserFeedbackRequest {
  feedback: string;
  company_user_sid: string;
  url?: string;
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
