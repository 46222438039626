/* eslint-disable import/no-cycle */
import React from 'react';

// Libraries
import { createSearchParams, generatePath } from 'react-router-dom';

import RedirectToCompanyDashboard from './dashboard/pages/RedirectToCompanyDashboard';
import QuoteRequestsCompleted from './pages/QuoteRequests/Completed';
import QuoteRequestsConfirm from './pages/QuoteRequests/Confirm';
import QuoteRequestsContact from './pages/QuoteRequests/Contact';
import QuoteRequestsContract from './pages/QuoteRequests/Contract';
// Data collection flow
import QuoteRequestsLocation from './pages/QuoteRequests/Location';
import QuoteRequestsProvider from './pages/QuoteRequests/Provider';
// Post collection flow
import QuoteRequestsResults from './pages/QuoteRequests/Results';
import QuoteRequestsSubmit from './pages/QuoteRequests/Submit';
import QuoteRequestsTopProvider from './pages/QuoteRequests/TopProvider';

const indexedRoutes: { [key: string]: WriteableRouteDefn } = {};
type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export type RouteDefn<ChildType, ParentType> = {
  readonly name: string;
  readonly path: string;
  readonly element: React.ReactNode;
  children?: ChildType; // ReadonlyArray<RouteDefn>;
  parent?: ParentType; // RouteDefn;
  index?: boolean;
};

// @ts-ignore
type StdRouteDefn = RouteDefn<ReadonlyArray<StdRouteDefn>, StdRouteDefn>;

// @ts-ignore
export type WriteableRouteDefn = Writeable<
  RouteDefn<Array<WriteableRouteDefn>, WriteableRouteDefn>
>;

const makeRoute = (route: StdRouteDefn, parent?: WriteableRouteDefn) => {
  const { name, children } = route;
  if (indexedRoutes[name]) {
    throw Error(`Route with name=${name} already registered.`);
  }
  const r = {
    ...route,
    ...{ parent },
  };

  if (children) {
    r.children = children.map((child: StdRouteDefn) => makeRoute(child, r));
  }

  indexedRoutes[name] = r;
  return indexedRoutes[name];
};

const routes = [
  // Root path
  {
    name: 'welcome',
    path: '/',
    element: <QuoteRequestsLocation />,
    children: [],
  },

  // Collection steps
  {
    name: 'location',
    path: '/location',
    element: <QuoteRequestsLocation />,
    children: [],
  },
  {
    name: 'contract',
    path: '/contract/:sid',
    element: <QuoteRequestsContract />,
    children: [],
  },
  {
    name: 'topProviders',
    path: '/topProviders/:sid',
    element: <QuoteRequestsTopProvider />,
    children: [],
  },
  {
    name: 'provider',
    path: '/provider/:sid',
    element: <QuoteRequestsProvider />,
    children: [],
  },
  {
    name: 'submit',
    path: '/submit/:sid',
    element: <QuoteRequestsSubmit />,
    children: [],
  },

  // Complete flow
  {
    name: 'results',
    path: '/quoteRequests/:sid/results',
    element: <QuoteRequestsResults />,
    children: [],
  },
  {
    name: 'confirm',
    path: '/quoteRequests/:sid/confirm',
    element: <QuoteRequestsConfirm />,
    children: [],
  },
  {
    name: 'contact',
    path: '/quoteRequests/:sid/contact',
    element: <QuoteRequestsContact />,
    children: [],
  },
  {
    name: 'completed',
    path: '/quoteRequests/:sid/completed',
    element: <QuoteRequestsCompleted />,
    children: [],
  },
  {
    name: 'connection',
    path: '/dashboard/:companySID/locations/:sid',
    element: <RedirectToCompanyDashboard />,
    children: [],
  },
  {
    name: 'dashboard',
    path: '/dashboard/*',
    element: <RedirectToCompanyDashboard />,
    children: [],
  },
] as const;

export const { regRoutes } = routes.reduce(
  (memo, route) => {
    const madeRoute = makeRoute(route);

    return {
      ...memo,
      regRoutes: [...memo.regRoutes, madeRoute],
    };
  },
  { regRoutes: [] as WriteableRouteDefn[], dashboardRoutes: [] as WriteableRouteDefn[] },
);

type TopLevelNames = (typeof routes)[number]['name'];
// @ts-ignore
type ChildNames = (typeof routes)[number]['children'][number]['name'];
// @ts-ignore
type ChildChildNames = (typeof routes)[number]['children'][number]['children'][number]['name'];

export const route = (
  name: TopLevelNames | ChildNames | ChildChildNames,
  params?: { [k: string]: any },
): string => {
  const { parent } = indexedRoutes[name];
  let { path } = indexedRoutes[name];
  if (parent && parent.path) {
    path = `${parent.path}/${path}`;
  }
  const p = { ...(params || {}), companySID: 'meter' };
  let generatedPath = generatePath(path, p);
  if (params && params.query) {
    generatedPath += `?${createSearchParams(params.query).toString()}`;
  }
  return generatedPath;
};
