import React from 'react';

import { Route } from 'react-router-dom';

import { WriteableRouteDefn } from './routes';

export function regRoutesToElements(routes: WriteableRouteDefn[], parentPath = '') {
  return routes.map((route) => {
    let suffix: string | null = null;

    if (!route.path) {
      suffix = route.index ? '-index' : '-layout';
    }

    const key = `${parentPath}${suffix || `/${route.path}`}`;

    return (
      <Route key={key} path={route.path} element={route.element}>
        {route.children && route.children.length > 0
          ? regRoutesToElements(route.children, route.path)
          : null}
      </Route>
    );
  });
}
