/* eslint-disable eqeqeq */
/* eslint-disable import/no-cycle */
import React from 'react';

import cx from 'classnames';

import Header from './Header';

interface SidebarLayoutProps {
  children: React.ReactNode;
  sidebarChildren: React.ReactNode;
  align?: 'center' | 'top';
  hideSidebarOnMobile?: boolean;
}
// TODO RENAME THIS to MainLayout
function SidebarLayout({
  children,
  sidebarChildren,
  align = 'center',
  hideSidebarOnMobile = true,
}: SidebarLayoutProps) {
  const contentClasses = cx('flex-1 flex pb-28 p-6 pb-28 pt-4 border-r border-gray-100', {
    'items-center': align == 'center',
    'items-top': align == 'top',
  });

  const sidebarClasses = cx(
    'flex-1 bg-gray-50 p-10 pb-28 pt-4 w-full md:max-w-md',
    {
      'hidden md:flex': hideSidebarOnMobile,
      flex: !hideSidebarOnMobile,
    },
    {
      'items-center': align == 'center',
      'items-top': align == 'top',
    },
  );

  return (
    <main className="w-full flex flex-col">
      <Header />
      <div className="flex h-full w-full flex-col md:flex-row mt-48">
        <section className={contentClasses}>{children}</section>
        <section className={sidebarClasses}>
          <div className="w-full">{sidebarChildren}</div>
        </section>
      </div>
    </main>
  );
}

export default SidebarLayout;
