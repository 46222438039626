import type { SliderProps as RadixSliderProps } from '@radix-ui/react-slider';
import * as SliderPrimitive from '@radix-ui/react-slider';
import React, { useCallback, useMemo, useState } from 'react';

import { fade, palette } from '../../common/colors';
import { TextInput } from '../../components/TextInput/TextInput';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { selectors, transitions } from '../shared/styles';

const SliderMarker = styled(Small, {
  display: 'flex',
  justifyContent: 'center',
  width: 0,
  whiteSpace: 'nowrap',

  '&:first-child': {
    justifyContent: 'flex-start',
  },

  '&:last-child': {
    justifyContent: 'flex-end',
  },
});

const SliderMarkers = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: '$8 $12 0',
});

const SliderThumb = styled(SliderPrimitive.Thumb, {
  zIndex: 1,
  display: 'block',
  width: '14px',
  height: '14px',
  background: colors.bgApplicationLight,
  boxShadow: shadows.controlRaisedInitialLight,
  $$dotColor: colors.strokeApplicationLight,
  borderRadius: '99em',
  transition: transitions.control,
  translateY: '-50%',
  outline: 'none',
  userSelect: 'none',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.controlRaisedInitialDark,
    $$dotColor: colors.strokeApplicationDark,
  },

  [selectors.hover]: {
    boxShadow: shadows.controlRaisedHoveredLight,

    [darkThemeSelector]: {
      boxShadow: shadows.controlRaisedHoveredDark,
    },
  },

  [selectors.focus]: {
    boxShadow: shadows.controlRaisedFocusedLight,
    $$dotColor: colors.brand600,

    [darkThemeSelector]: {
      boxShadow: shadows.controlRaisedFocusedDark,
      $$dotColor: colors.brand600,
    },
  },

  '&:before': {
    content: '',
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'block',
    width: '6px',
    height: '6px',
    backgroundColor: '$$dotColor',
    borderRadius: '99em',
    transform: 'translate(-50%, -50%)',
  },

  '&[data-disabled]': {
    boxShadow: shadows.controlRaisedDisabledLight,
    $$dotColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      boxShadow: shadows.controlRaisedDisabledDark,
      $$dotColor: colors.strokeNeutralDark,
    },
  },
});

const SliderRange = styled(SliderPrimitive.Range, {
  position: 'absolute',
  top: 0,
  bottom: 0,
  zIndex: 0,
  backgroundColor: colors.brand600,
  borderRadius: '99em',

  '&[data-disabled]': {
    backgroundColor: colors.brand300,

    [darkThemeSelector]: {
      backgroundColor: colors.brand800,
    },
  },
});

const SliderTrack = styled(SliderPrimitive.Track, {
  position: 'relative',
  zIndex: 0,
  width: '100%',
  height: '2px',
  backgroundColor: colors.tokenBgNeutralLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    backgroundColor: colors.tokenBgNeutralDark,
  },
});

const SliderFields = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
});

const SliderArea = styled(SliderPrimitive.Root, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  width: '100%',
  padding: '$12',
});

const SliderAreaContainer = styled('div', {
  borderRadius: '$8',
  background: colors.bgApplicationLight,
  strokeAll: fade(palette.controlStrokeBaseLight, 0.12),

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeAll: fade(palette.controlStrokeBaseDark, 0.44),
  },

  variants: {
    disabled: {
      true: {
        background: colors.bgNeutralLight,
        strokeAll: fade(palette.controlStrokeBaseLight, 0.3),
        opacity: 0.5,
        pointerEvents: 'none',

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
          strokeAll: fade(palette.controlStrokeBaseDark, 0.36),
        },
      },
    },
  },
});

const SliderContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  width: '100%',
  userSelect: 'none',
  touchAction: 'none',
});

export type SliderProps = RadixSliderProps & {
  showFields?: boolean;
  showMarkers?: boolean;
  middleMarkers?: number[];
  unit?: React.ReactNode;
  showTooltips?: boolean;
};

export const Slider = React.forwardRef<HTMLSpanElement, SliderProps>((props, forwardedRef) => {
  const {
    disabled,
    onValueChange,
    showFields,
    showMarkers,
    showTooltips = true,
    middleMarkers,
    min = 0,
    max = 100,
    unit,
  } = props;

  const values = useMemo(
    () => props.value ?? props.defaultValue ?? [],
    [props.value, props.defaultValue],
  );

  const handleValueChange = useCallback(
    (index: number) => (value: string) => {
      if (!onValueChange) return;
      const newValues = values.slice();
      const num = Number(value);
      if (Number.isNaN(num)) return;
      newValues[index] = num;
      onValueChange(newValues);
    },
    [onValueChange, values],
  );

  const [isHovered, setHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <SliderContainer>
      <SliderAreaContainer
        disabled={props.disabled}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        {showMarkers && (
          <SliderMarkers>
            <SliderMarker variant="tabular" weight="bold">
              {min}
              {unit}
            </SliderMarker>
            {middleMarkers?.map((marker) => (
              <SliderMarker key={marker} variant="tabular" weight="bold">
                {marker}
                {unit}
              </SliderMarker>
            ))}
            <SliderMarker variant="tabular" weight="bold">
              {max}
              {unit}
            </SliderMarker>
          </SliderMarkers>
        )}
        <SliderArea disabled={props.disabled} {...props} ref={forwardedRef}>
          <SliderTrack>
            <SliderRange />
          </SliderTrack>
          {values.map((value, i) => {
            const thumb = (
              <SliderThumb
                tabIndex={disabled ? undefined : 0}
                data-disabled={disabled}
                // eslint-disable-next-line react/no-array-index-key
                key={`thumb-${i}`}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
              />
            );
            return showTooltips ? (
              <Tooltip
                contents={
                  <>
                    {value}
                    {unit}
                  </>
                }
                isOpen={isHovered || isFocused}
              >
                {thumb}
              </Tooltip>
            ) : (
              thumb
            );
          })}
        </SliderArea>
      </SliderAreaContainer>
      {showFields && (
        <SliderFields>
          {values.map((value, i) => (
            <TextInput
              disabled={disabled}
              // eslint-disable-next-line react/no-array-index-key
              key={`input-${i}`}
              type="number"
              width="100%"
              value={`${value}`}
              onChange={handleValueChange(i)}
              suffix={unit}
            />
          ))}
        </SliderFields>
      )}
    </SliderContainer>
  );
});
