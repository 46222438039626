import { styled } from '../../styled';

export const TdContentWrapper = styled('div', {
  height: '100%',
  flex: 1,
  flexDirection: 'column',
  padding: '$10 $8 $10 $20',
  display: 'flex',
  overflow: 'hidden',
  '& > div': {
    textOverflow: 'ellipsis',
  },
  // justifyContent: "center",
  variants: {
    position: {
      last: {
        padding: '$10 $8 $10 $8',
      },
      lastHeader: {
        padding: 0,
      },
    },
  },
});
