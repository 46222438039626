import { MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';

// react-router v6 friendly version
export default function useRouter() {
  const navigate = useNavigate();

  // UX-friendly handling of link/button clicks
  function handleOnClick(event: MouseEvent<HTMLAnchorElement>) {
    if (event.metaKey || event.altKey || event.shiftKey) {
      // meta = open in new tab
      // alt = download page
      // shift = open in new window
    } else {
      event.preventDefault();

      // TS error otherwise
      const hrefMaster = event.currentTarget.getAttribute('href') as string;
      navigate(hrefMaster);
    }
  }

  return {
    handleOnClick,
  };
}
