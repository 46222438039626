import React from 'react';

import { Caption, TextInput } from '@meterup/atto';

// Props will be a bunch of onchangeevent
interface FilterLocationProps {
  address: string;
}

function FilterLocation({ address }: FilterLocationProps) {
  return (
    <section className="mt-2">
      <Caption>Install Location</Caption>
      <div className="mt-2">
        <TextInput
          name="Location"
          disabled
          icon="location"
          controlSize="large"
          value={address}
          aria-label={`Location: ${address}`}
          placeholder="Enter address"
        />
      </div>
    </section>
  );
}

export default FilterLocation;
