/* eslint-disable import/no-cycle */
import React from 'react';

import { Link } from '@meterup/atto';
import cx from 'classnames';

import OnboardingHeader from '../OnboardingHeader/OnboardingHeader';
import styles from './OnboardingLayout.module.css';

interface OnboardingLayoutProps {
  children: React.ReactNode;
  sidebarChildren: React.ReactNode;
}
// TODO RENAME THIS to MainLayout
function OnboardingLayout({ children, sidebarChildren }: OnboardingLayoutProps) {
  const contentClasses = cx('flex-1 flex p-6 pt-12 pb-14 items-center flex-col');

  const sidebarClasses = cx(
    'flex-1 bg-gray-50 p-10 pb-28 pt-12 w-full flex items-center justify-center items-center',
    styles.sidebar,
    'hidden md:flex',
  );

  return (
    <main className="w-full flex flex-col relative">
      <OnboardingHeader />
      <div className="flex h-full w-full flex-col md:flex-row">
        <section className={contentClasses}>
          <div className="flex-1 w-full flex items-center mb-32">{children}</div>
          <div className="mx-auto max-w-screen-xs max-w-sm w-full flex justify-items-start">
            <p className="w-full text-left hidden md:inline-block mr-auto">
              <span className="text-gray-600">Meter, Inc.</span>
              <span className="inline-block px-2 text-gray-500"> •</span>
              <Link href="https://www.meter.com/support/connect" target="_blank">
                Support & FAQs
              </Link>
            </p>
          </div>
        </section>
        <section className={sidebarClasses}>
          <div className="w-full md:max-w-md">{sidebarChildren}</div>
        </section>
      </div>
    </main>
  );
}

export default OnboardingLayout;
