import React from 'react';

import { Body, Caption, CheckboxInputWithLabel, FormattedMoney } from '@meterup/atto';
import { Quote } from '@meterup/connect-api';

// Props will be a bunch of onchangeevent
interface FilterProvidersProps {
  allProviders: string[];
  currentProviders: string[];
  onProvidersChange: (providerName: string, checked: boolean) => void;
  quotesByProvider: { [provider: string]: Quote[] };
}

function FilterProviders({
  allProviders,
  currentProviders,
  onProvidersChange,
  quotesByProvider,
}: FilterProvidersProps) {
  return (
    <section>
      <Caption>Providers</Caption>
      <div className="mt-2">
        {allProviders.map((providerName) => (
          <div className="mt-2" key={providerName}>
            <CheckboxInputWithLabel
              checked={currentProviders.includes(providerName)}
              onChange={(checked: boolean) => onProvidersChange(providerName, checked)}
              aria-label={`Choose provider ${providerName}`}
              name={`providerName-${providerName}`}
            >
              <div
                className="w-full flex justify-between"
                onClick={() =>
                  onProvidersChange(providerName, !currentProviders.includes(providerName))
                }
              >
                <Body weight="bold">{providerName}</Body>
                <Body>
                  <FormattedMoney
                    amount={quotesByProvider[providerName][0].monthlyFeeCents / 100}
                  />
                </Body>
              </div>
            </CheckboxInputWithLabel>
          </div>
        ))}
      </div>
    </section>
  );
}

export default FilterProviders;
