/* eslint-disable react-hooks/exhaustive-deps */
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';

import React, { useEffect, useRef } from 'react';

import tippy, { roundArrow } from 'tippy.js';

export interface TooltipProps {
  children: React.ReactNode;
  title: string;
}
function Tooltip({ children, title }: TooltipProps) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (tooltipRef.current) {
      tippy(tooltipRef.current, {
        content: title,
        arrow: roundArrow,
        placement: 'right',
        maxWidth: 250,
        theme: 'meter-theme',
      });
    }
  }, []);
  return <span ref={tooltipRef}>{children}</span>;
}
export default Tooltip;
