import React from 'react';

import { Body, Caption, CheckboxInputWithLabel, FormattedMoney } from '@meterup/atto';
import { Quote } from '@meterup/connect-api';

// Props will be a bunch of onchangeevent
interface FilterConnectionTypesProps {
  allConnectionTypes: string[];
  currentConnectionTypes: string[];
  onCurrentConnectionTypesChange: (connectionType: string, checked: boolean) => void;
  quotesByConnectionType: { [connectionType: string]: Quote[] };
}

function FilterConnectionTypes({
  allConnectionTypes,
  currentConnectionTypes,
  onCurrentConnectionTypesChange,
  quotesByConnectionType,
}: FilterConnectionTypesProps) {
  return (
    <section>
      <Caption>Connection Type</Caption>
      <div className="mt-4">
        {allConnectionTypes.map((connectionType) => (
          <div className="mt-2" key={connectionType}>
            <CheckboxInputWithLabel
              checked={currentConnectionTypes.includes(connectionType)}
              onChange={(checked) => {
                onCurrentConnectionTypesChange(connectionType, checked);
              }}
              name={`connectionType-${connectionType}`}
            >
              <div
                className="w-full flex justify-between"
                onClick={() =>
                  onCurrentConnectionTypesChange(
                    connectionType,
                    !currentConnectionTypes.includes(connectionType),
                  )
                }
              >
                <Body weight="bold">{connectionType}</Body>
                <Body weight="light">
                  <FormattedMoney
                    amount={quotesByConnectionType[connectionType][0].monthlyFeeCents / 100}
                  />
                </Body>
              </div>
            </CheckboxInputWithLabel>
          </div>
        ))}
      </div>
    </section>
  );
}

export default FilterConnectionTypes;
