import React from 'react';

import { Body } from '@meterup/atto';

// import { TextInput } from "@meterup/connect-ui";

// Props will be a bunch of onchangeevent
function QuotesOnboardingProviders() {
  return (
    <div className="rounded-md overflow-hidden max-w-sm	mx-auto">
      <div className="p-4 mb-nudge-2px text-center">
        <Body>Find deals from all providers</Body>
      </div>
      <ul className="grid grid-cols-3	">
        <li className="text-center flex items-center justify-center mt-6">
          <img src="/assets/att.svg" />
        </li>
        <li className="text-center flex items-center justify-center mt-6">
          <img src="/assets/cogent.svg" />
        </li>
        <li className="text-center flex items-center justify-center mt-6">
          <img src="/assets/spectrum.svg" />
        </li>
        <li className="text-center flex items-center justify-center mt-6">
          <img src="/assets/lumen.svg" />
        </li>
        <li className="text-center flex items-center justify-center mt-6">
          <img src="/assets/comcast.svg" />
        </li>
        <li className="text-center flex items-center justify-center mt-6">
          <img src="/assets/wiline.svg" />
        </li>
        <li className="text-center flex items-center justify-center mt-6 mb-6">
          <img src="/assets/verizon.svg" />
        </li>
        <li className="text-center flex items-center justify-center mt-6 mb-6">
          <img src="/assets/xfinity.svg" />
        </li>
        <li className="text-center flex items-center justify-center mt-6 mb-6">
          <img src="/assets/zayo.svg" />
        </li>
      </ul>
    </div>
  );
}

export default QuotesOnboardingProviders;
