import React from 'react';

import { TextInput, TextInputProps } from '@meterup/atto';
import { FieldRenderProps } from 'react-final-form';

// Mixed interface between React Final Form and our pure TextInputForm
export interface TextInputFieldProps extends FieldRenderProps<string>, TextInputProps {}

// TODO: Can we move this to the front-end component library?
function TextInputField({
  // React Final Form
  input,
  meta,
  placeholder,
  // Frontend components
  controlSize,
  size,
  icon,
  prefix,
  suffix,
  autocomplete,
  disabled,
  ariaLabel,
}: TextInputFieldProps) {
  // TODO: The error mark up should not be here
  const displayError = meta.error && meta.touched;
  const inputType = input.type as TextInputProps['type'];
  const internalOnChange = (value: string) =>
    input.onChange({
      target: {
        value,
      },
    });
  return (
    <div>
      <TextInput
        controlSize={controlSize}
        name={input.name}
        onChange={internalOnChange}
        // @ts-ignore: 2322
        onBlur={input.onBlur}
        // @ts-ignore: 2322
        onFocus={input.onFocus}
        value={input.value}
        type={inputType}
        placeholder={placeholder}
        autoComplete={autocomplete}
        size={size}
        icon={icon}
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        invalid={displayError}
        aria-label={ariaLabel}
      />
      {displayError && <span className="mt-2 ml-2 text-xs text-red-600">{meta.error}</span>}
    </div>
  );
}

// RRF = React Final Form
export default TextInputField;
