import * as d3 from 'd3';

export type VariantProp =
  | 'alternative'
  | 'attention'
  | 'brand'
  | 'negative'
  | 'neutral'
  | 'positive';

const checkered = `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 4 4' fill='none' opacity='0.02' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='black'/%3E%3Crect x='2' width='2' height='2' fill='white'/%3E%3Crect y='2' width='2' height='2' fill='white'/%3E%3Crect x='2' y='2' width='2' height='2' fill='black'/%3E%3C/svg%3E")`;

const base = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  amber50: '#FFF7ED',
  amber100: '#FDE1C0',
  amber200: '#F9D1A3',
  amber300: '#F5C083',
  amber400: '#DC9543',
  amber500: '#C67D29',
  amber600: '#AB5B00',
  amber700: '#8F4D00',
  amber800: '#7A4100',
  amber900: '#593000',
  blue50: '#EDF4FF',
  blue100: '#C0D7FD',
  blue200: '#92BBFC',
  blue300: '#6AA2F6',
  blue400: '#528FEB',
  blue500: '#4280DE',
  blue600: '#316CC6',
  blue700: '#1754B1',
  blue800: '#124A9F',
  blue900: '#073376',
  brand50: '#EFF1FF',
  brand100: '#D8DCFF',
  brand200: '#C9CFFF',
  brand300: '#A4AEFA',
  brand400: '#8A96F4',
  brand500: '#6977E1',
  brand600: '#5461C8',
  brand700: '#4551AF',
  brand800: '#384084',
  brand900: '#2B305B',
  gray50: '#FAFAFC',
  gray100: '#E4E6F0',
  gray200: '#C5C8D6',
  gray300: '#9799AD',
  gray400: '#818394',
  gray500: '#66687A',
  gray600: '#4E5161',
  gray700: '#343647',
  gray800: '#1E202E',
  gray900: '#131522',
  green50: '#D5FADC',
  green100: '#A7FAB5',
  green200: '#7CFA91',
  green300: '#5CEB74',
  green400: '#58DB6D',
  green500: '#33C34A',
  green600: '#1CA733',
  green700: '#0F8922',
  green800: '#056F15',
  green900: '#01570F',
  red50: '#FFE8E8',
  red100: '#FFC7C7',
  red200: '#FC8282',
  red300: '#F45757',
  red400: '#E33131',
  red500: '#D42121',
  red600: '#BB0F0F',
  red700: '#9E0707',
  red800: '#8A0909',
  red900: '#750505',
  checkered,
};

const bg = {
  bgApplicationLight: base.white,
  bgApplicationDark: base.gray800,
  bgAlternativeLight: base.blue50,
  bgAlternativeDark: base.blue900,
  bgAttentionLight: base.amber50,
  bgAttentionDark: base.amber800,
  bgBrandLight: base.brand50,
  bgBrandDark: base.brand900,
  bgNegativeLight: base.red50,
  bgNegativeDark: base.red900,
  bgNeutralLight: base.gray50,
  bgNeutralDark: base.gray900,
  bgPositiveLight: base.green50,
  bgPositiveDark: base.green900,
};

const stroke = {
  strokeApplicationLight: base.gray100,
  strokeApplicationDark: base.gray600,
  strokeAlternativeLight: base.blue100,
  strokeAlternativeDark: base.blue800,
  strokeAttentionLight: base.amber100,
  strokeAttentionDark: base.amber700,
  strokeBrandLight: base.brand100,
  strokeBrandDark: base.brand800,
  strokeNegativeLight: base.red100,
  strokeNegativeDark: base.red800,
  strokeNeutralLight: '#EDEFF5',
  strokeNeutralDark: base.gray700,
  strokePositiveLight: base.green300,
  strokePositiveDark: base.green800,
};

const heading = {
  headingAlternativeLight: base.blue800,
  headingAlternativeDark: base.blue50,
  headingAttentionLight: base.amber800,
  headingAttentionDark: base.amber50,
  headingBrandLight: base.brand800,
  headingBrandDark: base.brand50,
  headingNegativeLight: base.red800,
  headingNegativeDark: base.red50,
  headingNeutralLight: base.gray800,
  headingNeutralDark: base.gray50,
  headingPositiveLight: base.green900,
  headingPositiveDark: base.green50,
};

const body = {
  bodyAlternativeLight: base.blue700,
  bodyAlternativeDark: base.blue100,
  bodyAttentionLight: base.amber700,
  bodyAttentionDark: base.amber100,
  bodyBrandLight: base.brand700,
  bodyBrandDark: base.brand100,
  bodyNegativeLight: base.red700,
  bodyNegativeDark: base.red100,
  bodyNeutralLight: base.gray700,
  bodyNeutralDark: base.gray100,
  bodyPositiveLight: base.green800,
  bodyPositiveDark: base.green100,
};

const icon = {
  iconAlternativeLight: base.blue600,
  iconAlternativeDark: base.blue200,
  iconAttentionLight: base.amber600,
  iconAttentionDark: base.amber200,
  iconBrandLight: base.brand600,
  iconBrandDark: base.brand200,
  iconNegativeLight: base.red600,
  iconNegativeDark: base.red200,
  iconNeutralLight: base.gray600,
  iconNeutralDark: base.gray200,
  iconPositiveLight: base.green700,
  iconPositiveDark: base.green200,
};

const link = {
  linkInitialLight: base.blue800,
  linkInitialDark: base.blue200,
  linkHoverLight: base.blue900,
  linkHoverDark: base.blue100,
  linkIconInitialLight: base.blue700,
  linkIconInitialDark: base.blue300,
  linkIconHoverLight: base.blue800,
  linkIconHoverDark: base.blue200,
};

const disabled = {
  disabledAlternativeLight: base.blue400,
  disabledAlternativeDark: base.blue400,
  disabledAttentionLight: base.amber400,
  disabledAttentionDark: base.amber400,
  disabledBrandLight: base.brand400,
  disabledBrandDark: base.brand400,
  disabledNegativeLight: base.red400,
  disabledNegativeDark: base.red400,
  disabledNeutralLight: base.gray400,
  disabledNeutralDark: base.gray400,
  disabledPositiveLight: base.green400,
  disabledPositiveDark: base.green400,
};

const hardware = {
  hardwareBgAlternativeLight: base.blue100,
  hardwareBgAlternativeDark: base.blue800,
  hardwareStrokeAlternativeLight: base.blue300,
  hardwareStrokeAlternativeDark: base.blue500,
  hardwareContentAlternativeLight: base.blue800,
  hardwareContentAlternativeDark: base.blue100,

  hardwareBgAttentionLight: base.amber100,
  hardwareBgAttentionDark: base.amber700,
  hardwareStrokeAttentionLight: base.amber400,
  hardwareStrokeAttentionDark: base.amber500,
  hardwareContentAttentionLight: base.amber800,
  hardwareContentAttentionDark: base.amber100,

  hardwareBgBrandLight: base.brand200,
  hardwareBgBrandDark: base.brand600,
  hardwareStrokeBrandLight: base.brand400,
  hardwareStrokeBrandDark: base.brand400,
  hardwareContentBrandLight: base.brand800,
  hardwareContentBrandDark: base.brand50,

  hardwareBgNegativeLight: base.red100,
  hardwareBgNegativeDark: base.red700,
  hardwareStrokeNegativeLight: base.red300,
  hardwareStrokeNegativeDark: base.red400,
  hardwareContentNegativeLight: base.red800,
  hardwareContentNegativeDark: base.red100,

  hardwareBgNeutralLight: base.gray100,
  hardwareBgNeutralDark: base.gray600,
  hardwareStrokeNeutralLight: base.gray200,
  hardwareStrokeNeutralDark: base.gray400,
  hardwareContentNeutralLight: base.gray700,
  hardwareContentNeutralDark: base.gray100,

  hardwareBgPositiveLight: base.green100,
  hardwareBgPositiveDark: base.green800,
  hardwareStrokePositiveLight: base.green500,
  hardwareStrokePositiveDark: base.green600,
  hardwareContentPositiveLight: base.green800,
  hardwareContentPositiveDark: base.green100,

  hardwareBgDisabledLight: base.gray50,
  hardwareBgDisabledDark: base.gray700,
  hardwareStrokeDisabledLight: base.gray100,
  hardwareStrokeDisabledDark: base.gray600,
  hardwareContentDisabledLight: base.gray400,
  hardwareContentDisabledDark: base.gray300,
};

const internal = {
  // Internal only!
  // These colors are intended to be used ONLY for representing data/controls
  // that are only visible to Meter employees. Using them elsewhere will likely
  // result in an undesirable experience for both our customers and employees!
  internalBgLight: '#FFF7ED',
  internalBgDark: '#7A4100',
  internalStrokeLight: '#FDE1C0',
  internalStrokeDark: '#B15F00',
  internalHeadingLight: '#8F4D00',
  internalHeadingDark: '#FEE5C7',
  internalBodyLight: '#AB5B00',
  internalBodyDark: '#F7C58B',
  internalIconLight: '#C67D29',
  internalIconDark: '#D69750',
  internalTokenBgLight: '#F1DFC7',
  internalTokenBgDark: '#9B611D',
};

export const palette = {
  // Inherits
  ...base,
  ...bg,
  ...stroke,
  ...heading,
  ...body,
  ...icon,
  ...link,
  ...disabled,
  ...hardware,
  ...internal,

  // Control strokes
  controlStrokeBaseLight: base.gray600,
  controlStrokeBaseDark: base.gray300,
  controlStrokeBaseErrorLight: base.red600,
  controlStrokeBaseErrorDark: base.red300,
  controlStrokeBaseFocusedLight: base.brand600,
  controlStrokeBaseFocusedDark: base.brand500,

  controlStrokePrimaryLight: base.brand900,
  controlStrokePrimaryDark: base.brand300,
  controlStrokePrimaryFocusedLight: base.brand900,
  controlStrokePrimaryFocusedDark: base.brand300,

  controlStrokeDestructiveLight: base.red900,
  controlStrokeDestructiveDark: base.red300,
  controlStrokeDestructiveFocusedLight: base.brand900,
  controlStrokeDestructiveFocusedDark: base.brand300,

  controlStrokeInternalLight: base.amber600,
  controlStrokeInternalDark: base.amber300,
  controlStrokeInternalErrorLight: base.red600,
  controlStrokeInternalErrorDark: base.red300,
  controlStrokeInternalFocusedLight: base.brand600,
  controlStrokeInternalFocusedDark: base.brand500,

  controlStrokeRaisedLight: '#090B14',
  controlStrokeRaisedDark: base.gray300,
  controlStrokeRaisedFocusedLight: base.brand600,
  controlStrokeRaisedFocusedDark: base.brand300,

  // Controls backgrounds
  controlBgPrimaryInitialLight: base.brand600,
  controlBgPrimaryHoveredLight: base.brand700,
  controlBgPrimaryFocusedLight: base.brand700,
  controlBgPrimaryDisabledLight: base.brand600,

  controlBgPrimaryInitialDark: base.brand600,
  controlBgPrimaryHoveredDark: base.brand500,
  controlBgPrimaryFocusedDark: base.brand500,
  controlBgPrimaryDisabledDark: base.brand600,

  controlBgDestructiveInitialLight: base.red600,
  controlBgDestructiveHoveredLight: base.red700,
  controlBgDestructiveFocusedLight: base.red700,
  controlBgDestructiveDisabledLight: base.red600,

  controlBgDestructiveInitialDark: base.red600,
  controlBgDestructiveHoveredDark: base.red500,
  controlBgDestructiveFocusedDark: base.red500,
  controlBgDestructiveDisabledDark: base.red600,

  // Controls content
  controlContentAccentLight: base.gray600,
  controlContentAccentDark: base.gray100,
  controlContentPlaceholderLight: base.gray500,
  controlContentPlaceholderDark: base.gray300,
  controlContentValueLight: base.gray700,
  controlContentValueDark: base.gray50,
  controlContentInvalidLight: base.red700,
  controlContentInvalidDark: base.red100,

  // Checkered backgrounds
  checkeredAlternativeLight: bg.bgAlternativeLight,
  checkeredAlternativeDark: bg.bgAlternativeDark,
  checkeredAttentionLight: bg.bgAttentionDark,
  checkeredAttentionDark: bg.bgAttentionLight,
  checkeredBrandLight: bg.bgBrandLight,
  checkeredBrandDark: bg.bgBrandDark,
  checkeredNegativeLight: bg.bgNegativeLight,
  checkeredNegativeDark: bg.bgNegativeDark,
  checkeredNeutralLight: bg.bgNeutralLight,
  checkeredNeutralDark: bg.bgNeutralDark,
  checkeredPositiveLight: bg.bgPositiveLight,
  checkeredPositiveDark: bg.bgPositiveDark,

  // Token backgrounds
  tokenBgAlternativeLight: base.blue100,
  tokenBgAlternativeDark: base.blue600,
  tokenBgAttentionLight: base.amber100,
  tokenBgAttentionDark: base.amber600,
  tokenBgBrandLight: base.brand100,
  tokenBgBrandDark: base.brand600,
  tokenBgNegativeLight: base.red100,
  tokenBgNegativeDark: base.red600,
  tokenBgNeutralLight: base.gray100,
  tokenBgNeutralDark: base.gray500,
  tokenBgPositiveLight: base.green100,
  tokenBgPositiveDark: base.green800,

  // Token strokes
  tokenStrokeAlternativeLight: base.blue200,
  tokenStrokeAlternativeDark: base.blue500,
  tokenStrokeAttentionLight: base.amber200,
  tokenStrokeAttentionDark: base.amber500,
  tokenStrokeBrandLight: base.brand200,
  tokenStrokeBrandDark: base.brand500,
  tokenStrokeNegativeLight: base.red200,
  tokenStrokeNegativeDark: base.red500,
  tokenStrokeNeutralLight: base.gray200,
  tokenStrokeNeutralDark: base.gray400,
  tokenStrokePositiveLight: base.green300,
  tokenStrokePositiveDark: base.green600,
};

export type ColorPalette = keyof typeof palette;

/**
 * Sets the opacity of the given CSS color specifier string and returns the new color formatted as an rgba() string.
 * @param specifier
 * @param opacity
 */
export const fade = (specifier: string, opacity: number): string => {
  const c = d3.color(specifier);

  if (c) {
    c.opacity = opacity;
    return c.formatRgb();
  }

  return 'rgba(0,0,0,0)';
};
