/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, SectionHeader } from '@meterup/atto';
import type { AxiosAPIError, QuoteRequest, QuoteRequestUpdateRequest } from '@meterup/connect-api';
import { axios, put } from '@meterup/connect-api';
import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import SidebarLayout from '../../components/Layout/SidebarLayout';
import ConfirmQuoteBlock from '../../components/Quotes/ConfirmQuoteBlock/ConfirmQuoteBlock';
import CheckoutHeaderNavigation from '../../components/QuotesContract/CheckoutHeaderNavigation/CheckoutHeaderNavigation';
import QuotesContractSidebar from '../../components/QuotesContract/QuotesContractSidebar/QuotesContractSidebar';
import RequestSecondaryQuote from '../../components/QuotesContract/RequestSecondaryQuote/RequestSecondaryQuote';
// Hooks
import useQuotes from '../../hooks/useQuotes';
import { route } from '../../routes';

function QuoteRequestsConfirm() {
  // Router
  const navigate = useNavigate();

  // QuoteRequest sid & QuoteRequest fetching
  const { sid } = useParams<{ sid: string }>();
  const {
    data: quoteRequest,
    isLoading: loading,
    isError: failed,
    refetch: reloadQuoteRequest,
  } = useQuery<unknown, AxiosAPIError, QuoteRequest>(
    ['quoteRequest', sid],
    () => axios.get(`v1/connect/quote-requests/${sid}`),
    {
      enabled: !!sid,
    },
  );

  const selectedQuoteSids = useMemo(
    () => (quoteRequest ? quoteRequest.selectedQuotes : []),
    [quoteRequest],
  );

  // Quotes
  const {
    loadingQuotes,
    quotes,
    reloadQuotes,
    enabledQuotes,
    proposedQuotes,
    generatedQuotes,
    selectedQuotes,
    primaryQuote,
    secondaryQuote,
    // @ts-ignore
  } = useQuotes(sid, selectedQuoteSids);

  const [quoteLoadingSid, setQuoteLoadingSid] = useState('');

  const address = quoteRequest?.location?.address?.address1
    ? quoteRequest?.location?.address?.address1
    : '';

  // Request a manual quote
  const onRequestManualQuote = useCallback(
    (quoteSid: string) => {
      setQuoteLoadingSid(quoteSid);
      put(`quote-requests/${sid}/quotes/${quoteSid}`, { status: 'requested' }).then((response) => {
        reloadQuotes &&
          reloadQuotes().then((response) => {
            setQuoteLoadingSid('');
          });
      });
    },
    [selectedQuoteSids],
  );

  const onContactClick = useCallback(() => {
    if (quoteRequest) {
      navigate(route('contact', { sid: quoteRequest.sid }));
    }
  }, [quoteRequest]);

  const onDeselectQuote = useCallback(
    (quoteSid: string) => {
      setQuoteLoadingSid(quoteSid);

      // Selected quotes Ids
      const selectedQuotes = [
        ...selectedQuoteSids.slice(0, selectedQuoteSids.indexOf(quoteSid)),
        ...selectedQuoteSids.slice(selectedQuoteSids.indexOf(quoteSid) + 1),
      ];

      // Data
      const data = { selectedQuotes, selectedQuotesChanged: true };

      // Request
      put<QuoteRequestUpdateRequest, QuoteRequest>(`quote-requests/${sid}`, data).then(
        (response) => {
          reloadQuoteRequest &&
            reloadQuoteRequest().then((response) => {
              setQuoteLoadingSid('');
              reloadQuotes && reloadQuotes();
            });
        },
      );
    },
    [selectedQuoteSids],
  );

  // On render/QuoteRequest loaded
  useEffect(() => {
    if (!loading && !loadingQuotes && quoteRequest && quoteRequest.status == 'contract_requested') {
      navigate(route('completed', { sid }));
    }
  }, [sid, loading, loadingQuotes, quoteRequest]);

  const renderSidebar = () => {
    if (!primaryQuote) {
      return null;
    }
    if (!quoteRequest) {
      return null;
    }

    const buttonWrapperClass = cx(
      'md:rounded-lg',
      'bg-white md:bg-gray-100 md:bg-opacity-30',
      'w-full fixed z-200 left-0 bottom-0 md:relative',
      'border-t border-gray-100 md:border-none',
      'p-4 pt-5 md:p-6 md:pt-6 md:mt-8',
    );

    return (
      <div className="pb-8 md:pb-0">
        <QuotesContractSidebar
          primaryQuote={primaryQuote}
          secondaryQuote={secondaryQuote}
          quoteRequest={quoteRequest}
        />
        <div className={buttonWrapperClass}>
          <Button onClick={onContactClick} size="large" width="100%">
            Set up contract details
          </Button>
          <p className="text-center text-xs md:text-sm mt-2 md:mt-4 text-gray-400">
            We’ll contact you before anything is committed.
          </p>
        </div>
      </div>
    );
  };

  const primaryHeaderEnd = (
    <Button size="small" variant="secondary" onClick={() => navigate(route('results', { sid }))}>
      Change
    </Button>
  );

  const secondaryHeaderEnd = (
    <>
      <Button size="small" variant="secondary" onClick={() => navigate(route('results', { sid }))}>
        Change
      </Button>
      <Button size="small" variant="secondary" onClick={() => onDeselectQuote(secondaryQuote.sid)}>
        Remove
      </Button>
    </>
  );

  if (loading) {
    return null;
  }

  return (
    <SidebarLayout sidebarChildren={renderSidebar()} hideSidebarOnMobile={false} align="top">
      <div className="mx-auto max-w-2xl max-w-screen-xs w-full">
        {/* @ts-ignore */}
        <CheckoutHeaderNavigation quoteSid={sid} />
        {primaryQuote && (
          <div className="mt-8 rounded-md overflow-hidden border border-gray-100">
            <div className="mb-nudge-1px">
              <SectionHeader heading="Primary connection" actions={primaryHeaderEnd} />
            </div>
            <ConfirmQuoteBlock quote={primaryQuote} />
          </div>
        )}

        {secondaryQuote && (
          <div className="mt-8 rounded overflow-hidden">
            <div className="mb-nudge-1px">
              <SectionHeader heading="Secondary connection" actions={secondaryHeaderEnd} />
            </div>
            <ConfirmQuoteBlock quote={secondaryQuote} />
          </div>
        )}

        {quoteRequest && !secondaryQuote && generatedQuotes.length > 2 && (
          <div className="mt-8">
            <RequestSecondaryQuote quoteRequestSid={quoteRequest.sid} />
          </div>
        )}
      </div>
    </SidebarLayout>
  );
}

export default QuoteRequestsConfirm;
