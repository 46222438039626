import React from 'react';

// import { TextInput } from "@meterup/connect-ui";

// Props will be a bunch of onchangeevent

function QuotesOnboardingFeatures() {
  return (
    <div className="text-center mt-5">
      <p className="text-gray-600">
        “When our current provider in Chicago had issues, we needed a solution ASAP. Meter Connect
        priced out our options and prioritized providers that could install fast. We closed a
        contract in a few days and were able to get a solution installed in under two weeks.”
      </p>
      <p className="text-gray-700 mt-6">Gilbert Villegas</p>
      <p className="text-gray-600">IT Manager</p>
      <div className="mt-5">
        <img src="/assets/ImperfectFoodsLogo.svg" className="inline-block" />
      </div>
    </div>
  );
}

export default QuotesOnboardingFeatures;
