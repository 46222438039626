/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useEffect } from 'react';

import { ConnectLogo, Title } from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

// Components
import OnboardingLayout from '../../components/Layout/OnboardingLayout/OnboardingLayout';
import QuotesOnboardingTestimonies from '../../components/Shared/QuotesOnboardingTestimonies/QuotesOnboardingTestimonies';
import SubmitForm from '../../components/Submit/SubmitForm';
// Hooks
import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
import { route } from '../../routes';

function QuoteRequestsSubmit() {
  // Router
  const navigate = useNavigate();

  // Page: Get locally saved form values
  const [, temporaryFormValues] = useFormStorage(REQUEST_QUOTE_FORM_KEY);

  // Page: Redirects to location if none have been provided
  useEffect(() => {
    // Page: Redirect to location if not set
    if (!temporaryFormValues.address) {
      return navigate(route('location'));
    }
  }, [navigate, temporaryFormValues]);

  return (
    <OnboardingLayout sidebarChildren={<QuotesOnboardingTestimonies />}>
      <div className="mx-auto max-w-sm mt-6 max-w-screen-xs w-full">
        <div className="mb-8">
          <ConnectLogo />
        </div>
        <Title>Generate quotes</Title>
        <div className="mt-8">
          <SubmitForm />
        </div>
      </div>
    </OnboardingLayout>
  );
}

export default QuoteRequestsSubmit;
