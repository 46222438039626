import React from 'react';

import { ProgressBar } from '@meterup/atto';
import { ProviderLogo } from '@meterup/connect-ui';

interface ProvidersProgressProps {
  progress: number;
}

function ProvidersProgress({ progress }: ProvidersProgressProps) {
  const providers = [{} as { sid: string; name: string; path: string }];

  return (
    <section className="w-full border border-gray-50 overflow-hidden relative p-4">
      <header className="border-b border-gray-50 pb-2 mb-4">Searching providers</header>
      <div>
        <div className="grid grid-cols-6 gap-6 items-center">
          {providers.map((provider) => (
            <div key={provider.sid}>
              <ProviderLogo name={provider.name} path={provider.path} />
            </div>
          ))}
        </div>
        <p className="text font-medium mb-2 mt-4">Generating quotes...</p>
        <ProgressBar progressPercentage={progress} />
        <p className="text-sm text-gray-300 mt-2">Estimated time: Less than 1 minute</p>
      </div>
    </section>
  );
}

export default ProvidersProgress;
