/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useEffect, useMemo, useState } from 'react';

import { Quote } from '@meterup/connect-api';

// A hooks to keep quotes logic / filtering
export default function useMonthlyFeeFilter(quotes: Quote[]) {
  // Monthly fee - All
  const monthlyFeeCents = useMemo(
    () => quotes.map((quote: Quote) => quote.monthlyFeeCents),
    [quotes],
  );

  // Monthly fee - Minimum
  const minMonthlyFee = useMemo(
    () => (quotes.length ? Math.max(Math.floor(Math.min(...monthlyFeeCents) / 100 - 100), 0) : 0),
    [quotes],
  );

  // Monthly fee - Maximum
  const maxMonthlyFee = useMemo(
    () => (quotes.length ? Math.ceil(Math.max(...monthlyFeeCents) / 100 + 100) : 100),
    [quotes],
  );

  // Monthly fee - Current
  const [currentMonthlyFee, setCurrentMonthlyFee] = useState(maxMonthlyFee);

  // Monthly fee - setstate
  useEffect(() => {
    setCurrentMonthlyFee(maxMonthlyFee);
  }, [maxMonthlyFee]);

  // Monthly fee - User event change
  const onMonthlyPriceChange = (valueStr: string) => {
    const value = Number(valueStr);
    setCurrentMonthlyFee(value > maxMonthlyFee ? maxMonthlyFee : value);
  };

  const resetMonthlyPriceFilter = () => {
    setCurrentMonthlyFee(maxMonthlyFee);
  };

  return {
    minMonthlyFee,
    maxMonthlyFee,
    currentMonthlyFee,
    onMonthlyPriceChange,
    resetMonthlyPriceFilter,
  };
}
