/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
// Libraries
import React, { useEffect, useMemo, useState } from 'react';

// Type
import { Quote } from '@meterup/connect-api';
// Components
import { FormattedSpeed } from '@meterup/connect-ui';

import { formatBandwidth } from '../../../utils/formatBandwidth';
// Utils
import { pluralize } from '../../../utils/String.utils';
// Components
import QuoteBlock from '../QuoteBlock/QuoteBlock';
import QuotesListHeader from '../QuotesListHeader/QuotesListHeader';
import QuotesListSection from '../QuotesListSection/QuotesListSection';

// TODO: get Interface from @api
interface QuotesListProps {
  quotes: Quote[];
  selectedQuotes: Quote[];
  recommendedQuotes: Quote[];
  shouldGroupByBandwidth: boolean;
  selectedQuoteSids: string[];
  quoteLoadingSid: string;
  onSelectQuote: (sid: string) => void;
  onDeselectQuote: (sid: string) => void;
  onPinQuote: (sid: string) => void;
  onUnpinQuote: (sid: string) => void;
}

function QuotesList({
  quotes,
  selectedQuotes,
  recommendedQuotes,
  selectedQuoteSids,
  quoteLoadingSid,
  shouldGroupByBandwidth,
  onSelectQuote,
  onDeselectQuote,
  onPinQuote,
  onUnpinQuote,
}: QuotesListProps) {
  // Quotes that aren't selected
  const unselectedQuotes = useMemo(
    () => quotes.filter((n) => !selectedQuotes.includes(n)),
    [quotes, selectedQuotes],
  );

  // Quotes that aren't selected but are recommended
  const unselectedRecommendedQuotes = useMemo(
    () => unselectedQuotes.filter((n) => recommendedQuotes.includes(n)),
    [quotes, unselectedQuotes, recommendedQuotes],
  );

  // Find all other quotes (not selected or recommented)
  const allOtherQuotes = useMemo(
    () => quotes.filter((n) => !selectedQuotes.includes(n) && !recommendedQuotes.includes(n)),
    [quotes, selectedQuotes, recommendedQuotes],
  );

  // Group all other quotes by download speed
  const allOtherQuotesByDownloadKbps = useMemo(
    () =>
      allOtherQuotes.reduce(
        (acc, quote) => {
          const downloadKbps = formatBandwidth(quote.downloadKbps);
          const existingQuotes = acc[downloadKbps] || [];
          return {
            ...acc,
            [downloadKbps]: [...existingQuotes, quote],
          };
        },
        {} as Record<string, Quote[]>,
      ),
    [quotes, selectedQuotes, recommendedQuotes],
  );

  // Util: get an array of all download speeds
  const allOtherQuotesDownloadKbps = useMemo(
    () => Object.keys(allOtherQuotesByDownloadKbps).sort((a, b) => parseInt(a) - parseInt(b)),
    [quotes, selectedQuotes, recommendedQuotes],
  );

  // State: which section is open
  const [visibleSelectedQuotes, setVisibleSelectedQuotes] = useState(selectedQuotes.length != 0);
  const [visibleRecommendedQuotes, setVisibleRecommendedQuotes] = useState(
    unselectedRecommendedQuotes.length != 0,
  );
  const [visibleDownloadKbps, setVisibleDownloadKbps] = useState(allOtherQuotesDownloadKbps);
  const [visibleAllOtherQuotes, setVisibleAllOtherQuotes] = useState(allOtherQuotes.length != 0);

  const onDownloadKbpsClick = (downloadKbps: number) => {
    if (visibleDownloadKbps.includes(downloadKbps.toString())) {
      setVisibleDownloadKbps(visibleDownloadKbps.filter((d) => d !== downloadKbps.toString()));
    } else {
      setVisibleDownloadKbps([...visibleDownloadKbps, downloadKbps.toString()]);
    }
  };

  useEffect(() => {
    setVisibleSelectedQuotes(selectedQuotes.length != 0);
  }, [selectedQuotes]);

  useEffect(() => {
    setVisibleRecommendedQuotes(unselectedRecommendedQuotes.length != 0);
  }, [unselectedRecommendedQuotes]);

  useEffect(() => {
    setVisibleDownloadKbps(allOtherQuotesDownloadKbps);
  }, [quotes]);

  useEffect(() => {
    setVisibleAllOtherQuotes(allOtherQuotes.length != 0);
  }, [allOtherQuotes]);

  const bandwidthSectionHeader = (downloadKbps: number) => (
    <QuotesListHeader
      onClick={() => onDownloadKbpsClick(downloadKbps)}
      open={visibleDownloadKbps.includes(downloadKbps.toString())}
      title={<FormattedSpeed mbps={formatBandwidth(downloadKbps)} />}
    />
  );

  const selectedQuotesSectionHeader = () => (
    <QuotesListHeader
      onClick={() => setVisibleSelectedQuotes(!visibleSelectedQuotes)}
      open={visibleSelectedQuotes}
      title={pluralize(selectedQuotes.length, 'Selected Quote')}
    />
  );

  const recommendedQuotesSectionHeader = () => (
    <QuotesListHeader
      onClick={() => setVisibleRecommendedQuotes(!visibleRecommendedQuotes)}
      open={visibleRecommendedQuotes}
      title={pluralize(unselectedRecommendedQuotes.length, 'Pinned Quote')}
    />
  );

  const allOtherQuotesSectionHeader = () => (
    <QuotesListHeader
      onClick={() => setVisibleAllOtherQuotes(!visibleAllOtherQuotes)}
      open={visibleAllOtherQuotes}
      title={pluralize(allOtherQuotes.length, 'Quote')}
    />
  );

  const renderQuotes = (quotes: Quote[]) => (
    <div className="mb-12 mt-2 border-t border-gray-100">
      {quotes.map((quote: Quote) => (
        <li key={quote.sid} className="mb-1 overflow-hidden ">
          {renderQuote(quote)}
        </li>
      ))}
    </div>
  );

  const renderQuote = (quote: Quote) => (
    <QuoteBlock
      quote={quote}
      selected={selectedQuoteSids.includes(quote.sid)}
      disabledSelect={selectedQuoteSids.length == 2}
      loading={quoteLoadingSid === quote.sid}
      onPinQuote={onPinQuote}
      onUnpinQuote={onUnpinQuote}
      onClick={() =>
        selectedQuoteSids.includes(quote.sid)
          ? onDeselectQuote(quote.sid)
          : onSelectQuote(quote.sid)
      }
    />
  );

  // In order we show:
  // 1) Selected Quotes
  // 2) Pinned Quotes (except selected ones)
  // 3) All other quotes, sorted by bandwidth
  return (
    <ol>
      {selectedQuotes.length != 0 && (
        <li className="mb-6">
          {selectedQuotesSectionHeader()}
          <QuotesListSection open={visibleSelectedQuotes}>
            {renderQuotes(selectedQuotes)}
          </QuotesListSection>
        </li>
      )}

      {unselectedRecommendedQuotes.length != 0 && (
        <li className="mb-6">
          {recommendedQuotesSectionHeader()}
          <QuotesListSection open={visibleRecommendedQuotes}>
            {renderQuotes(unselectedRecommendedQuotes)}
          </QuotesListSection>
        </li>
      )}

      {shouldGroupByBandwidth ? (
        allOtherQuotesDownloadKbps.map((downloadKbps) => {
          const open = visibleDownloadKbps.includes(downloadKbps.toString());
          return (
            <li key={downloadKbps} className="mb-6">
              {bandwidthSectionHeader(parseInt(downloadKbps))}
              <QuotesListSection open={open}>
                {renderQuotes(allOtherQuotesByDownloadKbps[downloadKbps])}
              </QuotesListSection>
            </li>
          );
        })
      ) : (
        <li className="mb-6">
          {allOtherQuotesSectionHeader()}
          <QuotesListSection open={visibleAllOtherQuotes}>
            {renderQuotes(allOtherQuotes)}
          </QuotesListSection>
        </li>
      )}
    </ol>
  );
}

export default QuotesList;
