import React from 'react';

// Props will be a bunch of onchangeevent

function InstantQuotesResultsEmpty() {
  return (
    <section className="bg-gray-50 p-4 rounded-md">
      <strong className="font-medium">Sorry, we weren’t able to find any instant quotes</strong>
      <p className="font-regular text-gray-600 text-sm">
        The below providers are servicing your location, but have not established instant quotes.
        can still request them and we’ll get the prices for you.
      </p>
    </section>
  );
}

export default InstantQuotesResultsEmpty;
