import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { PolymorphicRef } from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { ControlSizeProvider } from '../../common/control_size';
import { FocusRing } from '../../common/focus_rings';
import { sizing } from '../../common/sizing';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Text } from '../../text/Text';

export type AlertRelation = 'stacked' | 'standalone';
export type AlertVariant =
  | 'alternative'
  | 'attention'
  | 'brand'
  | 'negative'
  | 'neutral'
  | 'positive';

export type AlertProps = {
  /**
   * The more detailed information regarding the notice, no value disables no copy.
   */
  copy?: React.ReactNode;
  /**
   * Provide an accessible label for the dismiss action of the alert.
   */
  dismissLabel?: string;
  /**
   * Pass in the onClick event for dismissing the alert.
   */
  dismissOnClick?: (event: any) => void;
  /**
   * A simple heading for the notice, no value disables no heading.
   */
  heading?: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Pass in an ID to make sure the Alert is identifiable.
   */
  id?: React.ReactNode;
  internal?: boolean;
  /**
   * Set whether the alert has been dismissed.
   */
  isDismissed?: boolean;
  /**
   * Set whether the radius should exist or be squared off.
   */
  relation?: AlertRelation;
  /**
   * Actions to be displayed at the bottom of the alert.
   */
  trailingButtons?: React.ReactNode;
  /**
   * Actions to be displayed at the top-right of the alert.
   */
  shoulderButtons?: React.ReactNode;
  /**
   * Determine if the component should be the full or inline type.
   */
  type?: 'full' | 'inline';
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: AlertVariant;
};

const AlertContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  background: '$$backgroundColor',
  strokeAll: '$$strokeColor',
  variants: {
    isDismissed: {
      true: {
        display: 'none',
      },
    },
    variant: {
      alternative: {
        zIndex: 3,
        $$strokeColor: colors.strokeAlternativeLight,
        $$backgroundColor: colors.blue50,
        $$headingColor: colors.blue800,
        $$copyColor: colors.blue700,
        $$iconColor: colors.blue700,
        [darkThemeSelector]: {
          $$strokeColor: colors.strokeAlternativeDark,
          $$backgroundColor: colors.blue900,
          $$headingColor: colors.blue50,
          $$copyColor: colors.blue100,
          $$iconColor: colors.blue100,
        },
      },
      brand: {
        zIndex: 3,
        $$strokeColor: colors.strokeBrandLight,
        $$backgroundColor: colors.brand50,
        $$headingColor: colors.brand800,
        $$copyColor: colors.brand700,
        $$iconColor: colors.brand700,
        [darkThemeSelector]: {
          $$strokeColor: colors.strokeBrandDark,
          $$backgroundColor: colors.brand900,
          $$headingColor: colors.brand50,
          $$copyColor: colors.brand100,
          $$iconColor: colors.brand100,
        },
      },
      negative: {
        zIndex: 3,
        $$strokeColor: colors.strokeNegativeLight,
        $$backgroundColor: colors.red50,
        $$headingColor: colors.red800,
        $$copyColor: colors.red700,
        $$iconColor: colors.red700,
        [darkThemeSelector]: {
          $$strokeColor: colors.strokeNegativeDark,
          $$backgroundColor: colors.red900,
          $$headingColor: colors.red50,
          $$copyColor: colors.red100,
          $$iconColor: colors.red100,
        },
      },
      attention: {
        zIndex: 3,
        $$strokeColor: colors.strokeAttentionLight,
        $$backgroundColor: colors.bgAttentionLight,
        $$headingColor: colors.headingAttentionLight,
        $$copyColor: colors.bodyAttentionLight,
        $$iconColor: colors.amber700,
        [darkThemeSelector]: {
          $$strokeColor: colors.strokeAttentionDark,
          $$backgroundColor: colors.bgAttentionDark,
          $$headingColor: colors.headingAttentionDark,
          $$copyColor: colors.bodyAttentionDark,
          $$iconColor: colors.amber100,
        },
      },
      neutral: {
        zIndex: 1,
        $$strokeColor: colors.strokeNeutralLight,
        $$backgroundColor: colors.gray50,
        $$headingColor: colors.gray800,
        $$copyColor: colors.gray700,
        $$iconColor: colors.gray900,
        [darkThemeSelector]: {
          $$strokeColor: colors.strokeNeutralDark,
          $$backgroundColor: colors.gray900,
          $$headingColor: colors.gray50,
          $$copyColor: colors.gray100,
          $$iconColor: colors.gray100,
        },
      },
      positive: {
        zIndex: 3,
        $$strokeColor: colors.strokePositiveLight,
        $$backgroundColor: colors.green50,
        $$headingColor: colors.green900,
        $$copyColor: colors.green800,
        $$iconColor: colors.green800,
        [darkThemeSelector]: {
          $$strokeColor: colors.strokePositiveDark,
          $$backgroundColor: colors.green900,
          $$headingColor: colors.green50,
          $$copyColor: colors.green100,
          $$iconColor: colors.green100,
        },
      },
    },
    internal: {
      true: {},
      false: {},
    },
    type: {
      full: {
        gap: '$8',
        padding: sizing.contentSquish,
      },
      inline: {
        gap: '$6',
        padding: `$6 ${sizing.contentSides}`,
      },
    },
    relation: {
      standalone: {},
      stacked: {},
    },
    id: {},
  },
  compoundVariants: [
    {
      internal: true,
      css: {
        zIndex: 3,
        $$strokeColor: colors.internalStrokeLight,
        $$backgroundColor: colors.internalBgLight,
        $$headingColor: colors.internalHeadingLight,
        $$copyColor: colors.internalBodyLight,
        $$iconColor: colors.internalIconLight,
        [darkThemeSelector]: {
          $$strokeColor: colors.internalStrokeDark,
          $$backgroundColor: colors.internalBgDark,
          $$headingColor: colors.internalHeadingDark,
          $$copyColor: colors.internalBodyDark,
          $$iconColor: colors.internalIconDark,
        },
      },
    },
    {
      relation: 'standalone',
      type: 'full',
      css: {
        borderRadius: '$8',
      },
    },
    {
      relation: 'standalone',
      type: 'inline',
      css: {
        borderRadius: '$6',
      },
    },
  ],
});

const AlertContent = styled('div', {
  vStack: 4,
  alignItems: 'flex-start',
  width: '100%',
});

const AlertIcon = styled(Icon, {
  color: '$$iconColor',
  variants: {
    type: {
      full: {
        width: '$16',
        height: '$16',

        '@notDesktop': {
          marginTop: '$4',
        },

        '@desktop': {
          marginTop: '$2',
        },
      },
      inline: {
        width: '$12',
        height: '$12',

        '@notDesktop': {
          marginTop: '$4',
        },

        '@desktop': {
          marginTop: '$2',
        },
      },
    },
  },
});

const AlertHeading = styled(Text, {
  fontWeight: fontWeights.bold,
  color: '$$headingColor',
  wordBreak: 'break-word',
  variants: {
    type: {
      full: {
        '@notDesktop': {
          fontSize: '$16',
          lineHeight: '$24',
        },

        '@desktop': {
          fontSize: '$14',
          lineHeight: '$20',
        },
      },
      inline: {
        '@notDesktop': {
          fontSize: '$14',
          lineHeight: '$20',
        },

        '@desktop': {
          fontSize: '$12',
          lineHeight: '$16',
        },
      },
    },
  },
});

const AlertCopy = styled(Text, {
  color: '$$copyColor',
  wordBreak: 'break-word',
  variants: {
    type: {
      full: {
        '@notDesktop': {
          fontSize: '$16',
          lineHeight: '$24',
        },

        '@desktop': {
          fontSize: '$14',
          lineHeight: '$20',
        },
      },
      inline: {
        '@notDesktop': {
          fontSize: '$14',
          lineHeight: '$20',
        },

        '@desktop': {
          fontSize: '$12',
          lineHeight: '$16',
        },
      },
    },
  },
});

const AlertTrailingButtons = styled(ControlGroup, {
  width: '100%',
  paddingTop: '$4',
});

const AlertActions = styled('div', {
  hStack: '$6',
});

const AlertShoulderButtons = styled(ControlGroup, {
  marginTop: '-$2',
  marginBottom: '-$2',
});

const AlertDismissIcon = styled(Icon, {
  '@notDesktop': {
    width: '$12',
    height: '$12',
  },

  '@desktop': {
    width: '$10',
    height: '$10',
  },
});

const AlertDismissContainer = styled('button', FocusRing, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$$backgroundColor',
  color: '$$iconColor',
  strokeAll: colors.transparent,

  [darkThemeSelector]: {
    backgroundColor: '$$backgroundColor',
    color: '$$iconColor',
  },

  '&:hover': {
    backgroundColor: '$$backgroundColor',
    color: '$$headingColor',
    strokeAll: '$$strokeColor',

    [darkThemeSelector]: {
      backgroundColor: '$$backgroundColor',
      color: '$$headingColor',
      strokeAll: '$$strokeColor',
    },
  },

  '@notDesktop': {
    borderRadius: '$6',
    width: '$28',
    height: '$28',
  },

  '@desktop': {
    borderRadius: '$4',
    width: '$20',
    height: '$20',
  },
});

export interface AlertDismissProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: any) => void;
}

export const AlertDismiss = React.forwardRef<HTMLButtonElement, AlertDismissProps>(
  (props: AlertDismissProps, ref) => (
    <AlertDismissContainer type="button" {...props} ref={ref}>
      <AlertDismissIcon icon="cross" />
    </AlertDismissContainer>
  ),
);

export const Alert = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      copy,
      dismissLabel = 'Dismiss',
      dismissOnClick,
      heading,
      icon,
      id,
      internal,
      isDismissed,
      relation = 'standalone',
      shoulderButtons,
      trailingButtons,
      type = 'full',
      variant = 'neutral',
      ...remaining
    }: AlertProps,
    ref: PolymorphicRef<Tag>,
  ) => (
    <AlertContainer
      isDismissed={isDismissed}
      variant={variant}
      relation={relation}
      type={type}
      internal={internal}
      ref={ref}
      {...remaining}
    >
      {icon && <AlertIcon icon={icon} type={type} />}
      {(heading || copy) && (
        <AlertContent>
          {heading && <AlertHeading type={type}>{heading}</AlertHeading>}
          {copy && <AlertCopy type={type}>{copy}</AlertCopy>}
          {trailingButtons && (
            <AlertTrailingButtons relation="separate">
              <ControlSizeProvider value="small">{trailingButtons}</ControlSizeProvider>
            </AlertTrailingButtons>
          )}
        </AlertContent>
      )}
      <AlertActions>
        <AlertShoulderButtons relation="separate">
          <ControlSizeProvider value="small">{shoulderButtons} </ControlSizeProvider>
        </AlertShoulderButtons>
        {dismissOnClick && <AlertDismiss aria-label={dismissLabel} onClick={dismissOnClick} />}
      </AlertActions>
    </AlertContainer>
  ),
);
