/* eslint-disable no-empty */
/* eslint-disable react/button-has-type */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useEffect, useMemo, useState } from 'react';

import { Quote, QuoteRequest } from '@meterup/connect-api';
import { sortBy } from 'underscore';

import { onlyUnique } from '../utils/Array.utils';
import { formatBandwidth } from '../utils/formatBandwidth';

export default function useDownloadMbpsFilter(
  quotes: Quote[],
  quoteRequest: QuoteRequest | undefined,
) {
  // Sort by price, group by downloadKbps
  const quotesByDownloadMbps = useMemo(() => {
    const sortedQuotes = sortBy(quotes, (quote) => quote.monthlyFeeCents);
    return sortedQuotes.reduce(
      (acc, quote) => {
        const downloadKbps = formatBandwidth(quote.downloadKbps);
        const existingQuotes = acc[downloadKbps] || [];
        return {
          ...acc,
          [downloadKbps]: [...existingQuotes, quote],
        };
      },
      {} as Record<string, Quote[]>,
    );
  }, [quotes]);

  // // Download bandwidth - All
  const allDownloadMbps = useMemo(
    () =>
      quotes
        .map((quote: Quote) => formatBandwidth(quote.downloadKbps))
        .filter(onlyUnique)
        .sort((a, b) => (a < b ? -1 : 1)),
    [quotes.length],
  );

  // Download bandwidth - Current
  const [currentDownloadMbps, setCurrentDownloadMbps] = useState(allDownloadMbps);

  // Download bandwidth - setstate
  useEffect(() => {
    setCurrentDownloadMbps(allDownloadMbps);
  }, [allDownloadMbps]);

  // Download bandwidth - User event change
  const onDownloadMbpsChange = (downloadMbps: number, checked: boolean) => {
    let newDownloadMbps = currentDownloadMbps;
    if (checked) {
      newDownloadMbps.push(downloadMbps);
    } else {
      newDownloadMbps = newDownloadMbps.filter((item) => item !== downloadMbps);
    }
    setCurrentDownloadMbps(newDownloadMbps.filter(onlyUnique));
  };

  const resetDownloadMbpsFilter = () => {
    setCurrentDownloadMbps(allDownloadMbps);
  };

  return {
    quotesByDownloadMbps,
    allDownloadMbps,
    currentDownloadMbps,
    onDownloadMbpsChange,
    resetDownloadMbpsFilter,
  };
}
