/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useEffect } from 'react';

import { Body, Button, ConnectLogo, FieldContainer, Icon, TextInput, Title } from '@meterup/atto';
import { useNavigate, useParams } from 'react-router-dom';

import ContractForm from '../../components/Contract/ContractForm';
// Components
import OnboardingLayout from '../../components/Layout/OnboardingLayout/OnboardingLayout';
import QuotesOnboardingFeatures from '../../components/Shared/QuotesOnboardingFeatures/QuotesOnboardingFeatures';
// Hooks
import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
import { route } from '../../routes';

function QuoteRequestsContract() {
  // Router
  const navigate = useNavigate();

  // Page: get locally saved form values
  const [, temporaryFormValues] = useFormStorage(REQUEST_QUOTE_FORM_KEY);

  const { sid } = useParams<{ sid: string }>();

  // Page: redirect to location if not set
  useEffect(() => {
    if (!temporaryFormValues.address) {
      return navigate(route('location', { sid }));
    }
  }, [temporaryFormValues]);

  const onClickLocationEdit = () => navigate(route('location', { sid }));

  return (
    <OnboardingLayout sidebarChildren={<QuotesOnboardingFeatures />}>
      <div className="mx-auto max-w-sm mt-6 max-w-screen-xs w-full">
        <div className="mb-8">
          <ConnectLogo />
        </div>
        <FieldContainer>
          <div className="p-4 rounded-t-md mb-nudge-2px">
            <div className="relative">
              <TextInput
                value={temporaryFormValues.address}
                disabled
                controlSize="large"
                icon="location"
              />
              <div className="inline-block absolute top-1 right-2">
                {/* @ts-ignore */}
                <Button
                  icon="pencil"
                  size="small"
                  variant="secondary"
                  onClick={onClickLocationEdit}
                />
              </div>
            </div>
          </div>

          <div className=" p-4 rounded-b-md">
            <Body>What best describes your situation?</Body>
            <div className="mt-2">
              {/* @ts-ignore */}
              <ContractForm sid={sid} />
            </div>
          </div>
        </FieldContainer>

        <div className="mt-8" />
      </div>
    </OnboardingLayout>
  );
}

export default QuoteRequestsContract;
