import { useCallback, useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';

import { getPortalTld, getPortalUrl } from '../config';
import { PortalCookie, PortalOrigin } from '../enums';

interface Cookie {
  version: number;
  username: string;
  expires: string;
  companies?: string[];
}

export const getCookieName = (via: PortalOrigin) =>
  via === PortalOrigin.ConnectAdmin ? PortalCookie.ConnectAdmin : PortalCookie.Connect;

const cookieHasExpired = (cookie: Cookie) => Date.parse(cookie.expires) < Date.now();

export function redirectToPortal(via: PortalOrigin, path?: string) {
  const search = new URLSearchParams();
  search.set('via', via);
  if (path) {
    search.set('path', path);
  }
  window.location.href = `${getPortalUrl(window.location)}?${search.toString()}`;
}

export default function useAuthenticatedUser(
  via: PortalOrigin = PortalOrigin.ConnectAdmin,
  path?: string,
): [boolean, Cookie, () => void] {
  const [cookies] = useCookies([getCookieName(via)]);
  const cookie = useMemo<Cookie>(() => {
    const cookieInt = cookies[getCookieName(via)];
    if (cookieInt) {
      return JSON.parse(atob(cookieInt));
    }

    return undefined;
  }, [via, cookies]);

  const needsRedirect = useMemo(() => !cookie || cookieHasExpired(cookie), [cookie]);

  const onLogout = useCallback(() => {
    document.cookie = `${getCookieName(via)}=; path=/; domain=.meter.${getPortalTld(
      document.location,
    )}; expires=${new Date(0).toUTCString()};`;
  }, [via]);

  useEffect(() => {
    if (needsRedirect) {
      redirectToPortal(via, path);
    }
  }, [via, needsRedirect, path]);

  return [!needsRedirect, cookie, onLogout];
}
