import { colors, darkThemeSelector, shadows } from '../../stitches.config';
import { selectors } from '../shared/styles';

export const labelColor = '$$labelColor';
export const iconColor = '$$iconColor';
export const loadingIconColor = '$$loadingIconColor';

export const destructiveEnabledStyles = {
  backgroundColor: colors.controlBgDestructiveInitialLight,
  boxShadow: shadows.controlDestructiveInitialLight,
  [labelColor]: colors.red50,
  [iconColor]: colors.red100,

  [darkThemeSelector]: {
    backgroundColor: colors.controlBgDestructiveInitialDark,
    boxShadow: shadows.controlDestructiveInitialDark,
  },

  [selectors.hover]: {
    backgroundColor: colors.controlBgDestructiveHoveredLight,
    boxShadow: shadows.controlDestructiveHoveredLight,
    [labelColor]: colors.white,
    [iconColor]: colors.red50,

    [darkThemeSelector]: {
      backgroundColor: colors.controlBgDestructiveHoveredDark,
      boxShadow: shadows.controlDestructiveHoveredDark,
    },
  },

  [selectors.focus]: {
    backgroundColor: colors.controlBgDestructiveFocusedLight,
    boxShadow: shadows.controlDestructiveFocusedLight,

    [darkThemeSelector]: {
      backgroundColor: colors.controlBgDestructiveFocusedDark,
      boxShadow: shadows.controlDestructiveFocusedDark,
    },
  },
};

export const destructiveDisabledStyles = {
  backgroundColor: colors.controlBgDestructiveDisabledLight,
  boxShadow: shadows.controlDestructiveDisabledLight,
  [labelColor]: colors.red200,
  [iconColor]: colors.red300,
  [loadingIconColor]: colors.red50,

  [darkThemeSelector]: {
    boxShadow: shadows.controlDestructiveDisabledDark,
  },
};

export const primaryEnabledStyles = {
  backgroundColor: colors.controlBgPrimaryInitialLight,
  boxShadow: shadows.controlPrimaryInitialLight,
  [labelColor]: colors.brand50,
  [iconColor]: colors.brand100,

  [darkThemeSelector]: {
    backgroundColor: colors.controlBgPrimaryInitialDark,
    boxShadow: shadows.controlPrimaryInitialDark,
  },

  [selectors.hover]: {
    backgroundColor: colors.controlBgPrimaryHoveredLight,
    boxShadow: shadows.controlPrimaryHoveredLight,
    [labelColor]: colors.white,
    [iconColor]: colors.brand50,

    [darkThemeSelector]: {
      backgroundColor: colors.controlBgPrimaryHoveredDark,
      boxShadow: shadows.controlPrimaryHoveredDark,
    },
  },

  [selectors.focus]: {
    backgroundColor: colors.controlBgPrimaryFocusedLight,
    boxShadow: shadows.controlPrimaryFocusedLight,

    [darkThemeSelector]: {
      backgroundColor: colors.controlBgPrimaryFocusedDark,
      boxShadow: shadows.controlPrimaryFocusedDark,
    },
  },
};

export const primaryDisabledStyles = {
  backgroundColor: colors.controlBgPrimaryDisabledLight,
  boxShadow: shadows.controlPrimaryDisabledLight,
  [labelColor]: colors.brand200,
  [iconColor]: colors.brand300,
  [loadingIconColor]: colors.brand50,

  [darkThemeSelector]: {
    backgroundColor: colors.controlBgPrimaryDisabledDark,
    boxShadow: shadows.controlPrimaryDisabledDark,
  },
};

export const secondaryEnabledStyles = {
  [labelColor]: colors.gray600,
  [iconColor]: colors.gray500,
  boxShadow: shadows.controlSecondaryInitialLight,

  [darkThemeSelector]: {
    [labelColor]: colors.gray50,
    [iconColor]: colors.gray100,
    boxShadow: shadows.controlSecondaryInitialDark,
  },

  [selectors.hover]: {
    [labelColor]: colors.gray800,
    [iconColor]: colors.gray700,
    boxShadow: shadows.controlSecondaryHoveredLight,

    [darkThemeSelector]: {
      [labelColor]: colors.white,
      [iconColor]: colors.gray50,
      boxShadow: shadows.controlSecondaryHoveredDark,
    },
  },

  [selectors.focus]: {
    boxShadow: shadows.controlSecondaryFocusedLight,

    [darkThemeSelector]: {
      boxShadow: shadows.controlSecondaryFocusedDark,
    },
  },
};

export const secondaryDisabledStyles = {
  [labelColor]: colors.gray400,
  [iconColor]: colors.gray300,
  [loadingIconColor]: colors.gray500,
  boxShadow: shadows.controlSecondaryDisabledLight,

  [darkThemeSelector]: {
    [labelColor]: colors.gray200,
    [iconColor]: colors.gray300,
    [loadingIconColor]: colors.gray200,
    boxShadow: shadows.controlSecondaryDisabledDark,
  },
};

export const internalEnabledStyles = {
  [labelColor]: colors.internalBodyLight,
  [iconColor]: colors.internalIconLight,
  boxShadow: shadows.controlInternalInitialLight,

  [darkThemeSelector]: {
    [labelColor]: colors.internalBodyDark,
    [iconColor]: colors.internalIconDark,
    boxShadow: shadows.controlInternalInitialDark,
  },

  [selectors.hover]: {
    [labelColor]: colors.internalHeadingLight,
    [iconColor]: colors.internalBodyLight,
    boxShadow: shadows.controlInternalHoveredLight,

    [darkThemeSelector]: {
      [labelColor]: colors.internalHeadingDark,
      [iconColor]: colors.internalBodyDark,
      boxShadow: shadows.controlInternalHoveredDark,
    },
  },

  [selectors.focus]: {
    boxShadow: shadows.controlInternalFocusedLight,

    [darkThemeSelector]: {
      boxShadow: shadows.controlInternalFocusedDark,
    },
  },
};

export const internalDisabledStyles = {
  [labelColor]: colors.internalBodyLight,
  [iconColor]: colors.internalIconLight,
  [loadingIconColor]: colors.internalIconLight,
  boxShadow: shadows.controlInternalDisabledLight,

  [darkThemeSelector]: {
    [labelColor]: colors.internalBodyDark,
    [iconColor]: colors.internalIconDark,
    [loadingIconColor]: colors.internalIconDark,
    boxShadow: shadows.controlInternalDisabledDark,
  },
};
