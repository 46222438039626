import React from 'react';

import { Body, Caption, CheckboxInputWithLabel, FormattedMoney } from '@meterup/atto';
import { Quote } from '@meterup/connect-api';

// Props will be a bunch of onchangeevent
interface FilterBandwidthProps {
  quotesByDownloadMbps: { [provider: string]: Quote[] };
  allDownloadMbps: number[];
  currentDownloadMbps: number[];
  onDownloadMbpsChange: (downloadMbps: number, checked: boolean) => void;
}

export default function FilterBandwidth({
  quotesByDownloadMbps,
  allDownloadMbps,
  currentDownloadMbps,
  onDownloadMbpsChange,
}: FilterBandwidthProps) {
  return (
    <section>
      <Caption>Available bandwidths</Caption>
      <div className="mt-2">
        {allDownloadMbps.map((downloadMbps) => (
          <div className="mt-2" key={downloadMbps}>
            <CheckboxInputWithLabel
              checked={currentDownloadMbps.includes(downloadMbps)}
              onChange={(checked: boolean) => {
                onDownloadMbpsChange(downloadMbps, checked);
              }}
              name={`downloadMbps-${downloadMbps}`}
            >
              <div
                className="w-full flex justify-between"
                onClick={() => {
                  onDownloadMbpsChange(downloadMbps, !currentDownloadMbps.includes(downloadMbps));
                }}
              >
                <Body weight="bold">{`${downloadMbps} Mbps`}</Body>
                <Body variant="tabular">
                  <FormattedMoney
                    amount={quotesByDownloadMbps[downloadMbps][0].monthlyFeeCents / 100}
                  />
                </Body>
              </div>
            </CheckboxInputWithLabel>
          </div>
        ))}
      </div>
    </section>
  );
}
