/* eslint-disable eqeqeq */
/* eslint-disable import/no-cycle */
import React from 'react';

import { Icon, Link, Subheading } from '@meterup/atto';
import { useLocation } from 'react-router-dom';

import useRouter from '../../../hooks/useRouter';
import { route } from '../../../routes';

interface CheckoutHeaderNavigationProps {
  quoteSid: string;
}

function CheckoutHeaderNavigation({ quoteSid }: CheckoutHeaderNavigationProps) {
  const { handleOnClick } = useRouter();
  const location = useLocation();

  const resultsLink = route('results', { sid: quoteSid });
  const confirmLink = route('confirm', { sid: quoteSid });
  const contactLink = route('contact', { sid: quoteSid });

  return (
    <nav>
      <Subheading>Review your selection</Subheading>
      <ul className="text-sm mt-1 flex items-center">
        <li>
          <Link href={resultsLink} onClick={handleOnClick} muted={location.pathname == resultsLink}>
            Quotes
          </Link>
        </li>
        <li className="text-xs text-gray-600 mx-2 my-1">
          <Icon icon="chevron-right" size={8} />
        </li>
        <li>
          <Link href={confirmLink} onClick={handleOnClick} muted={location.pathname == confirmLink}>
            Confirm selection
          </Link>
        </li>
        <li className="text-xs text-gray-600 mx-2 my-1">
          <Icon icon="chevron-right" size={8} />
        </li>
        <li>
          <Link href={contactLink} onClick={handleOnClick} muted={location.pathname == contactLink}>
            Customer info
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default CheckoutHeaderNavigation;
