import type * as Polymorphic from '@radix-ui/react-polymorphic';
import { uniqueId } from 'lodash-es';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ProductIconName } from '../../assets/ProductIcon/ProductIcon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { ProductIcon } from '../../assets/ProductIcon/ProductIcon';
import { FocusRing } from '../../common/focus_rings';
import { sizing } from '../../common/sizing';
import { selectors } from '../../controls/shared/styles';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import Shortcut from '../../formatting/Shortcut/Shortcut';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Caption } from '../../text/Caption';
import { space } from '../../utilities/shared/sizes';
import { useVerticalShadowOnScroll, VerticalScrollShadow } from '../../utilities/useShadowOnScroll';

const SidebarChildContext = createContext<boolean | undefined>(undefined);
const SidebarChildProvider = SidebarChildContext.Provider;
const useSidebarChild = (controlledValue?: boolean, defaultValue: boolean = false) => {
  const isChild = useContext(SidebarChildContext);
  return controlledValue ?? isChild ?? defaultValue;
};

const SidebarTargetIcon = styled(Icon, {
  display: 'flex',
  color: '$$iconColor',

  '@notDesktop': {
    width: '$20',
    height: '$20',
  },

  '@desktop': {
    width: '$16',
    height: '$16',
  },
});

const SidebarTargetLabel = styled(Body, {
  width: '100%',
  fontWeight: fontWeights.bold,
  color: '$$labelColor',
  truncate: true,

  [darkThemeSelector]: {
    color: '$$labelColor',
  },
});

const SidebarTargetChevron = styled(Icon, {
  display: 'flex',
  color: '$$iconColor',
  margin: '0 $2',

  '@notDesktop': {
    width: '$16',
    height: '$16',
  },

  '@desktop': {
    width: '$12',
    height: '$12',
  },
});

const SidebarTargetLink = styled('a', FocusRing, {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  $$labelColor: colors.bodyNeutralLight,
  $$iconColor: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    $$labelColor: colors.bodyNeutralDark,
    $$iconColor: colors.bodyNeutralDark,
  },

  '@notDesktop': {
    gap: '$8',
    padding: '$8 $12',
    borderRadius: '$10',
  },

  '@desktop': {
    gap: '$6',
    padding: '$4 $8',
    borderRadius: '$8',
  },

  [selectors.hover]: {
    zIndex: 2,
    backgroundColor: colors.bgNeutralLight,
    $$labelColor: colors.headingNeutralLight,
    $$iconColor: colors.bodyNeutralLight,
    strokeAll: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.bgNeutralDark,
      $$labelColor: colors.headingNeutralDark,
      $$iconColor: colors.bodyNeutralDark,
      strokeAll: colors.strokeNeutralDark,
    },
  },

  [selectors.focus]: {
    zIndex: 4,
  },

  variants: {
    internal: {
      true: {},
      false: {},
    },
    isChild: {
      true: {},
      false: {},
    },
    isSelected: {
      true: {
        zIndex: 3,
        [`&, ${selectors.hover}`]: {
          $$labelColor: colors.headingBrandLight,
          $$iconColor: colors.bodyBrandLight,
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,

          [darkThemeSelector]: {
            $$labelColor: colors.headingBrandDark,
            $$iconColor: colors.bodyBrandDark,
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      internal: true,
      css: {
        $$labelColor: colors.internalBodyLight,
        $$iconColor: colors.internalIconLight,

        [darkThemeSelector]: {
          $$labelColor: colors.internalBodyDark,
          $$iconColor: colors.internalIconDark,
        },

        [selectors.hover]: {
          $$labelColor: colors.internalHeadingLight,
          $$iconColor: colors.internalBodyLight,

          [darkThemeSelector]: {
            $$labelColor: colors.internalHeadingDark,
            $$iconColor: colors.internalBodyDark,
          },
        },
      },
    },
    {
      internal: true,
      isSelected: true,
      css: {
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.internalBgLight,
          strokeAll: colors.internalStrokeLight,

          [darkThemeSelector]: {
            backgroundColor: colors.internalBgDark,
            strokeAll: colors.internalStrokeDark,
          },
        },
      },
    },
  ],
});

const SidebarTargetLI = styled('li', {
  position: 'relative',
  width: '100%',
});

export interface SidebarTargetProps {
  beta?: boolean;
  icon?: IconName;
  internal?: boolean;
  isChild?: boolean;
  isCollapsed?: boolean;
  isParent?: boolean;
  isSelected?: boolean;
  label?: React.ReactNode;
  shortcut?: React.ReactNode[];
}

export const SidebarTarget = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      beta,
      icon,
      internal,
      isChild,
      isCollapsed,
      isParent,
      isSelected,
      label,
      shortcut,
      ...props
    }: PolymorphicComponentProps<Tag, SidebarTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => {
    const isChildContext = useSidebarChild(isChild, false);
    return (
      <SidebarTargetLI role="menu-item">
        <SidebarTargetLink
          as={as}
          ref={forwardedRef}
          aria-label={`${label}`}
          {...props}
          internal={internal}
          isChild={isChildContext}
          isSelected={isSelected}
        >
          {!isChild && isParent && (
            <SidebarTargetChevron icon={isCollapsed ? 'chevron-right' : 'chevron-down'} />
          )}
          {icon && <SidebarTargetIcon icon={icon} />}
          <SidebarTargetLabel>{label}</SidebarTargetLabel>
          {beta && <FeatureBadge type="beta" size="small" />}
          {shortcut && <Shortcut keys={shortcut} />}
        </SidebarTargetLink>
      </SidebarTargetLI>
    );
  },
) as Polymorphic.ForwardRefComponent<React.ElementType, SidebarTargetProps>;

const SidebarGroupChevron = styled(Icon, {
  color: colors.bodyNeutralLight,
  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const SidebarGroupLabel = styled(Caption, {
  color: colors.controlContentPlaceholderLight,
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',

  [darkThemeSelector]: {
    color: colors.controlContentPlaceholderDark,
  },
});

const SidebarGroupChain = styled('div', {
  width: '100%',
  height: '$1',
  backgroundColor: colors.strokeNeutralLight,
  borderRadius: '$8',
  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
});

const SidebarGroupHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  minHeight: '$16',

  '@notDesktop': {
    gap: '$8',
    padding: '$0 $12 $4',
  },

  '@desktop': {
    gap: '$6',
    padding: '$0 $8 $4',
  },
});

const SidebarGroupItemsContainer = styled('ul', {
  vStack: '$0',
  alignItems: 'stretch',
  width: '100%',
  variants: {
    collapsed: {
      true: {
        display: 'none',
      },
      false: {
        display: 'flex',
      },
    },
  },
});

type SidebarGroupItemsProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  id?: string;
};

export function SidebarGroupItems({
  children,
  collapsed,
  id,
  ...remaining
}: SidebarGroupItemsProps) {
  return (
    <SidebarGroupItemsContainer id={id} role="menu" collapsed={collapsed} {...remaining}>
      {children}
    </SidebarGroupItemsContainer>
  );
}

const SidebarGroupContainer = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$4',

  variants: {
    isCollapsed: {
      true: {
        display: 'none',
      },
      false: {},
    },
    isChild: {
      true: {
        position: 'relative',
        padding: '$4 0',

        '@notDesktop': {
          paddingLeft: '$30',
        },

        '@desktop': {
          paddingLeft: '$22',
        },
      },
      false: {},
    },
  },
});

type SidebarGroupProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  label?: React.ReactNode;
  onClick?: (event: any) => void;
  id?: string;
  isCollapsed?: boolean;
  isChild?: boolean;
};

export function SidebarGroup({
  children,
  collapsed,
  onClick,
  label,
  id,
  isCollapsed,
  isChild,
  ...remaining
}: SidebarGroupProps) {
  const [groupID] = useState(uniqueId('sgi-'));
  return (
    <SidebarGroupContainer role="group" isCollapsed={isCollapsed} isChild={isChild} {...remaining}>
      {(onClick || label) && (
        <SidebarGroupHeader
          aria-controls={onClick && groupID}
          aria-expanded={!(onClick && collapsed)}
          role={onClick && 'button'}
          onClick={onClick}
          id={`${groupID}_${id}`}
        >
          {onClick && (
            <SidebarGroupChevron
              icon={collapsed ? 'chevron-right' : 'chevron-down'}
              size={space(8)}
            />
          )}
          {label && <SidebarGroupLabel>{label}</SidebarGroupLabel>}
          <SidebarGroupChain />
        </SidebarGroupHeader>
      )}
      <SidebarGroupItems aria-labelledby={`${groupID}_${id}`} id={groupID} collapsed={collapsed}>
        <SidebarChildProvider value={isChild}>{children}</SidebarChildProvider>
      </SidebarGroupItems>
    </SidebarGroupContainer>
  );
}

const SidebarGroupsScroll = styled('ul', VerticalScrollShadow, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  padding: sizing.contentSidesOnly,
  overflowY: 'auto',

  variants: {
    collapsible: {
      true: {
        gap: '$4',
      },
      false: {},
    },
  },
});

const SidebarGroupsContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  '@mobile': {
    [`&:first-child ${SidebarGroupContainer}:first-child`]: {
      paddingTop: '$12',
    },
    [`&:last-child ${SidebarGroupContainer}:last-child`]: {
      paddingBottom: '$12',
    },
  },

  '@tablet': {
    [`&:first-child ${SidebarGroupContainer}:first-child`]: {
      paddingTop: '$12',
    },
    [`&:last-child ${SidebarGroupContainer}:last-child`]: {
      paddingBottom: '$12',
    },
  },

  '@desktop': {
    [`&:first-child ${SidebarGroupContainer}:first-child`]: {
      paddingTop: '$8',
    },
    [`&:last-child ${SidebarGroupContainer}:last-child`]: {
      paddingBottom: '$8',
    },
  },
});

type SidebarGroupsProps = {
  children?: React.ReactNode;
  collapsible?: boolean;
  id?: string;
};

export function SidebarGroups({ children, collapsible, ...remaining }: SidebarGroupsProps) {
  const sidebarScrollRef = useRef<HTMLUListElement>(null);
  const { getBoxShadow, onScrollHandler, handleTargetChange } = useVerticalShadowOnScroll('both');

  const handleResize = useCallback(() => {
    if (sidebarScrollRef.current) {
      handleTargetChange(sidebarScrollRef.current);
    }
  }, [handleTargetChange]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <SidebarGroupsContainer {...remaining}>
      <SidebarGroupsScroll
        role="menu"
        onScroll={onScrollHandler}
        ref={sidebarScrollRef}
        {...getBoxShadow}
        collapsible={collapsible}
      >
        {children}
      </SidebarGroupsScroll>
    </SidebarGroupsContainer>
  );
}

const SidebarEndsContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',

  '@mobile': {
    padding: `$8 ${sizing.contentSides}`,
  },

  '@notMobile': {
    padding: sizing.contentSquish,
  },
});

const SidebarStartContainer = styled(SidebarEndsContainer, {
  variants: {
    hasHeading: {
      true: {
        gap: '$8',
        padding: `$8 ${sizing.contentSides} ${sizing.contentEnds}`,
      },
      false: {},
    },
  },
});

type SidebarStartProps = {
  children?: React.ReactNode;
  hasHeading?: boolean;
};

export function SidebarStart({ children, hasHeading, ...remaining }: SidebarStartProps) {
  return (
    <SidebarStartContainer hasHeading={hasHeading} {...remaining}>
      {children}
    </SidebarStartContainer>
  );
}

const SidebarEndContainer = styled(SidebarEndsContainer);

type SidebarEndProps = {
  children?: React.ReactNode;
};

export function SidebarEnd({ children, ...remaining }: SidebarEndProps) {
  return <SidebarEndContainer {...remaining}>{children}</SidebarEndContainer>;
}

const SidebarHeaderIconProduct = styled(ProductIcon, {
  '@tablet': {
    width: '$22',
    height: '$22',
  },

  '@desktop': {
    width: '$18',
    height: '$18',
  },
});

const SidebarHeaderIconStandard = styled(Icon, {
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  '@tablet': {
    width: '$20',
    height: '$20',
  },

  '@desktop': {
    width: '$16',
    height: '$16',
  },
});

const SidebarHeaderIcon = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@tablet': {
    width: '$20',
    height: '$20',
  },

  '@desktop': {
    width: '$16',
    height: '$16',
  },
});

const SidebarHeaderLabel = styled(Body);

const SidebarHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '@mobile': {
    display: 'none',
  },

  '@tablet': {
    gap: '$8',
    padding: '0 $12',
  },

  '@desktop': {
    gap: '$6',
    padding: '0 $8',
  },
});

// Guarantees a minimum internal width for the Drawer.
const sidebarWidthTablet = sizing.primary * 2 + 260;
const sidebarWidthDesktop = sizing.primary * 2 + 220;

const SidebarContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  height: '100%',
  padding: `$4 0`,
  overflow: 'hidden',

  '@mobile': {
    width: '100%',
  },

  '@tablet': {
    width: `$${sidebarWidthTablet}`,
    minWidth: `$${sidebarWidthTablet}`,
  },

  '@desktop': {
    width: `$${sidebarWidthDesktop}`,
    minWidth: `$${sidebarWidthDesktop}`,
  },

  variants: {
    collapsed: {
      true: {
        '@mobile': {
          display: 'none',
        },
      },
      false: {},
    },
  },
});

type SidebarProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  end?: React.ReactNode;
  heading?: React.ReactNode;
  icon?: IconName;
  product?: ProductIconName;
  start?: React.ReactNode;
};

export function Sidebar({
  children,
  collapsed,
  end,
  heading,
  icon,
  product,
  start,
  ...remaining
}: SidebarProps) {
  return (
    <SidebarContainer collapsed={collapsed} {...remaining}>
      {(heading || start) && (
        <SidebarStart hasHeading={!!heading}>
          {heading && (
            <SidebarHeader>
              <SidebarHeaderIcon>
                {product ? (
                  <SidebarHeaderIconProduct product={product} />
                ) : (
                  icon && <SidebarHeaderIconStandard icon={icon} />
                )}
              </SidebarHeaderIcon>
              <SidebarHeaderLabel weight="bold">{heading}</SidebarHeaderLabel>
            </SidebarHeader>
          )}
          {start}
        </SidebarStart>
      )}
      {children}
      {end && <SidebarEnd>{end}</SidebarEnd>}
    </SidebarContainer>
  );
}
