import { merge } from 'lodash-es';
import React from 'react';

import type { CSS } from '../../stitches.config';
import type { AlignTypes } from '../shared/Align';
import type { JustifyTypes } from '../shared/Justify';
import type { SizingScale, SpacingScale } from '../shared/sizes';
import type { PolymorphicRef } from '../types/polymorphicAsProp';
import { styled } from '../../stitches.config';
import { alignCSS } from '../shared/Align';
import { heightCSS } from '../shared/Height';
import { justifyCSS } from '../shared/Justify';
import { widthCSS } from '../shared/Width';

const BaseStack = styled('div', {});

export type StackSpacing = SpacingScale;
export type StackDisplay = 'flex' | 'inline-flex';

export type WrapTypes = 'no-wrap' | 'wrap' | 'wrap-reverse';
export type DirectionTypes = 'column' | 'column-reverse' | 'row' | 'row-reverse';

type Props<Tag extends React.ElementType> = {
  as?: Tag;
  children?: React.ReactNode;
  className?: string;

  align?: AlignTypes;
  direction?: DirectionTypes;
  display?: StackDisplay;
  height?: SizingScale;
  justify?: JustifyTypes;
  spacing?: StackSpacing;
  width?: SizingScale;
  wrap?: WrapTypes;
};

export const spacingCSS = (spacing?: SpacingScale): CSS =>
  spacing != null
    ? {
        gap: `$${spacing}`,
      }
    : {};

export type StackProps<Tag extends React.ElementType> = Props<Tag> &
  Omit<React.ComponentPropsWithoutRef<Tag>, keyof Props<Tag>>;

export const Stack = React.forwardRef(
  <Tag extends React.ElementType = 'div'>(
    {
      as,
      align,
      children,
      direction,
      display = 'flex',
      height,
      justify,
      spacing,
      width,
      wrap,
      ...remaining
    }: StackProps<Tag>,
    ref: PolymorphicRef<Tag>,
  ) => {
    const baseCSS: CSS = {
      display,
      flexDirection: direction,
      flexWrap: wrap,
    };

    const css = merge(
      baseCSS,
      alignCSS(align),
      justifyCSS(justify),
      widthCSS(width),
      heightCSS(height),
      spacingCSS(spacing),
    );

    return (
      <BaseStack {...remaining} as={as} css={css} ref={ref}>
        {children}
      </BaseStack>
    );
  },
);
