/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useEffect } from 'react';

import { ConnectLogo, Title } from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

// Components
import OnboardingLayout from '../../components/Layout/OnboardingLayout/OnboardingLayout';
import QuotesOnboardingProviders from '../../components/Shared/QuotesOnboardingProviders/QuotesOnboardingProviders';
import TopProviderForm from '../../components/TopProvider/TopProviderForm';
// Hooks
import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
import { route } from '../../routes';

function QuoteRequestsProvider() {
  // Router
  const navigate = useNavigate();

  // Page: get locally saved form values
  const [, temporaryFormValues] = useFormStorage(REQUEST_QUOTE_FORM_KEY);

  // Page: redirect to location if not set
  useEffect(() => {
    if (!temporaryFormValues.address) {
      return navigate(route('location'));
    }
  }, [navigate, temporaryFormValues]);

  return (
    <OnboardingLayout sidebarChildren={<QuotesOnboardingProviders />}>
      <div className="mx-auto max-w-sm mt-6 max-w-screen-xs w-full">
        <div className="mb-8">
          <ConnectLogo />
        </div>
        <div className="mt-8 mb-6">
          <Title>Renegotiate your existing connection</Title>
        </div>
        <TopProviderForm />
      </div>
    </OnboardingLayout>
  );
}

export default QuoteRequestsProvider;
