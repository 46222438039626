import React, { useState } from 'react';

import { Button, Caption } from '@meterup/atto';

import useLogEvent, { RESULTS_PAGE_SHARE_VIA_MAIL_CLICKED } from '../../../hooks/useLogEvent';

// Props will be a bunch of onchangeevent
interface ShareQuotesProps {
  address: string;
}

function ShareQuotes({ address }: ShareQuotesProps) {
  const logEvent = useLogEvent();

  const [emails] = useState('');

  const emailSubject = `Internet quotes for ${address}`;

  const emailBody = `I found these business internet quotes for ${address}:

${window.location.href}
`;

  const buttonOnClick = () => {
    logEvent(RESULTS_PAGE_SHARE_VIA_MAIL_CLICKED);
    window.location.href = `mailto:${emails}?subject=${encodeURI(emailSubject)}&body=${encodeURI(
      emailBody,
    )}`;
  };

  return (
    <section className="flex justify-between items-center min-h-48 py-2 flex-wrap gap-2">
      <div className="whitespace-nowrap">
        <Caption>Share</Caption>
      </div>
      <span>
        <Button arrangement="leading-icon" icon="email" onClick={buttonOnClick} variant="secondary">
          Share via email
        </Button>
      </span>
    </section>
  );
}

export default ShareQuotes;
