import React from 'react';

import { ModalProvider } from '@meterup/react-aria';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import Root from './pages/Root';
import { regRoutesToElements } from './regRoutesToElements';
import { regRoutes } from './routes';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      useErrorBoundary: true,
    },
  },
});

const browserRouter = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      {regRoutesToElements(regRoutes)}
    </Route>,
  ),
);
//
// const browserRouter = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path="/" element={<Root />}>
//       {regRoutesToElements(regRoutes)}
//     </Route>,
//   ),
// );

export default function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ModalProvider>
          <RouterProvider router={browserRouter} />
        </ModalProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
