import camelCaseKeys from 'camelcase-keys';
import { useCallback, useEffect, useState } from 'react';

import type { EntityKey, EntityMap, EntityToGetRequestMap } from '../protos';
import { get } from '../requests';

export default function useApiResources<E extends EntityKey>(
  namespace: E,
  path: string,
  ready = true,
): [boolean, EntityMap[E][] | [], (skipSetLoading?: boolean) => Promise<void>] {
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState<EntityMap[E][]>();

  const reload = useCallback(
    async (skipSetLoading = false) => {
      const response = await get<EntityToGetRequestMap[E]>(
        path,
        skipSetLoading ? undefined : setLoading,
      );

      if (response) {
        setEntity((camelCaseKeys(response, { deep: true }) as any)[namespace]);
      }
    },
    [namespace, path],
  );

  useEffect(() => {
    if (ready) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, path]);

  return [loading, entity || [], reload];
}
