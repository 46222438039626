import React, { useCallback } from 'react';

import { Select, SelectItem } from '@meterup/atto';
import { first } from 'lodash-es';
import type { ComponentProps } from 'react';
import { FieldRenderProps } from 'react-final-form';

type SelectProps = ComponentProps<typeof Select>;
// Mixed interface between React Final Form and our pure SelectForm
interface SelectFieldProps extends FieldRenderProps<string>, Omit<SelectProps, 'children'> {
  options: { label: string; value: string }[];
}

// TODO: Can we move this to the fornt-end component library?
export default function SelectField({
  // React Final Form
  input,
  meta,
  placeholder,
  // Frontend components
  options,
  disabled,
}: SelectFieldProps) {
  // TODO: The error mark up should not be here
  // @ts-ignore
  const displayError = meta.error && meta.touched;
  const onChange = useCallback(
    (val: string) => {
      input.onChange(val);
    },
    [input],
  );
  return (
    <div>
      <Select
        name={input.name}
        onValueChange={onChange}
        value={input.value || first(options)?.value || ''}
        disabled={disabled}
        placeholder={placeholder}
        width="100%"
        invalid={displayError}
      >
        {options.map(({ label, value }) => (
          <SelectItem key={value}>{label}</SelectItem>
        ))}
      </Select>
      {displayError && <span className="mt-2 ml-2 text-xs text-red-600">{meta.error}</span>}
    </div>
  );
}
