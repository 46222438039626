import React, { useId } from 'react';

import type { HardwareIconPropHardware } from '../../assets/HardwareIcon/HardwareIcon';
import { FramedIcon } from '../../assets/FramedIcon/FramedIcon';
import { HardwareIcon } from '../../assets/HardwareIcon/HardwareIcon';
import { type IconName, Icon } from '../../assets/Icon/Icon';
import { Badge } from '../../components/Badge/Badge';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { space } from '../../utilities/shared/sizes';

const HOPS_HORIZONTAL_SIZE = 40;
const HOPS_VERTICAL_SIZE = 32;

const HopConnectionDash = styled('div', {
  background: colors.hardwareStrokeNeutralLight,
  borderRadius: '99em',

  [darkThemeSelector]: {
    background: colors.hardwareStrokeNeutralDark,
  },

  variants: {
    direction: {
      horizontal: {
        width: '$6',
        height: '$2',
      },
      vertical: {
        width: '$2',
        height: '$6',
      },
    },
    status: {
      connected: {},
      disconnected: {
        background: colors.hardwareStrokeNeutralLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokeNeutralDark,
        },
      },
      error: {
        background: colors.hardwareStrokeNegativeLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokeNegativeDark,
        },
      },
    },
    speed: {
      low: {},
      medium: {},
      high: {},
    },
  },

  compoundVariants: [
    {
      status: 'connected',
      speed: 'low',
      css: {
        background: colors.hardwareStrokeAttentionLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokeAttentionDark,
        },
      },
    },
    {
      status: 'connected',
      speed: 'medium',
      css: {
        background: colors.hardwareStrokePositiveLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokePositiveDark,
        },
      },
    },
    {
      status: 'connected',
      speed: 'high',
      css: {
        background: colors.hardwareStrokeAlternativeLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokeAlternativeDark,
        },
      },
    },
  ],
});

const HopConnectionDashes = styled('div', {
  position: 'relative',
  zIndex: 0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '$4',
  overflow: 'hidden',

  variants: {
    direction: {
      horizontal: {
        left: '-50%',
        minWidth: '200%',
        height: '$2',
      },
      vertical: {
        top: '-50%',
        width: '$2',
        minHeight: '200%',
      },
    },
  },
});

const HopConnectionIcon = styled(Icon, {
  width: '$14',
  height: '$14',
  color: colors.iconNegativeLight,

  [darkThemeSelector]: {
    color: colors.iconNegativeDark,
  },
});

const HopConnectionBreaker = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1,
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  padding: '$4',
  background: colors.bgApplicationLight,
  borderRadius: '99em',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },
});

const HopConnectionPosition = styled('div', {
  position: 'relative',
  display: 'flex',

  variants: {
    direction: {
      horizontal: {
        alignItems: 'center',
        height: `$${HOPS_HORIZONTAL_SIZE}`,
      },
      vertical: {
        justifyContent: 'center',
        width: `$${HOPS_VERTICAL_SIZE}`,
        height: '$28',
      },
    },
  },
});

const HopSubjectLabel = styled(Body, {
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',
});

const HopSubject = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',

  variants: {
    direction: {
      horizontal: {
        flexDirection: 'column',
        gap: '$4',
      },
      vertical: {
        flexDirection: 'row',
        gap: '$8',
      },
    },
  },
});

const speedToCategory = (speed?: number) => {
  if (speed) {
    if (speed < 1000) {
      return 'low';
    }
    if (speed < 10000) {
      return 'medium';
    }
    if (speed < 100000) {
      return 'high';
    }
  }
  return undefined;
};

const speedStatusToVariant = (speed?: number, status?: HopPropStatus) => {
  if (status === 'error') {
    return 'negative';
  }
  if (status === 'disconnected') {
    return 'neutral';
  }
  if (speed && speed < 1000) {
    return 'attention';
  }
  if (speed && speed < 10000) {
    return 'positive';
  }
  if (speed && speed < 100000) {
    return 'alternative';
  }
  return undefined;
};

export const getHopSubjectIcon = (direction: HopPropDirection, icon: HopPropIcon) => {
  const size = direction === 'horizontal' ? HOPS_HORIZONTAL_SIZE : HOPS_VERTICAL_SIZE;
  switch (icon) {
    case 'access-point':
      return <HardwareIcon hardware={icon} size={space(size)} />;
    case 'pdu':
    case 'power-distribution-unit':
    case 'switch':
    case 'security-appliance':
      return <HardwareIcon hardware={icon} size={size} />;
    default:
      return <FramedIcon icon={icon} size={size} />;
  }
};

type HopPropIcon = HardwareIconPropHardware | IconName;
type HopPropDirection = 'horizontal' | 'vertical';
type HopPropStatus = 'connected' | 'disconnected' | 'error';

type HopProps = {
  badge?: React.ReactNode;
  direction?: HopPropDirection;
  icon?: HopPropIcon;
  label: React.ReactNode;
  showConnection?: boolean;
  speed?: number;
  status?: HopPropStatus;
};

const HopSubjectBadgeContainer = styled(Badge, {
  position: 'relative',
  zIndex: 1,
  marginTop: '-$12',
});

function HopSubjectBadge({
  children,
  speed,
  status,
}: {
  children: React.ReactNode;
  speed?: number;
  status?: HopPropStatus;
}) {
  return (
    <HopSubjectBadgeContainer
      ends="pill"
      size="small"
      variant={speedStatusToVariant(speed, status)}
    >
      {children}
    </HopSubjectBadgeContainer>
  );
}

function Hop({
  badge,
  direction = 'horizontal',
  icon,
  label,
  showConnection,
  speed,
  status,
}: HopProps) {
  return (
    <>
      {showConnection && (
        <HopConnectionPosition direction={direction}>
          {status === 'error' && (
            <HopConnectionBreaker>
              <HopConnectionIcon icon="cross-circle" />
            </HopConnectionBreaker>
          )}
          <HopConnectionDashes direction={direction}>
            {[...Array(100)].map(() => (
              <HopConnectionDash
                direction={direction}
                status={status}
                speed={speedToCategory(speed)}
              />
            ))}
          </HopConnectionDashes>
        </HopConnectionPosition>
      )}
      <HopSubject direction={direction}>
        {icon && getHopSubjectIcon(direction, icon)}
        {badge && (
          <HopSubjectBadge speed={speed} status={status}>
            {badge}
          </HopSubjectBadge>
        )}
        {label && <HopSubjectLabel>{label}</HopSubjectLabel>}
      </HopSubject>
    </>
  );
}

const HopsContainer = styled('div', {
  width: '100%',

  variants: {
    direction: {
      horizontal: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
      },
      vertical: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
});

type HopsProps = {
  direction?: HopPropDirection;
  hops: HopProps[];
};

export default function Hops({ direction = 'horizontal', hops }: HopsProps) {
  const uuid = useId();
  return (
    <HopsContainer direction={direction}>
      {hops.map((hop, index) => (
        <Hop
          key={`hops-${uuid}-${hop.label}`}
          {...hop}
          direction={direction}
          showConnection={index !== 0}
        />
      ))}
    </HopsContainer>
  );
}
