import { colors, darkThemeSelector, styled } from '../stitches.config';
import { Text } from './Text';

const CaptionBase = {
  color: colors.bodyNeutralLight,
  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
  variants: {
    internal: {
      true: {
        color: colors.internalBodyLight,
        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },
};

const CaptionSansSizes = {
  '@notDesktop': {
    fontSize: '$13',
    lineHeight: '$14',
  },
  '@desktop': {
    fontSize: '$11',
    lineHeight: '$12',
  },
};

const CaptionMonoSizes = {
  '@notDesktop': {
    fontSize: '$12',
    lineHeight: '$14',
  },
  '@desktop': {
    fontSize: '$10',
    lineHeight: '$12',
  },
};

export const Caption = styled(Text, CaptionBase, {
  variants: {
    family: {
      regular: CaptionSansSizes,
      monospace: CaptionMonoSizes,
    },
    transform: {
      uppercase: {},
    },
  },
  defaultVariants: {
    family: 'regular',
    transform: 'uppercase',
  },
});
