/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React, { useEffect } from 'react';

import { ConnectLogo, Title } from '@meterup/atto';
import { useNavigate } from 'react-router-dom';

// Components
import OnboardingLayout from '../../components/Layout/OnboardingLayout/OnboardingLayout';
import ProviderForm from '../../components/Provider/ProviderForm';
import QuotesOnboardingProviders from '../../components/Shared/QuotesOnboardingProviders/QuotesOnboardingProviders';
// Hooks
import useFormStorage, { REQUEST_QUOTE_FORM_KEY } from '../../hooks/useFormStorage';
import { route } from '../../routes';

function QuoteRequestsProvider() {
  // Router
  const navigate = useNavigate();

  // Page: get locally saved form values
  const [_onChange, temporaryFormValues] = useFormStorage(REQUEST_QUOTE_FORM_KEY);

  // Page: redirect to location if not set
  useEffect(() => {
    if (!temporaryFormValues.address) {
      return navigate(route('location'));
    }
  }, [navigate, temporaryFormValues]);

  return (
    <OnboardingLayout sidebarChildren={<QuotesOnboardingProviders />}>
      <div className="mx-auto max-w-sm mt-6 max-w-screen-xs w-full">
        <div className="mb-8">
          <ConnectLogo />
        </div>
        <div className="mt-8 mb-6">
          <Title>Renegotiate your existing connection</Title>
        </div>
        <ProviderForm />
      </div>
    </OnboardingLayout>
  );
}

export default QuoteRequestsProvider;
