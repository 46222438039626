import React, { useEffect } from 'react';

import { ErrorBoundary } from '@sentry/react';
import { Location, Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

// import Heap from '../components/Heap';

function trackPageView(location: Location) {
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: 'pageview',
      page: {
        path: location.pathname,
        search: location.search,
      },
    });
  }
}

export default function Root() {
  // Track page view for Gtag
  const location = useLocation();

  useEffect(() => {
    trackPageView(location);
  }, [location]);

  return (
    <ErrorBoundary>
      <ScrollRestoration />
      <Outlet />
    </ErrorBoundary>
  );
}
