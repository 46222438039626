import React from 'react';

import { Caption, Slider, TextInput } from '@meterup/atto';

// Props will be a bunch of onchangeevent
interface FilterPriceProps {
  minMonthlyFee: number;
  maxMonthlyFee: number;
  currentMonthlyFee: number;
  onMonthlyPriceChange: (event: any) => void;
}

function FilterPrice({
  minMonthlyFee,
  maxMonthlyFee,
  currentMonthlyFee,
  onMonthlyPriceChange,
}: FilterPriceProps) {
  return (
    <section>
      <Caption>Price</Caption>
      <div className="mt-0">
        <span className="inline-block w-3/6 text-sm">Max Price</span>
        <span className="inline-block w-3/6">
          <TextInput
            name="amount"
            type="number"
            controlSize="large"
            prefix="$"
            value={currentMonthlyFee.toString()}
            onChange={onMonthlyPriceChange}
            placeholder="Download Speed"
          />
        </span>
      </div>
      <div className="mt-4">
        <Slider
          min={minMonthlyFee}
          max={maxMonthlyFee}
          value={[currentMonthlyFee]}
          onValueChange={onMonthlyPriceChange}
        />
      </div>
    </section>
  );
}

export default FilterPrice;
