import React from 'react';

import { styled } from '../styled';

const ExpandFlexEl = styled('div', {
  flex: 1,
});
export function ExpandFlex({ children }: { children?: React.ReactNode }) {
  return <ExpandFlexEl>{children || <>&nbsp;</>}</ExpandFlexEl>;
}
