/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
// Libraries
import React from 'react';

// Components
import { ConnectLogo, Title } from '@meterup/atto';

import OnboardingLayout from '../../components/Layout/OnboardingLayout/OnboardingLayout';
import LocationForm from '../../components/Location/LocationForm';
import QuotesOnboardingFeatures from '../../components/Shared/QuotesOnboardingFeatures/QuotesOnboardingFeatures';

function QuoteRequestsLocation() {
  return (
    <OnboardingLayout sidebarChildren={<QuotesOnboardingFeatures />}>
      <div className="mx-auto max-w-sm mt-6 max-w-screen-xs w-full">
        <ConnectLogo />
        <div className="mt-8">
          <Title>Enter your business address:</Title>
        </div>
        <div className="mt-8">
          <LocationForm />
        </div>
      </div>
    </OnboardingLayout>
  );
}

export default QuoteRequestsLocation;
