import { ServiceIconSVG } from 'atto-svgs';

import type { SizerProp } from '../../utilities/shared/sizes';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { sizer, space } from '../../utilities/shared/sizes';

export const ServiceIcons = {
  accesspeople: ServiceIconSVG.AccessPeopleHR,
  adp: ServiceIconSVG.ADP,
  apple: ServiceIconSVG.Apple,
  asana: ServiceIconSVG.Asana,
  auth0: ServiceIconSVG.Auth0,
  azure: ServiceIconSVG.Azure,
  bamboo: ServiceIconSVG.BambooHR,
  breathe: ServiceIconSVG.BreatheHR,
  cas: ServiceIconSVG.CAS,
  cezanne: ServiceIconSVG.CezanneHR,
  chrome: ServiceIconSVG.Chrome,
  classlink: ServiceIconSVG.ClassLink,
  cloudflare: ServiceIconSVG.Cloudflare,
  cyberark: ServiceIconSVG.CyberArk,
  datadog: ServiceIconSVG.Datadog,
  docusign: ServiceIconSVG.Docusign,
  duo: ServiceIconSVG.Duo,
  figma: ServiceIconSVG.Figma,
  firebase: ServiceIconSVG.Firebase,
  firefox: ServiceIconSVG.Firefox,
  fourth: ServiceIconSVG.Fourth,
  github: ServiceIconSVG.Github,
  godaddy: ServiceIconSVG.GoDaddy,
  google: ServiceIconSVG.Google,
  googlecloud: ServiceIconSVG.GoogleCloud,
  greenhouse: ServiceIconSVG.Greenhouse,
  hibob: ServiceIconSVG.HiBob,
  jira: ServiceIconSVG.JIRA,
  jumpcloud: ServiceIconSVG.JumpCloud,
  keycloak: ServiceIconSVG.Keycloak,
  kisi: ServiceIconSVG.Kisi,
  lastpass: ServiceIconSVG.LastPass,
  linear: ServiceIconSVG.Linear,
  logingov: ServiceIconSVG.LoginGOV,
  loom: ServiceIconSVG.Loom,
  microsoft: ServiceIconSVG.Microsoft,
  miniorange: ServiceIconSVG.MiniOrange,
  netiq: ServiceIconSVG.NetIQ,
  notion: ServiceIconSVG.Notion,
  okta: ServiceIconSVG.Okta,
  onelogin: ServiceIconSVG.OneLogin,
  onepassword: ServiceIconSVG.OnePassword,
  oracle: ServiceIconSVG.Oracle,
  personio: ServiceIconSVG.Personio,
  pingidentity: ServiceIconSVG.PingIdentity,
  quip: ServiceIconSVG.Quip,
  ramp: ServiceIconSVG.Ramp,
  reactnativeexpo: ServiceIconSVG.ReactNativeExpo,
  rippling: ServiceIconSVG.Rippling,
  salesforce: ServiceIconSVG.Salesforce,
  sentry: ServiceIconSVG.Sentry,
  shibboleth: ServiceIconSVG.Shibboleth,
  simplesamlphp: ServiceIconSVG.SimpleSAMLPHP,
  slack: ServiceIconSVG.Slack,
  splunk: ServiceIconSVG.Splunk,
  supabase: ServiceIconSVG.Supabase,
  twitter: ServiceIconSVG.Twitter,
  vercel: ServiceIconSVG.Vercel,
  vmware: ServiceIconSVG.VMWare,
  workday: ServiceIconSVG.Workday,
  zoom: ServiceIconSVG.Zoom,
  airtable: ServiceIconSVG.Airtable,
};

export type ServiceIconName = keyof typeof ServiceIcons;

const ServiceIconBase = styled('svg', {
  display: 'flex',

  variants: {
    ratio: {
      'x-small': {
        width: '80%',
        height: '80%',
      },
      small: {
        width: '64%',
        height: '64%',
      },
      medium: {
        width: '56%',
        height: '56%',
      },
      large: {
        width: '48%',
        height: '48%',
      },
    },
  },
});

const getSize = (size: number) => {
  switch (true) {
    case size >= 44:
      return 'large';
    case size >= 24:
      return 'medium';
    default:
      return 'small';
  }
};
export interface ServiceIconProps {
  /**
   * Set which manufacturer to display.
   */
  icon: ServiceIconName;
  /**
   * Set a size of the component.
   */
  size?: number;
}

function ServiceIconIcon({ icon, size = space(28), ...remaining }: ServiceIconProps) {
  const NamedIcon = ServiceIcons[icon];

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  return <ServiceIconBase ratio={getSize(size)} as={NamedIcon} {...remaining} />;
}

const ServiceIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.bgApplicationLight,
  color: colors.iconNeutralLight,
  boxShadow: shadows.manufacturerLight,

  [darkThemeSelector]: {
    boxShadow: shadows.manufacturerDark,
  },

  variants: {
    radius: {
      'x-small': {
        borderRadius: '$4',
      },
      small: {
        borderRadius: '$6',
      },
      medium: {
        borderRadius: '$10',
      },
      large: {
        borderRadius: '$12',
      },
    },
  },
});

const getServiceIconSizeLabel = (size: number) => {
  switch (true) {
    case size >= 44:
      return 'large';
    case size >= 32:
      return 'medium';
    case size >= 20:
      return 'small';
    default:
      return 'x-small';
  }
};

export function ServiceIcon({ icon, size = 28, ...remaining }: ServiceIconProps) {
  return (
    <ServiceIconContainer
      radius={getServiceIconSizeLabel(size)}
      style={{
        width: sizer(size as SizerProp),
        maxWidth: sizer(size as SizerProp),
        minWidth: sizer(size as SizerProp),
        height: sizer(size as SizerProp),
        maxHeight: sizer(size as SizerProp),
        minHeight: sizer(size as SizerProp),
      }}
      {...remaining}
    >
      <ServiceIconIcon icon={icon} size={size} />
    </ServiceIconContainer>
  );
}
