import { useEffect } from 'react';

import { getTldWithPort } from '@meterup/connect-api';
import { Ports } from '@meterup/connect-api/src/config';

export default function RedirectToCompanyDashboard() {
  useEffect(() => {
    window.location.href = `https://dashboard.meter.${getTldWithPort(
      window.location,
      Ports.Dashboard,
    )}/connect`;
  }, []);

  return null;
}
