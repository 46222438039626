/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useCallback } from 'react';

import { Label } from '@meterup/atto';
import type {
  AxiosAPIError,
  CompanyResponse,
  QuoteRequest,
  QuoteRequestCreateRequest,
} from '@meterup/connect-api';
import { axios, put } from '@meterup/connect-api';
import { Textarea, TextInput } from '@meterup/connect-ui';
import { useIdentity } from '@meterup/connect-ui/src/hooks/useIdentity';
import { required, validEmail, validTelephone } from '@meterup/connect-ui/src/validation';
import { useQuery } from '@tanstack/react-query';
import { AsYouType } from 'libphonenumber-js';
import { Field, Form } from 'react-final-form';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';

import { route } from '../../../routes';

interface QuotesContractContactInfoFormProps {
  quoteRequest: QuoteRequest;
}

function validDate(value: string) {
  const date = new Date(value);
  return Number.isNaN(date.getTime()) ? 'Invalid date' : undefined;
}

function QuotesContractContactInfoForm({ quoteRequest }: QuotesContractContactInfoFormProps) {
  const navigate = useNavigate();

  const onSubmit = useCallback(async (values: QuoteRequestCreateRequest) => {
    try {
      // logEvent(REQUEST_QUOTE_FORM_SUBMIT_CONTACT_INFORMATION);
      // setError("");
      const request = {
        companyName: String(values.companyName),
        contactName: String(values.contactName),
        contactEmail: String(values.contactEmail),
        contactTelephone: String(values.contactTelephone),
        notes: String(values.notes),
        status: String('contract_requested'),
        ...(values.contractMaximumInstallDate
          ? { contractMaximumInstallDate: new Date(values.contractMaximumInstallDate) }
          : {}),
      };
      await put(`quote-requests/${quoteRequest.sid}`, request);

      TagManager.dataLayer({
        dataLayer: {
          event: 'conversion',
          send_to: 'AW-475626357/K-YMCNyXoJcYEPX25eIB',
          conversionValue: 1.0,
          currency: 'USD',
        },
      });

      navigate(route('completed', { sid: quoteRequest.sid }));
    } catch (e) {
      // setError(String(e));
      // setLoading(false);
    }
  }, []);

  // Probs should be shared validation
  const validateEmail = (value: string) => required(value) || validEmail(value);
  const formatTelephone = (value: string) => new AsYouType('US').input(value || '');
  const validateDate = (value: string) => validDate(value);

  const validateTelephone = (value: string) => required(value) || validTelephone(value);
  const identity = useIdentity();
  const { data: company } = useQuery<unknown, AxiosAPIError, CompanyResponse>(
    ['company'],
    () => axios.get(`v1/companies/${identity?.companyMemberships[0].companySlug}`),
    {
      enabled: !!identity,
    },
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        companyName: company?.name,
        contactEmail: identity?.username,
      }}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          id="contactForm"
          className="border border-gray-100 bg-white rounded-lg p-4 mb-12"
        >
          <div className="grid grid-cols-2 gap-6">
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="companyName">Company name*</Label>
              <div className="mt-2">
                <Field
                  component={TextInput}
                  aria-label="Company name"
                  size="large"
                  icon="company"
                  name="companyName"
                  type="text"
                  id="companyName"
                  placeholder="Company"
                  validate={required}
                />
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="contactName">Your first and last name*</Label>
              <div className="mt-2">
                <Field
                  component={TextInput}
                  aria-label="Your first and last name"
                  size="large"
                  icon="user"
                  name="contactName"
                  type="text"
                  id="contactName"
                  placeholder="First and last name"
                  validate={required}
                />
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="contactTelephone">Phone number</Label>
              <div className="mt-2">
                <Field
                  component={TextInput}
                  aria-label="Phone number"
                  size="large"
                  icon="phone"
                  name="contactTelephone"
                  type="tel"
                  id="contactTelephone"
                  placeholder="Phone number"
                  format={formatTelephone}
                  validate={validateTelephone}
                />
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <Label htmlFor="contactEmail">Email</Label>
              <div className="mt-2">
                <Field
                  component={TextInput}
                  aria-label="Email"
                  size="large"
                  icon="email"
                  name="contactEmail"
                  type="email"
                  id="contactEmail"
                  placeholder="Work email address"
                  validate={validateEmail}
                />
              </div>
            </div>
            {!quoteRequest.contractMaximumInstallDate ? (
              <div className="col-span-2">
                <Label htmlFor="contractMaximumInstallDate">Preferred install date</Label>
                <div className="mt-2">
                  <Field<string>
                    component={TextInput}
                    name="contractMaximumInstallDate"
                    type="date"
                    size="large"
                    id="contractMaximumInstallDate"
                    validate={validateDate}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="mt-6">
            <Label htmlFor="notes">Anything else?</Label>
            <div className="mt-2">
              <Field
                component={Textarea}
                aria-label="Any other notes you'd like to include"
                size="large"
                icon="pen"
                name="notes"
                id="notes"
                placeholder="Any special instructions, or other information about your internet needs?"
              />
            </div>
          </div>
        </form>
      )}
    />
  );
}

export default QuotesContractContactInfoForm;
