import React, { type ComponentProps } from 'react';

import type { GutterProp } from '../../common/sizing';
import { sizing } from '../../common/sizing';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body, BodySansSizes } from '../../text/Body';
import { LargeSansSizes } from '../../text/Large';
import { Small, SmallSansSizes } from '../../text/Small';

const StatDisplay = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  height: '100%',
  color: '$$displayColor',

  variants: {
    alignment: {
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      start: {
        justifyContent: 'flex-start',
      },
    },
    size: {
      small: {
        ...SmallSansSizes,
      },
      medium: {
        ...BodySansSizes,
      },
      large: {
        ...LargeSansSizes,
      },
    },
  },
});

const StatLabel = styled(Small, {
  width: '100%',
  color: '$$labelColor',
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',

  variants: {
    variant: {
      negative: {
        color: colors.bodyNegativeLight,

        [darkThemeSelector]: {
          color: colors.bodyNegativeDark,
        },
      },
      neutral: {},
      positive: {
        color: colors.bodyPositiveLight,

        [darkThemeSelector]: {
          color: colors.bodyPositiveDark,
        },
      },
    },
  },
});

const StatLegend = styled(Body, {
  display: 'flex',
  flexDirection: 'row',
  color: '$$legendColor',
});

const StatHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '$8',
});

const StatContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  $$labelColor: colors.bodyNeutralLight,
  $$legendColor: colors.bodyNeutralLight,
  $$displayColor: colors.headingNeutralLight,

  [darkThemeSelector]: {
    $$labelColor: colors.bodyNeutralDark,
    $$legendColor: colors.bodyNeutralDark,
    $$displayColor: colors.headingNeutralDark,
  },

  variants: {
    alignment: {
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      start: {
        alignItems: 'flex-start',
      },
    },
    arrangement: {
      'leading-display': {
        flexDirection: 'column-reverse',
      },
      'leading-label': {
        flexDirection: 'column',
      },
    },
    selected: {
      true: {
        $$labelColor: colors.bodyBrandLight,
        $$legendColor: colors.bodyBrandLight,
        $$displayColor: colors.headingBrandLight,

        [darkThemeSelector]: {
          $$labelColor: colors.bodyBrandDark,
          $$legendColor: colors.bodyBrandDark,
          $$displayColor: colors.headingBrandDark,
        },
      },
      false: {},
    },
    size: {
      small: {},
      medium: {},
      large: {
        gap: '$4',
      },
    },
  },
});

type StatAlignmentProp = 'center' | 'end' | 'start';
type StatArrangementProp = 'leading-display' | 'leading-label';
type StatSizeProp = 'small' | 'medium' | 'large';

export type StatProps = {
  alignment?: StatAlignmentProp;
  arrangement?: StatArrangementProp;
  label: React.ReactNode;
  legend?: React.ReactNode;
  selected?: boolean;
  size?: StatSizeProp;
  value: React.ReactNode;
  variant?: 'negative' | 'neutral' | 'positive';
} & ComponentProps<typeof StatContainer>;

function Stat({
  alignment,
  arrangement,
  label,
  legend,
  selected,
  size,
  value,
  variant,
  ...remaining
}: StatProps) {
  return (
    <StatContainer
      alignment={alignment}
      arrangement={arrangement}
      selected={selected}
      size={size}
      {...remaining}
    >
      <StatHeader>
        <StatLabel variant={variant}>{label}</StatLabel>
        {legend && <StatLegend>{legend}</StatLegend>}
      </StatHeader>
      <StatDisplay alignment={alignment} size={size}>
        {value}
      </StatDisplay>
    </StatContainer>
  );
}

const StatsContainer = styled('div', {
  position: 'relative',
  gap: '$12',
  width: '100%',
  padding: sizing.squish,
  overflow: 'hidden',

  variants: {
    gutter: {
      all: {
        padding: sizing.squish,
      },
      vertical: {
        padding: sizing.endsOnly,
      },
      horizontal: {
        padding: sizing.sidesOnly,
      },
      top: {
        paddingTop: sizing.ends,
      },
      right: {
        paddingRight: sizing.sides,
      },
      bottom: {
        paddingBottom: sizing.ends,
      },
      left: {
        paddingLeft: sizing.sides,
      },
      none: {
        padding: 0,
      },
    },
    proportions: {
      auto: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      equal: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
      },
    },
    size: {
      small: {
        gap: '$4',
      },
      medium: {
        gap: '$8',
      },
      large: {
        gap: '$12',
      },
    },
  },
});

export type StatsProps = {
  alignment?: StatAlignmentProp;
  arrangement?: StatArrangementProp;
  gutter?: GutterProp;
  proportions?: 'equal' | 'auto';
  selected?: boolean;
  size?: StatSizeProp;
  stats: StatProps[];
} & ComponentProps<typeof StatsContainer>;

export function Stats({
  alignment = 'start',
  gutter = 'all',
  arrangement = 'leading-label',
  proportions = 'equal',
  selected,
  size = 'large',
  stats,
  ...remaining
}: StatsProps) {
  return (
    <StatsContainer gutter={gutter} proportions={proportions} size={size} {...remaining}>
      {stats.map((stat) => (
        <Stat
          key={`${stat.label}`}
          alignment={alignment}
          arrangement={arrangement}
          selected={selected}
          size={size}
          {...stat}
        />
      ))}
    </StatsContainer>
  );
}
