import type { PortalOrigin } from './enums';
import { getPortalUrl } from './config';
import { headers } from './headers';
import { redirectToPortal } from './hooks/useAuthenticatedUser';

export default async function verifyAuth(via: string): Promise<void> {
  const { status } = await fetch(`${getPortalUrl(window.location)}/api-proxy/v1/identity`, {
    headers,
    credentials: 'include',
  });

  if (status !== 200) {
    redirectToPortal(via as PortalOrigin);
  }
}
