/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import { Badge, CheckboxInput, LoadingIcon } from '@meterup/atto';
import { Quote } from '@meterup/connect-api';
import { ProviderLogo } from '@meterup/connect-ui';
import cx from 'classnames';

// TODO: Get this from @api
interface ManualQuoteBlockProps {
  quote: Quote;
  onRequestManualQuote: (sid: string) => void;
  loading?: boolean;
}

export default function ManualQuoteBlock({
  quote,
  onRequestManualQuote,
  loading,
}: ManualQuoteBlockProps) {
  const quoteIsRequested = quote.status === 'requested';
  const handleChange = () => {
    onRequestManualQuote(quote.sid);
  };
  const inputName = `select-${quote.sid}`;
  const elementClassName = cx(
    // Base styles
    'flex px-3 py-2 w-full border-t border-gray-100',

    // State
    'hover:bg-brand-50',
    {
      'cursor-pointer': !quoteIsRequested,
    },

    ' flex items-center justify-between',
    ' flex-row',
  );

  const renderCheckboxInput = () => (
    <>
      {/* <div className="text-gray-200 text-xs">
        <Icon icon="clock" />
        <span className="ml-2">Click to request quote</span>
      </div> */}
      <div className="ml-4 border border-gray-100 inline-block py-2 px-3 rounded-lg hover:bg-blue-light cursor-pointer flex items-center text-sm">
        <CheckboxInput
          name={inputName}
          checked={quoteIsRequested}
          onChange={handleChange}
          aria-label={`Request manual quote for ${quote.provider?.name}`}
        />
        <span className="text-gray-800 font-medium ml-2 whitespace-nowrap">Get Quote</span>
      </div>
    </>
  );
  const renderRequestedQuoteMessage = () => (
    <div className="mt-0">
      <Badge arrangement="leading-icon" ends="pill" icon="clock" variant="neutral">
        Requested
      </Badge>
    </div>
  );

  return (
    <label className={elementClassName} htmlFor={inputName}>
      <div className="h-10 w-32 flex-initial flex justify-between items-center">
        {quote.provider && <ProviderLogo name={quote.provider.name} path={quote.provider.path} />}
      </div>

      <div className="flex items-center mt-0 w-full justify-end lg:justify-end lg:self-center md:mt-0 lg:pl-4">
        {loading && (
          <span className="text-gray-400">
            <LoadingIcon />
          </span>
        )}
        {!loading && (
          <>{quoteIsRequested ? renderRequestedQuoteMessage() : renderCheckboxInput()}</>
        )}
      </div>
    </label>
  );
}
