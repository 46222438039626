import { styled } from '@stitches/react';
import React, { type ReactNode, useId, useMemo } from 'react';

import { fontFamilies } from '../../common/fonts';
import { colors, darkThemeSelector, fontWeights } from '../../stitches.config';
import { Text } from '../../text/Text';
import { type Platform, useUsersOS } from '../../utilities/useUsersOS';

type ShortcutVariants = 'destructive' | 'neutral' | 'primary';
export type ShortcutKeys = SpecialKey | string | ReactNode;

export enum SpecialKey {
  Alt = 'alt',
  CmdOrCtrl = 'cmdOrCtrl',
  Meta = 'meta',
  Shift = 'shift',
}

const ShortcutKeyContainer = styled(Text, {
  display: 'inline-flex',
  width: 'fit-content',
  padding: '$2 $4',
  backgroundColor: colors.tokenBgNeutralLight,
  borderRadius: '$4',
  color: colors.headingNeutralLight,
  font: `${fontFamilies.sans}`,
  fontSize: '$11',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.bold,
  lineHeight: '$12',
  whiteSpace: 'nowrap',
  strokeAll: colors.tokenStrokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.tokenBgNeutralDark,
    color: colors.headingNeutralDark,
    strokeAll: colors.tokenStrokeNeutralDark,
  },

  variants: {
    control: {
      true: {},
      false: {},
    },
    internal: {
      true: {},
      false: {},
    },
    variant: {
      destructive: {},
      neutral: {},
      primary: {},
    },
  },

  compoundVariants: [
    // Destructive
    {
      variant: 'destructive',
      control: false,
      css: {
        backgroundColor: colors.tokenBgNegativeLight,
        color: colors.headingNegativeLight,
        strokeAll: colors.tokenStrokeNegativeLight,

        [darkThemeSelector]: {
          backgroundColor: colors.tokenBgNegativeDark,
          color: colors.headingNegativeDark,
          strokeAll: colors.tokenStrokeNegativeDark,
        },
      },
    },
    // Destructive, control
    {
      variant: 'destructive',
      control: true,
      css: {
        backgroundColor: colors.red700,
        color: colors.red50,
        strokeAll: colors.controlStrokeDestructiveDark,
      },
    },
    // Internal
    {
      control: false,
      internal: true,
      css: {
        backgroundColor: colors.internalTokenBgLight,
        color: colors.internalBodyLight,
        strokeAll: colors.internalStrokeLight,

        [darkThemeSelector]: {
          backgroundColor: colors.internalTokenBgDark,
          color: colors.internalBodyDark,
          strokeAll: colors.internalStrokeDark,
        },
      },
    },
    // Primary
    {
      variant: 'primary',
      control: false,
      css: {
        backgroundColor: colors.tokenBgBrandLight,
        color: colors.headingBrandLight,
        strokeAll: colors.tokenStrokeBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.tokenBgBrandDark,
          color: colors.headingBrandDark,
          strokeAll: colors.tokenStrokeBrandDark,
        },
      },
    },
    // Primary, control
    {
      variant: 'primary',
      control: true,
      css: {
        backgroundColor: colors.brand700,
        color: colors.brand50,
        strokeAll: colors.strokeBrandDark,
      },
    },
  ],
});

function AltKey({ platform }: { platform: Platform }) {
  if (platform === 'mac') {
    return '⌥';
  }
  return 'Alt';
}

function CmdOrCtrlKey({ platform }: { platform: Platform }) {
  if (platform === 'mac') {
    return '⌘';
  }
  return 'Ctrl';
}

function MetaKey({ platform }: { platform: Platform }) {
  if (platform === 'mac') {
    return '⌘';
  }
  if (platform === 'linux') {
    return 'Super';
  }
  return '⊞';
}

function ShiftKey() {
  return '⇧';
}

function ShortcutKey({
  control,
  internal,
  variant,
  children: key,
}: {
  children: ShortcutKeys;
  control: boolean;
  internal: boolean;
  variant: ShortcutVariants;
}) {
  const platform = useUsersOS();

  const renderedKey = useMemo(() => {
    if (key === SpecialKey.Meta) return <MetaKey platform={platform} />;
    if (key === SpecialKey.Alt) return <AltKey platform={platform} />;
    if (key === SpecialKey.CmdOrCtrl) return <CmdOrCtrlKey platform={platform} />;
    if (key === SpecialKey.Shift) return <ShiftKey />;

    return key;
  }, [key, platform]);

  return (
    <ShortcutKeyContainer control={control} internal={internal} variant={variant}>
      {renderedKey}
    </ShortcutKeyContainer>
  );
}

const ShortcutModifier = styled(Text, {
  display: 'inline-flex',
  width: 'fit-content',
  color: colors.bodyNeutralLight,
  font: `${fontFamilies.sans}`,
  fontSize: '$11',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.bold,
  lineHeight: '$12',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  variants: {
    control: {
      true: {},
      false: {},
    },
    internal: {
      true: {},
      false: {},
    },
    variant: {
      destructive: {},
      neutral: {},
      primary: {},
    },
  },

  compoundVariants: [
    // Destructive
    {
      variant: 'destructive',
      control: false,
      css: {
        color: colors.bodyNegativeLight,

        [darkThemeSelector]: {
          color: colors.bodyNegativeDark,
        },
      },
    },
    // Destructive, control
    {
      variant: 'destructive',
      control: true,
      css: {
        color: colors.red100,
      },
    },
    // Internal
    {
      control: false,
      internal: true,
      css: {
        color: colors.internalBodyLight,

        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
    },
    // Primary
    {
      variant: 'primary',
      control: false,
      css: {
        color: colors.bodyBrandLight,

        [darkThemeSelector]: {
          color: colors.bodyPositiveDark,
        },
      },
    },
    // Primary, control
    {
      variant: 'primary',
      control: true,
      css: {
        color: colors.brand100,
      },
    },
  ],
});

const ShortcutContainer = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$2',
  padding: '0 $1',
});

type ShortcutProps = {
  /**
   * Whether the shortcut is contained inside an Atto control component.
   */
  control?: boolean;
  /**
   * Whether the shortcut is internal only.
   */
  internal?: boolean;
  /**
   * The keys to display in the shortcut.
   */
  keys: ShortcutKeys[];
  /**
   * The variant of the shortcut.
   */
  variant?: ShortcutVariants;
};

export function Shortcut({
  control = false,
  internal = false,
  keys,
  variant = 'neutral',
}: ShortcutProps) {
  const uuid = useId();
  return (
    <ShortcutContainer>
      {keys.map((key, index) => (
        <React.Fragment key={`${uuid}-${key}`}>
          <ShortcutKey control={control} internal={internal} variant={variant}>
            {key}
          </ShortcutKey>
          {index < keys.length - 1 && (
            <ShortcutModifier control={control} internal={internal} variant={variant}>
              +
            </ShortcutModifier>
          )}
        </React.Fragment>
      ))}
    </ShortcutContainer>
  );
}

export default Shortcut;
