import type { SVGAttributes } from 'react';
import { ProviderIconSVG } from 'atto-svgs';
import React from 'react';

import { styled } from '../../stitches.config';
import { space, SpacingValue } from '../../utilities/shared/sizes';

export const ProviderIconSizes = SpacingValue;

export type ProviderIconSize = number;

export const ProviderIcons = {
  unknown: ProviderIconSVG.Unknown,
  allstream: ProviderIconSVG.Allstream,
  att: ProviderIconSVG.ATT,
  cogent: ProviderIconSVG.Cogent,
  comcastBusiness: ProviderIconSVG.ComcastBusiness,
  cox: ProviderIconSVG.Cox,
  cradlePoint: ProviderIconSVG.CradlePoint,
  crownCastle: ProviderIconSVG.CrownCastle,
  cruzio: ProviderIconSVG.Cruzio,
  earthLink: ProviderIconSVG.EarthLink,
  fastmetrics: ProviderIconSVG.Fastmetrics,
  for2Fi: ProviderIconSVG.For2Fi,
  frontier: ProviderIconSVG.Frontier,
  google: ProviderIconSVG.Google,
  gtt: ProviderIconSVG.GTT,
  hughesNet: ProviderIconSVG.HughesNet,
  lumen: ProviderIconSVG.Lumen,
  monkeybrains: ProviderIconSVG.Monkeybrains,
  pilot: ProviderIconSVG.Pilot,
  rcn: ProviderIconSVG.RCN,
  sonic: ProviderIconSVG.Sonic,
  spectrum: ProviderIconSVG.Spectrum,
  tMobile: ProviderIconSVG.TMobile,
  verizon: ProviderIconSVG.Verizon,
  viasat: ProviderIconSVG.Viasat,
  wave: ProviderIconSVG.Wave,
  webpass: ProviderIconSVG.Webpass,
  wiLine: ProviderIconSVG.WiLine,
  windstream: ProviderIconSVG.Windstream,
  wow: ProviderIconSVG.WOW,
  xfinity: ProviderIconSVG.Xfinity,
  zayo: ProviderIconSVG.Zayo,
};

export type ProviderIconName = keyof typeof ProviderIcons;
export const ProviderIconNames = Object.keys(ProviderIcons) as ProviderIconName[];

export interface ProviderIconProps {
  /**
   * Set which piece of provider to display.
   */
  provider?: ProviderIconName;
  /**
   * Set a size of the component.
   */
  size?: ProviderIconSize;
}

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
});

export function ProviderIcon({ provider, size = space(40), ...remaining }: ProviderIconProps) {
  const NamedProviderIcon = ProviderIcons[provider as ProviderIconName] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;

  if (!NamedProviderIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${provider}`);
    return null;
  }

  return <BaseSVG as={NamedProviderIcon} width={size} height={size} {...remaining} />;
}
