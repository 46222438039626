import React from 'react';

import { Icon } from '@meterup/atto';
import { Quote } from '@meterup/connect-api';

import ManualQuoteBlock from '../ManualQuoteBlock/ManualQuoteBlock';

// TODO: get Interface from @api
interface ManualQuotesListProps {
  quotes: Quote[];
  quoteLoadingSid: string;
  onRequestManualQuote: (sid: string) => void;
}

export default function ManualQuotesList({
  quotes,
  onRequestManualQuote,
  quoteLoadingSid,
}: ManualQuotesListProps) {
  return (
    <section className="p-0 bg-white">
      <header className="flex p-4">
        <span className="text-gray-600">
          <Icon icon="clock" />
        </span>
        <div className="ml-2">
          <strong className="font-medium">Willing to wait for additional quotes?</strong>
          <p className="font-regular text-gray-600 text-sm">
            We found more providers in the area, but they do not offer instant quotes. We can reach
            out to these providers without sharing your information and update the quotes list when
            they are ready.
          </p>
        </div>
      </header>
      <ul className="mt-2">
        {quotes.map((quote) => (
          // Last quote won't have margin bottom
          <li className="mt-0" key={quote.sid}>
            <ManualQuoteBlock
              quote={quote}
              onRequestManualQuote={onRequestManualQuote}
              loading={quoteLoadingSid === quote.sid}
            />
          </li>
        ))}
      </ul>
    </section>
  );
}
