import { useCallback } from 'react';

export const REQUEST_QUOTE_FORM_SUBMIT_LOCATION = 'Request Quote Form: Location submitted';
export const REQUEST_QUOTE_FORM_SUBMIT_BANDWIDTH = 'Request Quote Form: Bandwidth submitted';
export const REQUEST_QUOTE_FORM_SUBMIT_CONTRACT = 'Request Quote Form: Contract submitted';
export const REQUEST_QUOTE_FORM_SUBMIT_EXISTING_PROVIDER =
  'Request Quote Form: Existing provider submitted';
export const REQUEST_QUOTE_FORM_SUBMIT_EXISTING_PROVIDER_DETAILS =
  'Request Quote Form: Existing provider details submitted';
export const REQUEST_QUOTE_FORM_SUBMIT_CONTACT_INFORMATION =
  'Request Quote Form: Contact information submitted';
export const RESULTS_PAGE_SHARE_VIA_MAIL_CLICKED =
  'Quote Request Results page: Clicked "Share via mail button"';

export default function useLogEvent(): (event: string) => void {
  return useCallback((event: string) => {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({ event });
    }

    if ((window as any).heap) {
      (window as any).heap.track(event);
    }
  }, []);
}
