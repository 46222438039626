import React from 'react';

import { Badge } from '@meterup/atto';

// import { TextInput } from "@meterup/connect-ui";

// Props will be a bunch of onchangeevent
function QuotesOnboardingFeatures() {
  return (
    <div>
      {/* <strong className="inline-block font-medium">Why use Meter Connect?</strong> */}
      <ul className="mt-4">
        <li className="mt-2">
          <Badge variant="neutral" icon="checkmark" arrangement="leading-icon">
            Unlimited free quotes
          </Badge>
        </li>
        <li className="mt-2">
          <Badge variant="neutral" icon="checkmark" arrangement="leading-icon">
            Price match if you find better pricing
          </Badge>
        </li>
        <li className="mt-2">
          <Badge variant="neutral" icon="checkmark" arrangement="leading-icon">
            Expert & unbiased support and recommendations
          </Badge>
        </li>
        <li className="mt-2">
          <Badge variant="neutral" icon="checkmark" arrangement="leading-icon">
            Create your contract hassle-free
          </Badge>
        </li>
      </ul>
    </div>
  );
}

export default QuotesOnboardingFeatures;
