// Libraries
import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
  title: string;
  src1_5x?: string;
  src2x?: string;
  src3x?: string;
  className?: string;
}

function Image({ src, alt, title, src1_5x, src2x, src3x, className }: ImageProps) {
  const srcset = [src];
  if (src1_5x) {
    srcset.push(`${src1_5x} 1.5x`);
  }

  if (src2x) {
    srcset.push(`${src2x} 2x`);
  }

  if (src3x) {
    srcset.push(`${src3x} 3x`);
  }

  return <img srcSet={srcset.join(', ')} src={src} alt={alt} title={title} className={className} />;
}

export default Image;
