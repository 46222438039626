// Libraries
import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import AnimateHeight from 'react-animate-height';

interface QuotesListSectionProps {
  open: boolean;
  children: React.ReactNode;
}

function QuotesListSection({ open, children }: QuotesListSectionProps) {
  const [init, setInit] = useState(true);

  useEffect(() => {
    setInit(false);
  }, []);

  const quotesWrapper = cx('transition duration-300', {
    'opacity-100': init || open,
    'opacity-0': !(init || open),
  });
  return (
    <AnimateHeight duration={150} height={init || open ? 'auto' : 0}>
      <ul className={quotesWrapper}>{children}</ul>
    </AnimateHeight>
  );
}
export default QuotesListSection;
