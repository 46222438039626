/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';

import { Button, Icon, SectionHeader } from '@meterup/atto';
import { QuoteRequest } from '@meterup/connect-api';

import Image from '../../Shared/Image/Image';

interface QuotesContractNetworkProps {
  quoteRequest: QuoteRequest;
  onRequestNetwork: () => void;
}

function QuotesContractNetwork({ quoteRequest, onRequestNetwork }: QuotesContractNetworkProps) {
  const features = [
    'Free installation',
    'Free hardware',
    '24/7 support',
    'Network management & security',
    'One monthly rate',
    'Trusted by the fastest growing companies & most experienced IT teams',
  ];

  const renderItem = (text: string) => (
    <div className="flex">
      <span className="text-gray-500 mr-2">
        <Icon icon="checkmark" />
      </span>
      <span className="text-sm ml-0 text-gray-600">{text}</span>
    </div>
  );

  const renderNetworkFeaturesSection = useMemo(
    () => (
      <ul className="text-left justify-between">
        {features.map((feature, index) => (
          <li className="mt-2 font-normal" key={index}>
            {renderItem(feature)}
          </li>
        ))}
      </ul>
    ),
    [],
  );

  const headerStart = (
    <div className="text-sm text-gray-700">
      <p className="font-medium text-gray-800">Need help with your network?</p>
      <p>We can contact you with more details.</p>
    </div>
  );

  const headerEnd = (
    <Button variant="secondary" onClick={onRequestNetwork}>
      {quoteRequest.isInterestedInNetwork ? 'Undo' : 'Yes, contact me'}
    </Button>
  );

  return (
    <section>
      <SectionHeader heading={headerStart} actions={headerEnd} />
      <div className="border border-gray-50 bg-gray-50 rounded-b-lg p-6 mt-nudge-1px">
        <div className="flex flex-col-reverse sm:flex-row items-center text-center sm:text-left">
          <div className="flex-2">
            <Image
              src="/assets/MeterNetworkLogo.svg"
              alt="Meter Network Logo"
              title="Meter Network"
              className="inline-block mt-6 sm:mt-0"
            />
            <div className="mt-6 sm:mt-0">{renderNetworkFeaturesSection}</div>
          </div>
          <div className="sm:pl-6 flex-1 text-center">
            <Image
              src="/illustrations/MeterNetworkHardwareIllustration.png"
              src1_5x="/illustrations/MeterNetworkHardwareIllustration@1.5x.png"
              src2x="/illustrations/MeterNetworkHardwareIllustration@2x.png"
              src3x="/illustrations/MeterNetworkHardwareIllustration@3x.png"
              alt="Illustration of the meter Harware"
              title="Free hardware"
              className="inline-block"
            />
          </div>
        </div>
        <ul className="flex flex-wrap	w-full justify-around text-left sm:text-center mt-2">
          <li className="px-4 w-1/2 md:w-1/4 mt-4">
            <Image
              src="/assets/TishmanLogo.svg"
              alt="Tishman"
              title="Tishman"
              className="inline-block"
            />
          </li>
          <li className="px-4 w-1/2 md:w-1/4 mt-4">
            <Image
              src="/assets/LightstepLogo.svg"
              alt="Lightstep"
              title="Lightstep"
              className="inline-block"
            />
          </li>
          <li className="px-4 w-1/2 md:w-1/4 mt-4">
            <Image src="/assets/TroveLogo.svg" alt="Trove" title="Trove" className="inline-block" />
          </li>
          <li className="px-4 w-1/2 md:w-1/4 mt-4">
            <Image
              src="/assets/NotionLogo.svg"
              alt="Notion"
              title="Notion"
              className="inline-block"
            />
          </li>
        </ul>
      </div>
    </section>
  );
}

export default QuotesContractNetwork;
